// noinspection SpellCheckingInspection

import { appleSignIn } from "@/helper/functions/auth/SignMethods/Apple";
import { facebookSignIn } from "@/helper/functions/auth/SignMethods/Facebook";
import { googleSignIn } from "@/helper/functions/auth/SignMethods/Google";
import { microsoftSignIn } from "@/helper/functions/auth/SignMethods/Microsoft";
import {
  SiApple,
  SiFacebook,
  SiGoogle,
  SiMicrosoft,
} from "@icons-pack/react-simple-icons";
import { useNavigate } from "react-router-dom";
import SocialButton from "./Buttons/SocialButton";

const Social = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="relative mt-10">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-secondary-foreground" />
        </div>
        <div className="relative flex justify-center text-sm font-medium leading-6">
          <span className="bg-background px-6 text-foreground">
            Or continue with
          </span>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-4">
        {/*In favor of usage disabled the GitHub*/}
        <SocialButton
          Label="Google"
          Svg={<SiGoogle className="h-4 w-4 shrink-0 text-foreground" />}
          onClick={() => googleSignIn(navigate)}
        />

        <SocialButton
          Label="Facebook"
          Svg={<SiFacebook className="h-4 w-4 shrink-0 text-foreground" />}
          onClick={() => facebookSignIn(navigate)}
        />
        <SocialButton
          Label="Microsoft"
          Svg={<SiMicrosoft className="h-4 w-4 shrink-0 text-foreground" />}
          onClick={() => microsoftSignIn(navigate)}
        />
        <SocialButton
          Label="Apple"
          Svg={<SiApple className="h-4 w-4 shrink-0 text-foreground" />}
          onClick={() => appleSignIn(navigate)}
        />
      </div>
    </>
  );
};
export default Social;
