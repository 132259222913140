// import { Close } from "@radix-ui/react-popover";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

import clsx from "clsx";

import type React from "react";
// import { useState } from "react";
// import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
	// children: React.ReactNode;
	Icon: React.ReactElement;
}

const NotificationButton: React.FC<Props> = ({ Icon }) => {
	return (
		<Popover modal>
			<PopoverTrigger
				className={clsx(
					"-m-2.5 p-2.5 text-neutral-400 hover:text-neutral-500",
					"bg-background text-foreground",
					"align-middle",
				)}
			>
				{Icon}
				<span className="sr-only">View notifications</span>
			</PopoverTrigger>
			<PopoverContent
				className={clsx(
					"w-64 rounded p-5 shadow-xl",
					// "w-64 rounded p-5 shadow-xl focus:outline-none focus:ring-2 focus:ring-orange-500",
					"border border-neutral-200 bg-background text-foreground",
				)}
			>
				<div className="flex flex-col gap-2.5 py-2">
					<p className="">There is no new notification!</p>
				</div>
				{/* <Close
          className="absolute right-1 top-1 inline-flex items-center justify-center rounded-full p-1.5 text-orange-500 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-orange-400"
          aria-label="Close"
        >
          <XMarkIcon className={"h-[0.90rem] w-[0.90rem]"} />
        </Close> */}
			</PopoverContent>
		</Popover>
	);
};

export default NotificationButton;
