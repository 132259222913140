import Navbar from "@/components/Navbar";
import { Sidebar } from "@/components/Sidebar/Sidebar";
import { Toaster } from "@/components/ui/toaster";
import { cn } from "@/helper/lib/utils";
import { useSidebarStore } from "@/helper/store/sidebarStore";
import { NavLink, Outlet } from "react-router-dom";

const secondaryNavigation = [
  { name: "Account", href: "/settings" },
  { name: "Display", href: "/settings/display" },
  { name: "Billing", href: "/settings/billing" },
  { name: "Security", href: "/settings/security" },
  { name: "Notifications", href: "/settings/notifications" },
  // { name: "Teams", href: "/" },
  // { name: "Integrations", href: "/" },
];
function UserSettingsPage() {
  const { collapsed } = useSidebarStore();

  return (
    <>
      <Sidebar />

      <main
        className={cn(
          "min-h-[calc(100dvh_-_56px)] transition-[margin-left] duration-300 ease-in-out",
          !collapsed ? "lg:ml-[90px]" : "lg:ml-72",
        )}
      >
        <div className="h-full min-h-dvh">
          <Navbar isSettingsNavbar />

          <h1 className="sr-only">Account Settings</h1>

          <SecondaryNavbar />

          <Outlet />
        </div>
      </main>
      <Toaster />
    </>
  );
}

export default UserSettingsPage;

const SecondaryNavbar = () => {
  return (
    <header className="sticky top-16 border-b border-muted bg-background">
      {/* Secondary navigation */}
      <nav className="flex overflow-x-auto py-4">
        <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-foreground/25 sm:px-6 lg:px-8">
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <NavLink
                end
                to={item.href}
                className={({ isActive, isPending }) =>
                  cn(
                    isPending ? "transition-colors" : "",
                    isActive ? "text-primary" : "",
                    "hover:text-foreground",
                  )
                }
              >
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
