import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DateField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const FoodHealthWarningShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Food">
            <ReferenceField source="food_id" reference="foods">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Health Condition">
            <ReferenceField source="condition_id" reference="health_conditions">
              <TextField source="condition_name" />
            </ReferenceField>
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Warning Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Warning Severity">
            <TextField source="warning_severity" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Warning Type">
            <TextField source="warning_type" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Description">
            <TextField source="description" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Recommendation">
            <TextField source="recommendation" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Timestamps
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Created At">
            <DateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Updated At">
            <DateField source="updated_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Deleted At">
            <DateField source="deleted_at" showTime />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default FoodHealthWarningShow;
