import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/helper/lib/utils";
import countries from "@/helper/static/Country/filtered_countries.json";

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@/helper/hooks/useMediaQuery.tsx";
import { useCountryDropdownStore } from "@/helper/store/countryDropdownStore";
import type { CountryProps } from "@/helper/types/Country";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import type { HTMLProps } from "react";

interface CountryDropdownProps {
  disabled?: boolean;
  widthClass?: string;
  buttonClasses?: HTMLProps<HTMLElement>["className"];
  customOnChange?: (value: string) => void;
  isMeal?: boolean;
}

// TODO bunu i18 ile compatible hale getir.
const CountryDropdown = ({
  disabled,
  widthClass,
  buttonClasses,
  customOnChange,
  isMeal,
}: CountryDropdownProps) => {
  const {
    countryValue,
    setCountryValue,
    openCountryDropdown,
    setOpenCountryDropdown,
  } = useCountryDropdownStore();
  const C = countries as CountryProps[];
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const handleSelect = (value: string | number, countryName: string) => {
    setCountryValue(value === countryName ? value : "");
    setOpenCountryDropdown(false);
    customOnChange?.(value as string);
  };

  if (isDesktop) {
    return (
      <Popover open={openCountryDropdown} onOpenChange={setOpenCountryDropdown}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            aria-expanded={openCountryDropdown}
            className={cn(
              buttonClasses,
              widthClass ? widthClass : "w-[300px]",
              "justify-between rounded-[6px] border",
            )}
            disabled={disabled}
          >
            <span>
              {countryValue ? (
                <div className="flex items-end gap-2">
                  <span>
                    {
                      countries.find((country) => country.name === countryValue)
                        ?.emoji
                    }
                  </span>
                  <span>
                    {
                      countries.find((country) => country.name === countryValue)
                        ?.name
                    }
                  </span>
                </div>
              ) : (
                <span>
                  {isMeal ? "Select Meal Country..." : "Select Country..."}
                </span>
              )}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className={cn(
            widthClass ? widthClass : "w-[300px]",
            "rounded-[6px] border border-[#27272a] p-0",
          )}
        >
          <Command>
            <CommandInput
              placeholder={
                isMeal ? "Search meal country..." : "Search country..."
              }
            />
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandList className="overflow-hidden">
              <CommandGroup>
                <ScrollArea className="h-[300px] w-full">
                  {C.map((country) => (
                    <CommandItem
                      key={country.id}
                      value={country.name}
                      onSelect={(currentValue) =>
                        handleSelect(currentValue, country.name)
                      }
                      className="flex cursor-pointer items-center justify-between text-xs hover:!bg-[#27272a] hover:!text-white"
                    >
                      <div className="flex items-end gap-2">
                        <span>{country.emoji}</span>
                        <span className="">{country.name}</span>
                      </div>
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          countryValue === country.name
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </CommandItem>
                  ))}
                  <ScrollBar orientation="vertical" />
                </ScrollArea>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={openCountryDropdown} onOpenChange={setOpenCountryDropdown}>
      <DrawerTrigger asChild>
        <Button
          variant="outline"
          className="w-[150px] justify-start max-sm:w-full"
        >
          <span>
            {countryValue ? (
              <div className="flex items-end gap-2">
                <span>
                  {
                    countries.find((country) => country.name === countryValue)
                      ?.emoji
                  }
                </span>
                <span>
                  {
                    countries.find((country) => country.name === countryValue)
                      ?.name
                  }
                </span>
              </div>
            ) : (
              <span>Select Country...</span>
            )}
          </span>
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <VisuallyHidden.Root>
          <DrawerHeader>
            <DrawerTitle>Search country...</DrawerTitle>
            <DrawerDescription>"Search country..."</DrawerDescription>
          </DrawerHeader>
        </VisuallyHidden.Root>
        <div className="mt-4 border-t">
          <DrawerCommand
            handleSelect={handleSelect}
            List={C}
            PlaceHolder={"Search country..."}
          />
        </div>
      </DrawerContent>
    </Drawer>
  );
};

function DrawerCommand({
  List,
  handleSelect,
  PlaceHolder,
}: {
  List: CountryProps[];
  handleSelect: (value: string | number, countryName: string) => void;
  PlaceHolder: string;
}) {
  return (
    <Command>
      <CommandInput placeholder={PlaceHolder} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {List.map((item) => (
            <CommandItem
              key={item.id}
              value={item.name}
              onSelect={(value) => handleSelect(value, item.name)}
            >
              {item.emoji} {item.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

export default CountryDropdown;
