import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const AllergenFilters = [
  <TextInput
    key="allergen_name"
    source="allergen_name"
    label="Name"
    alwaysOn
  />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const AllergenList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "allergen_name", order: "ASC" }}
    filters={AllergenFilters}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="allergen_name" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="allergens" />
    </Datagrid>
  </List>
);

export default AllergenList;
