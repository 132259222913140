import { BoxSwitch } from "@/components/ui/box-switch";
import type { OptionValue } from "@/helper/types/BoxSwitch";
import { useField } from "formik";
import type { FC } from "react";
import { InputLabelComponent } from "./InputLabel";

interface FormBoxSwitchProps {
  name: string;
  options: { label: string; value: OptionValue }[];
  label?: string;
  Tooltip?: {
    tooltip: boolean;
    Content: string;
  };
}

export const FormBoxSwitch: FC<FormBoxSwitchProps> = ({
  name,
  options,
  label,
  Tooltip,
}) => {
  const [field, , helpers] = useField<OptionValue>(name);

  const handleChange = (value: OptionValue) => {
    helpers.setValue(value);
  };

  return (
    <>
      {label ? (
        <div className="grid w-full gap-2">
          <InputLabelComponent
            LabelText={label}
            For={name}
            FieldName={name}
            {...Tooltip}
          />
          <BoxSwitch
            value={field.value}
            onChange={handleChange}
            options={options}
          />
        </div>
      ) : (
        <BoxSwitch
          value={field.value}
          onChange={handleChange}
          options={options}
        />
      )}
    </>
  );
};
