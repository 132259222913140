import { Button } from "@/components/ui/button";
import { Table } from "@/components/ui/table";
import { useExerciseStore } from "@/helper/store/exerciseStore";
import {
	CreateExercise,
	ExerciseHeader,
	ExerciseListItem,
} from "./Exercise/ExerciseComponents";

function generateUUID() {
	return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

// TODO validate şemaları ekle özellikle güne 0 eklemesine izin verme.
// TODO bu sayfayı tekrar formik ile uyumlu hale getir.
export default function ExercisePage() {
	const { addExercise, exercises, clearExercises, formData, setFormData } =
		useExerciseStore();
	const onSubmitHandler = (event: { preventDefault: () => void }) => {
		event.preventDefault(); // Prevent default form submission
		if (!formData.name || formData.setCount === 0) {
			console.error("Required fields are missing");
			return;
		}
		addExercise({
			...formData,
			UUID: generateUUID(),
		});

		// Optionally reset form data
		setFormData("name", "");
		setFormData("setCount", 0);
		setFormData("totalSets", 0);
		setFormData("dayOfWeek", 0);
		setFormData("isCompleted", false);
	};

	return (
		<>
			<form onSubmit={onSubmitHandler}>
				<div className="flex flex-col gap-4">
					<div className="flex items-center justify-between">
						<h2 className="font-semibold text-4xl">Exercise List</h2>
						<Button
							type="button"
							variant="destructive"
							onClick={() => clearExercises()}
						>
							Clear All
						</Button>
					</div>
					{exercises.length === 0 && (
						<span className="text-xl">No exercises added yet.</span>
					)}
					<Table>
						{exercises.length > 0 && <ExerciseHeader />}
						{exercises.length !== 0 &&
							exercises.map((exercise) => (
								<ExerciseListItem key={exercise.UUID} exercise={exercise} />
							))}
						<CreateExercise />
					</Table>
				</div>
				{import.meta.env.DEV && <pre>{JSON.stringify(exercises, null, 2)}</pre>}
			</form>
		</>
	);
}
