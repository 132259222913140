import { signOut } from "@/helper/functions/auth/signOut";
import { useAuthStore } from "@/helper/store/authStore";
import { type FC, type ReactElement, cloneElement } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactElement;
}

const LogoutButton: FC<Props> = ({ children }) => {
  const { setIsRegistered, setUUID } = useAuthStore();
  const navigate = useNavigate();

  const handleClick = async () => {
    setIsRegistered(false);
    setUUID("");
    await signOut();
    navigate("/login");
  };

  return cloneElement(children, { onClick: handleClick });
};

export default LogoutButton;
