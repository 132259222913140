import AccountDeleteSection from "../Sections/Account/AccountDelete";
import SettingsPersonalInfoSection from "../Sections/Account/PersonalInfo";
import SettingsFormDivider from "./Layout/Divider";

// TODO populate this component with more necessary forms
export const SettingsAccountPage = () => {
  return (
    <SettingsFormDivider>
      <SettingsPersonalInfoSection />
      <AccountDeleteSection />
    </SettingsFormDivider>
  );
};
