import { PieChartWithCard } from "../Charts/PieChart";

const chartData = [
	{ name: "carbs", value: 275, fill: "var(--color-carbs)" },
	{ name: "protein", value: 287, fill: "var(--color-protein)" },
	{ name: "fats", value: 200, fill: "var(--color-fats)" },
];

const chartConfig = {
	title: {
		label: "Macros",
	},
	carbs: {
		label: "Carbs",
		color: "hsl(var(--chart-1))",
	},
	protein: {
		label: "Protein",
		color: "hsl(var(--chart-2))",
	},
	fats: {
		label: "Fats",
		color: "hsl(var(--chart-3))",
	},
};
export default function DailyCalorieChart() {
	return (
		<>
			<PieChartWithCard
				chartConfig={chartConfig}
				data={chartData}
				title="Günlük Kalori Dağılımı"
				description="Günlük kalori dağılımınız"
				totalLabel="Toplam"
			/>
		</>
	);
}
