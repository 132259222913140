import { queryClient, routeQueryOptions } from "@/helper/lib/react-query";
import { GraphQLUrlBuilder } from "../functions/ApiUrlbuilder";
import { getAllergiesAndConditions } from "../graphql/queries";
import type { LoaderFunction } from "react-router-dom";

export const onboardingLoader: LoaderFunction = async () => {
	// Prefetch allergies and conditions data
	await queryClient.prefetchQuery({
		queryKey: ["allergiesAndConditions"],
		queryFn: () => GraphQLUrlBuilder(getAllergiesAndConditions),
		...routeQueryOptions,
		staleTime: 30 * 60 * 1000, // 30 minutes
	});

	return { ok: true };
};
