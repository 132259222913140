/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

interface TranslationInputProps {
  source: string;
  label?: string;
  isNested?: boolean;
  fields?: string[];
}

const languageChoices = [
  { id: "en", name: "English" },
  { id: "tr", name: "Turkish" },
  // Add more languages as needed
];

export const TranslationsInput = ({
  source,
  isNested = false,
  fields = ["name", "description"],
}: TranslationInputProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  if (isNested) {
    return (
      <ArrayInput source={source}>
        <SimpleFormIterator inline={isDesktop}>
          {/* <Grid container spacing={2} width="100%"> */}
          {/* <Grid size={{ xs: 12 }}> */}
          <SelectInput
            source="lang"
            choices={languageChoices}
            label="Language"
            fullWidth
          />
          {/* </Grid> */}
          {fields.map((field) => (
            <Grid key={field} size={{ xs: 12 }}>
              <TextInput
                key={field}
                source={field}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                fullWidth
                multiline={field === "description"}
                rows={field === "description" ? 3 : 1}
              />
            </Grid>
          ))}
          {/* </Grid> */}
        </SimpleFormIterator>
      </ArrayInput>
    );
  }

  return (
    <ArrayInput source={source}>
      <SimpleFormIterator>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 4 }}>
            <SelectInput
              source="lang"
              choices={languageChoices}
              label="Language"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <TextInput source="text" label="Translation" fullWidth />
          </Grid>
        </Grid>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
