import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanInput,
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CreateUserBan = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Ban Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={12}>
            <ReferenceInput
              source="user_id"
              reference="users"
              label="User"
              fullWidth
            >
              <SelectInput optionText="username" />
            </ReferenceInput>
          </Grid>
          <Grid size={6}>
            <BooleanInput
              source="is_banned"
              validate={[required()]}
              label="Is Banned"
              defaultValue={true}
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <DateTimeInput
              source="banned_at"
              validate={[required()]}
              label="Ban Date"
              defaultValue={new Date()}
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <TextInput
              source="ban_reason"
              validate={[required()]}
              label="Ban Reason"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateUserBan;
