/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormBoxSwitch } from "@/components/Forms/Inputs/BoxSwitchComponent";
import { FormDatePicker } from "@/components/Forms/Inputs/DatePicker";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { UsernameInputComponent } from "@/components/Forms/Inputs/Username";
import { HeightInput, WeightInput } from "@/components/Inputs/Unit";
import { PersonalInfoValidationSchema } from "@/helper/Schemas/OnboardingSchemas";
import {
  HeightTransformer,
  WeightTransformer,
} from "@/helper/functions/converters";
import { useStepper } from "@/helper/hooks/useStepper";
import { fieldTypeBuilder } from "@/helper/lib/utils";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { useUnitStore } from "@/helper/store/unitStore";
import { useUsernameStore } from "@/helper/store/usernameStore";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";
import { BoardingInputField, BoardingRow } from "../UIElements";

export function PersonalInfo() {
  const { boarding, setBoarding, nextStep, setStepValidity, stepNumber } =
    useBoardingStore();
  const {
    selectedWeightUnit,
    selectedHeightUnit,
    setConvertedHeight,
    setConvertedWeight,
  } = useUnitStore();
  const { nextStep: stepperNextFunction } = useStepper();
  const { setReadyToCallAPI, usernameError, setUsernameError } =
    useUsernameStore();
  const initValues = {
    username: boarding.username,
    first_name: boarding.first_name,
    last_name: boarding.last_name,
    health_profile: {
      birth_date: boarding.health_profile?.birth_date,
      gender: boarding.health_profile?.gender,
      height: boarding.health_profile?.height,
      weight: boarding.health_profile?.weight,
    },
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmitHandler = (values: any) => {
    setBoarding(values);

    // Perform the conversions using the most up-to-date form values
    const transformedWeight = WeightTransformer(
      values.health_profile.weight,
      selectedWeightUnit,
      boarding.settings?.weight_unit || "",
    );
    const transformedHeight = HeightTransformer(
      values.health_profile.height,
      selectedHeightUnit,
      boarding.settings?.height_unit || "",
    );

    // Update the converted values in the store
    setConvertedWeight(transformedWeight);
    setConvertedHeight(transformedHeight);

    stepperNextFunction();
    setStepValidity(stepNumber, true);
    nextStep();
  };

  return (
    <MultiStepComponentFormWrapper
      initValues={initValues}
      onSubmit={onSubmitHandler}
      validationSchema={PersonalInfoValidationSchema(
        usernameError,
        setReadyToCallAPI,
        setUsernameError,
      )}
    >
      <UsernameInputComponent />
      <BoardingRow>
        <BoardingInputField
          Name="first_name"
          Placeholder="Ad"
          InputLabel="Adınızı Giriniz"
          InputType="text"
          FieldID="firstName"
        />
        <BoardingInputField
          Name="last_name"
          Placeholder="Soyad"
          InputLabel="Soyadınızı Giriniz"
          InputType="text"
          FieldID="lastName"
        />
      </BoardingRow>
      <BoardingRow>
        <HeightInput />
        <WeightInput />
      </BoardingRow>
      <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
        <div className="grid w-full gap-2">
          <InputLabelComponent
            LabelText="Doğum Tarihinizi Giriniz"
            FieldName={fieldTypeBuilder("health_profile", "birth_date")}
          />
          <FormDatePicker
            FieldName={fieldTypeBuilder("health_profile", "birth_date")}
            MonthAndYearPicker
          />
        </div>
        <FormBoxSwitch
          name="health_profile.gender"
          options={[
            { label: "Erkek", value: "male" },
            { label: "Kadın", value: "female" },
          ]}
          label="Cinsiyetinizi Seçiniz"
          Tooltip={{
            tooltip: true,
            Content:
              "Sağlıklı ve doğru ölçümler yapabilmemiz için biyolojik cinsiyetinizi seçmeniz önemlidir. Bu bilgi, sizin için en uygun sağlık önerilerini sunmamıza yardımcı olacaktır.",
          }}
        />
      </div>
    </MultiStepComponentFormWrapper>
  );
}
