import { cn } from "@/helper/lib/utils";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { useEffect } from "react";
import { CardDescription, CardHeader, CardTitle } from "../ui/card";
import { GoalsAndActivity } from "./Sections/GoalsAndActivity";
import { HealthProfile } from "./Sections/HealthProfile";
import { PersonalInfo } from "./Sections/PersonalInfo";
import { ReviewSection } from "./Sections/Review";
import { OnboardingUserSettingsSection } from "./Sections/UserSettings";
import { WelcomeSection } from "./Sections/Welcome";
import { BoardingCard, BoardingMotionDiv } from "./UIElements";

const MultiStepForm = [
  {
    number: -1,
    title: "Hoş Geldiniz!",
    description:
      "Kayıt olma işlemini tamamlamak için lütfen gerekli alanları doldurun. Bu bilgiler size özel öneriler almanızı sağlayacaktır.",
    content: <WelcomeSection />,
  },
  {
    number: 0,
    title: "Profilinizi Özelleştirin",
    description:
      "Hadi başlamadan önce Profilinizi özelleştirin ve tercihlerinizi belirleyin.",
    content: <OnboardingUserSettingsSection />,
  },
  {
    number: 1,
    title: "Kişisel Bilgilerinizi Belirleyin.",
    description:
      "Lütfen kullanıcı adınızı, adınızı, soyadınızı, boyunuzu, kilonuzu, doğum tarihinizi ve cinsiyetinizi girin.",
    content: <PersonalInfo />,
  },
  {
    number: 2,
    title: "Sağlık Bilgileriniz ve Tercihleriniz",
    description:
      "Lütfen sağlık bilgilerinizi girin. Bu bilgiler, size özel öneriler almanızı sağlayacaktır.",
    content: <HealthProfile />,
  },
  {
    number: 3,
    title: "Hedeflerinizi Belirleyin",
    description: "Lütfen hedeflerinizi belirleyin.",
    content: <GoalsAndActivity />,
  },
  {
    number: 4,
    title: "Review",
    description: "Lütfen bilgilerinizi kontrol edin ve onaylayın.",
    content: <ReviewSection />,
  },
];

export function OnboardingForm() {
  const { stepNumber, setMultiStepForm } = useBoardingStore();
  useEffect(() => {
    setMultiStepForm(MultiStepForm);
  }, [setMultiStepForm]);

  return (
    <BoardingCard>
      {MultiStepForm.map((step) => {
        return (
          stepNumber === step.number && (
            <BoardingMotionDiv key={step.number}>
              <CardHeader>
                <CardTitle>{step.title}</CardTitle>
                <CardDescription
                  className={cn(step.number === -1 && "mx-auto max-w-xl")}
                >
                  {step.description}
                </CardDescription>
              </CardHeader>
              {step.content}
            </BoardingMotionDiv>
          )
        );
      })}
    </BoardingCard>
  );
}
