/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
} from "@mui/material";
import { TrashIcon } from "lucide-react";
import { useState } from "react";
import {
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

interface DangerouslyDeleteButtonProps {
  record: { id: string | number };
  resource: string;
}

export const DangerouslyDeleteButton = ({
  record,
  resource,
}: DangerouslyDeleteButtonProps) => {
  const [open, setOpen] = useState(false);
  const [deleteOne, { isLoading }] = useDelete(
    resource,
    { id: record.id },
    {
      mutationMode: "pessimistic",
      meta: { endpoint: "dangerously-delete" },
    },
  );
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    return false; // Prevent event bubbling
  };

  const handleClose = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setOpen(false);
  };

  const handleConfirm = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await deleteOne();
      notify(`${resource} permanently deleted`, { type: "warning" });
      refresh();
    } catch (error) {
      notify(`Error: dangerous delete failed for ${resource}`, {
        type: "error",
      });
    }
    setOpen(false);
    return false; // Prevent event bubbling
  };
  return (
    <span
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <MuiButton
        variant="contained"
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleClick(e as unknown as React.MouseEvent);
          }
        }}
        disabled={isLoading}
        color="error"
        size="small"
      >
        <TrashIcon className="h-5 w-5" />
      </MuiButton>
      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleClose();
          }
        }}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ⚠️ Permanent Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will permanently delete this {resource.slice(0, -1)} and all
            associated data. This action <strong>cannot be undone</strong>. Are
            you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
          <MuiButton onClick={handleConfirm} color="error" autoFocus>
            Yes, Permanently Delete
          </MuiButton>
        </DialogActions>
      </Dialog>
    </span>
  );
};

interface DatagridDangerouslyDeleteButtonProps {
  resource: string;
}

export const DatagridDangerouslyDeleteButton = ({
  resource,
}: DatagridDangerouslyDeleteButtonProps) => {
  const record = useRecordContext();
  if (!record) return null;

  return <DangerouslyDeleteButton resource={resource} record={record} />;
};
