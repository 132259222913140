import DashboardShell from "@/components/Shells/DashboardShell";
import { useAuthStore } from "@/helper/store/authStore";
import {
  type ExtendedUserHealthProfiles,
  useUserStore,
} from "@/helper/store/userStore";
import type {
  CustomClaims,
  HealthMetrics,
  User,
  UserRole,
  UserSettings,
} from "@/helper/types/UserTypes";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

interface LoaderData {
  ok: boolean;
  userData: User;
  metrics: HealthMetrics;
  healthProfile: ExtendedUserHealthProfiles;
  settings: UserSettings;
  claims: CustomClaims;
}

export default function Root() {
  const { UUID } = useAuthStore();
  const queryClient = useQueryClient();
  const loaderData = useLoaderData() as LoaderData;
  const {
    setUser,
    setHealthMetrics,
    setHealthProfile,
    setUserRoles,
    setUserSettings,
  } = useUserStore();

  useEffect(() => {
    if (loaderData.claims?.role) {
      setUserRoles(loaderData.claims.role as UserRole);

      // Set the data in query cache
      queryClient.setQueryData(["userClaims"], loaderData.claims);
      queryClient.setQueryData(["userData", UUID], loaderData.userData);
      queryClient.setQueryData(
        ["user-health-metrics", UUID],
        loaderData.metrics,
      );
      queryClient.setQueryData(
        ["user-health-Profile", UUID],
        loaderData.healthProfile,
      );
      queryClient.setQueryData(["user-settings", UUID], loaderData.settings);

      // Set the data in store
      setUser(loaderData.userData);
      setHealthMetrics(loaderData.metrics);
      setHealthProfile(loaderData.healthProfile);
      setUserSettings(loaderData.settings);
    }
  }, [
    loaderData,
    UUID,
    queryClient,
    setUser,
    setHealthMetrics,
    setHealthProfile,
    setUserRoles,
    setUserSettings,
  ]);

  return (
    <DashboardShell>
      <Outlet />
    </DashboardShell>
  );
}
