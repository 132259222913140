import debounce from "lodash/debounce";
import { APIUrlBuilder } from "../ApiUrlbuilder";
import { fetchData } from "../fetch";

interface UsernameCheckResponse {
	is_unique: boolean;
}

const checkUsernameAvailability = async (
	username: string,
	setError: (error: string) => void,
): Promise<UsernameCheckResponse> => {
	const endpoint = APIUrlBuilder("/onboarding/check-username-unique", {
		username,
	});

	try {
		const data = await fetchData(endpoint);

		if (!data.is_unique) {
			setError("Bu kullanıcı adı mevcut. Lütfen başka bir tane deneyin.");
		} else {
			setError("");
		}
		return data;
	} catch (error) {
		if (error instanceof Error && "response" in error) {
			const response = (error as Error & { response: { status: number } })
				.response;
			if (response?.status === 429) {
				setError(
					"Çok fazla istek gönderdiniz. Lütfen daha sonra tekrar deneyin.",
				);
			} else {
				setError("Kullanıcı adı kontrolü sırasında bir hata oluştu.");
			}
		} else {
			setError("Bilinmeyen bir hata oluştu");
		}
		return { is_unique: false };
	}
};

// Create the debounced function once
const debouncedCheck = debounce(
	(
		username: string,
		setError: (error: string) => void,
		resolve: (value: UsernameCheckResponse) => void,
	) => {
		checkUsernameAvailability(username, setError).then(resolve);
	},
	// Debounce timing guidelines:
	// - 300-500ms: Optimal for typing-related checks
	// - 800ms: Maximum recommended for good UX
	// - >800ms: Can feel sluggish to users
	500,
);

export const debouncedCheckUsername = (
	username: string,
	setError: (error: string) => void,
): Promise<UsernameCheckResponse> => {
	return new Promise((resolve) => {
		debouncedCheck(username, setError, resolve);
	});
};
