import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useMediaQuery } from "@/helper/hooks/useMediaQuery";
import type { FC, ReactNode } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

interface Props {
  Label: ReactNode | string;

  Content: ReactNode | string;
}

const FormTooltip: FC<Props> = ({
  Label: hoverText,
  Content: tooltipContent,
}) => {
  const content =
    typeof tooltipContent === "string" ? (
      <p>{tooltipContent}</p>
    ) : (
      tooltipContent
    );
  const hover = typeof hoverText === "string" ? <p>{hoverText}</p> : hoverText;
  const isDesktop = useMediaQuery("(min-width: 768px)");
  if (isDesktop) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger type="button">{hover}</TooltipTrigger>
          <TooltipContent className="max-w-xs">{content}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
  return (
    <Popover>
      <PopoverTrigger>{hover}</PopoverTrigger>
      <PopoverContent>{content}</PopoverContent>
    </Popover>
  );
};
export default FormTooltip;
