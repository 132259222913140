import { cn } from "@/helper/lib/utils";
import { useSidebarStore } from "@/helper/store/sidebarStore.ts";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import NotificationButton from "./Buttons/Notification";
import ProfileDropdown from "./Buttons/ProfileDropdown";
import SearchInput from "./SearchInput";

import type React from "react";

interface Props {
  isSettingsNavbar?: boolean;
}

const Navbar: React.FC<Props> = ({ isSettingsNavbar }) => {
  const { setMobileSidebarOpen } = useSidebarStore();

  return (
    <div
      className={cn(
        "sticky top-0 z-40 lg:mx-auto",
        "bg-background text-foreground",
        !isSettingsNavbar && "lg:px-8",
      )}
    >
      <div
        className={cn(
          "flex h-16 items-center gap-x-4 border-b border-muted px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:shadow-none",
          "bg-background text-foreground",
          isSettingsNavbar ? "lg:px-8" : "lg:px-0",
        )}
      >
        <button
          type="button"
          className="-m-2.5 p-2.5 text-neutral-700 lg:hidden"
          onClick={() => setMobileSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon
            className={clsx("h-6 w-6", "text-foreground")}
            aria-hidden="true"
          />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <SearchInput />
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <NotificationButton
              Icon={<BellIcon className="h-6 w-6" aria-hidden="true" />}
            />

            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
              aria-hidden="true"
            />

            {/* Profile dropdown */}
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
