import { create } from "zustand";

interface SidebarStore {
  collapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  mobileSidebarOpen: boolean;
  setMobileSidebarOpen: (isOpen: boolean) => void;
  scrollable: boolean;
  setIsScrollable: (scrollable: boolean) => void;
}

export const useSidebarStore = create<SidebarStore>((set) => ({
  collapsed: true,
  setIsCollapsed: (isCollapsed: boolean) => set({ collapsed: isCollapsed }),
  mobileSidebarOpen: false,
  setMobileSidebarOpen: (isOpen: boolean) => set({ mobileSidebarOpen: isOpen }),
  scrollable: false,
  setIsScrollable: (isScrollable: boolean) => set({ scrollable: isScrollable }),
}));
