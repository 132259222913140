/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FormikErrors, FormikTouched } from "formik";

export const DebugForm = ({
  values,
  errors,
  touched,
}: {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  values: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  errors: FormikErrors<any>;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  touched: FormikTouched<any>;
}) => {
  return (
    <div className="grid gap-2">
      <div className="flex gap-2">
        <span className="text-yellow-500">Values:</span>
        {JSON.stringify(values, null, 2)}
      </div>

      {errors && touched && (
        <div className="flex gap-2">
          <span className="text-red-500">Errors:</span>
          {JSON.stringify(errors, null, 2)}
        </div>
      )}
    </div>
  );
};
