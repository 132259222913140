import { useApplyTheme } from "@/helper/hooks/useApplyTheme";
import type React from "react";

interface Props {
	children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
	useApplyTheme();

	return <>{children}</>;
};
export default Layout;
