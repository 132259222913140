import Combobox from "@/components/ui/Combobox";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/helper/lib/utils";
import type { FC } from "react";
import { DestructedFormikField } from "./DestructedField";

interface ComboboxComponentProps {
  FieldName: string;
  Options: { value: string | number; label: string }[];
  DefaultLabel: string;
  EmptyText: string;
  PlaceholderText: string;
  isLoading?: boolean;
  buttonClasses?: string;
  buttonClassForWidth?: string;
  doesHaveParentType?: boolean;
}

export const ComboboxComponent: FC<ComboboxComponentProps> = ({
  FieldName,
  DefaultLabel,
  EmptyText,
  Options,
  PlaceholderText,
  isLoading,
  buttonClassForWidth,
  buttonClasses,
  doesHaveParentType,
}) => {
  return isLoading ? (
    <Skeleton className="h-8 w-[200px]" />
  ) : (
    <DestructedFormikField FieldName={FieldName}>
      {({ field, form }) => {
        const formError = doesHaveParentType
          ? form?.errors?.[FieldName.split(".")[0]]?.[FieldName.split(".")[1]]
          : form?.errors?.[FieldName];
        const formTouched = doesHaveParentType
          ? form?.touched?.[FieldName.split(".")[0]]?.[FieldName.split(".")[1]]
          : form?.touched?.[FieldName];
        return (
          <Combobox
            Options={Options}
            field={field}
            form={form}
            DefaultLabel={DefaultLabel}
            EmptyText={EmptyText}
            PlaceholderText={PlaceholderText}
            buttonClasses={cn(
              buttonClasses && buttonClasses,
              formError && formTouched ? "border border-destructive" : "",
            )}
            buttonClassForWidth={buttonClassForWidth}
          />
        );
      }}
    </DestructedFormikField>
  );
};
