import { useAuthStore } from "@/helper/store/authStore";
import { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { useLoaderData } from "react-router-dom";

import {
  AlertCircleIcon,
  BanIcon,
  CalendarRangeIcon,
  CitrusIcon,
  CreditCardIcon,
  DollarSignIcon,
  DumbbellIcon,
  Flower2Icon,
  FolderKanbanIcon,
  MedalIcon,
  NutOffIcon,
  SettingsIcon,
  ShoppingBagIcon,
  SquareChartGanttIcon,
  TagIcon,
  UsersIcon,
} from "lucide-react";

// User Management
import UserCreate from "@/components/Admin/User/UserCreate";
import { UserEdit } from "@/components/Admin/User/UserEdit";
import UserList from "@/components/Admin/User/UserList";
import UserShow from "@/components/Admin/User/UserShow";

// User Settings
import CreateUserSettings from "@/components/Admin/UserSettings/CreateUserSettings";
import EditUserSettings from "@/components/Admin/UserSettings/EditUserSettings";
import UserSettingsList from "@/components/Admin/UserSettings/UserSettingsList";
import UserSettingsShow from "@/components/Admin/UserSettings/UserSettingsShow";

// Roles
import CreateRole from "@/components/Admin/Role/CreateRole";
import EditRole from "@/components/Admin/Role/EditRole";
import RoleList from "@/components/Admin/Role/RoleList";
import RoleShow from "@/components/Admin/Role/RoleShow";

// Subscription Plans
import CreateSubscriptionPlan from "@/components/Admin/SubscriptionPlan/CreateSubscriptionPlan";
import EditSubscriptionPlan from "@/components/Admin/SubscriptionPlan/EditSubscriptionPlan";
import SubscriptionPlanList from "@/components/Admin/SubscriptionPlan/SubscriptionPlanList";
import SubscriptionPlanShow from "@/components/Admin/SubscriptionPlan/SubscriptionPlanShow";

// User Subscriptions
import CreateUserSubscription from "@/components/Admin/UserSubscription/CreateUserSubscription";
import EditUserSubscription from "@/components/Admin/UserSubscription/EditUserSubscription";
import UserSubscriptionList from "@/components/Admin/UserSubscription/UserSubscriptionList";
import UserSubscriptionShow from "@/components/Admin/UserSubscription/UserSubscriptionShow";

// Foods
import CreateFood from "@/components/Admin/Food/CreateFood";
import EditFood from "@/components/Admin/Food/EditFood";
import { FoodList } from "@/components/Admin/Food/FoodList";
import FoodShow from "@/components/Admin/Food/FoodShow";

// Food Types
import CreateFoodType from "@/components/Admin/FoodType/CreateFoodType";
import EditFoodType from "@/components/Admin/FoodType/EditFoodType";
import FoodTypeList from "@/components/Admin/FoodType/FoodTypeList";
import FoodTypeShow from "@/components/Admin/FoodType/FoodTypeShow";

// Brands
import BrandList from "@/components/Admin/Brand/BrandList";
import BrandShow from "@/components/Admin/Brand/BrandShow";
import CreateBrand from "@/components/Admin/Brand/CreateBrand";
import EditBrand from "@/components/Admin/Brand/EditBrand";

// Brand Categories
import { BrandCategoryList } from "@/components/Admin/BrandCategory/BrandCategoryList";
import BrandCategoryShow from "@/components/Admin/BrandCategory/BrandCategoryShow";
import CreateBrandCategory from "@/components/Admin/BrandCategory/CreateBrandCategory";
import EditBrandCategory from "@/components/Admin/BrandCategory/EditBrandCategory";

// Brand Subcategories
import BrandSubcategoryList from "@/components/Admin/BrandSubcategory/BrandSubcategoryList";
import CreateBrandSubcategory from "@/components/Admin/BrandSubcategory/CreateBrandSubcategory";
import EditBrandSubcategory from "@/components/Admin/BrandSubcategory/EditBrandSubcategory";

import CreateUserBan from "@/components/Admin/UserBan/CreateUserBan";
import EditUserBan from "@/components/Admin/UserBan/EditUserBan";
import { UserBanList } from "@/components/Admin/UserBan/UserBanList";
import UserBanShow from "@/components/Admin/UserBan/UserBanShow";
import { dataProvider } from "@/helper/functions/dataProvider";

// Add these imports at the top with other imports
import AllergenList from "@/components/Admin/Allergen/AllergenList";
import AllergenShow from "@/components/Admin/Allergen/AllergenShow";
import CreateAllergen from "@/components/Admin/Allergen/CreateAllergen";
import EditAllergen from "@/components/Admin/Allergen/EditAllergen";

import BrandSubcategoryShow from "@/components/Admin/BrandSubcategory/BrandSubcategoryShow";
import CreateExercise from "@/components/Admin/Exercise/CreateExercise";
import EditExercise from "@/components/Admin/Exercise/EditExercise";
import ExerciseList from "@/components/Admin/Exercise/ExerciseList";
import ExerciseShow from "@/components/Admin/Exercise/ExerciseShow";
import CreateExerciseProgram from "@/components/Admin/ExerciseProgram/CreateExerciseProgram";
import EditExerciseProgram from "@/components/Admin/ExerciseProgram/EditExerciseProgram";
import ExerciseProgramList from "@/components/Admin/ExerciseProgram/ExerciseProgramList";
import ExerciseProgramShow from "@/components/Admin/ExerciseProgram/ExerciseProgramShow";

// Add these imports with other imports
import CreateExerciseProgramDetail from "@/components/Admin/ExerciseProgramDetail/CreateExerciseProgramDetail";
import EditExerciseProgramDetail from "@/components/Admin/ExerciseProgramDetail/EditExerciseProgramDetail";
import ExerciseProgramDetailList from "@/components/Admin/ExerciseProgramDetail/ExerciseProgramDetailList";
import ExerciseProgramDetailShow from "@/components/Admin/ExerciseProgramDetail/ExerciseProgramDetailShow";
import { ListCheckIcon } from "lucide-react";

// Add these imports
import CreateDietician from "@/components/Admin/Dietician/CreateDietician";
import DieticianList from "@/components/Admin/Dietician/DieticianList";
import DieticianShow from "@/components/Admin/Dietician/DieticianShow";
import EditDietician from "@/components/Admin/Dietician/EditDietician";

import CreateTrainer from "@/components/Admin/Trainer/CreateTrainer";
import EditTrainer from "@/components/Admin/Trainer/EditTrainer";
import TrainerList from "@/components/Admin/Trainer/TrainerList";
import TrainerShow from "@/components/Admin/Trainer/TrainerShow";

// Update imports
import CreateHealthCondition from "@/components/Admin/HealthCondition/CreateHealthCondition";
import EditHealthCondition from "@/components/Admin/HealthCondition/EditHealthCondition";
import HealthConditionList from "@/components/Admin/HealthCondition/HealthConditionList";
import HealthConditionShow from "@/components/Admin/HealthCondition/HealthConditionShow";
import CreateUserRole from "@/components/Admin/UserRoles/CreateUserRole";
import EditUserRole from "@/components/Admin/UserRoles/EditUserRole";
import UserRoleList from "@/components/Admin/UserRoles/UserRoleList";
import UserRoleShow from "@/components/Admin/UserRoles/UserRoleShow";

import CreateFoodBarcode from "@/components/Admin/FoodBarcode/CreateFoodBarcode";
import EditFoodBarcode from "@/components/Admin/FoodBarcode/EditFoodBarcode";
import FoodBarcodeList from "@/components/Admin/FoodBarcode/FoodBarcodeList";
import FoodBarcodeShow from "@/components/Admin/FoodBarcode/FoodBarcodeShow";
// Add this import at the top with other imports
import { BarcodeIcon } from "lucide-react";

import CreateFoodDetail from "@/components/Admin/FoodDetail/CreateFoodDetail";
// Add these imports at the top
import EditFoodDetail from "@/components/Admin/FoodDetail/EditFoodDetail";
import FoodDetailList from "@/components/Admin/FoodDetail/FoodDetailList";
import FoodDetailShow from "@/components/Admin/FoodDetail/FoodDetailShow";
import { AdminDashboardShell } from "@/components/Shells/AdminDashboardShell";
import { ClipboardListIcon } from "lucide-react";

// Add these imports at the top
import CreateFoodHealthScore from "@/components/Admin/FoodHealthScore/CreateFoodHealthScore";
import EditFoodHealthScore from "@/components/Admin/FoodHealthScore/EditFoodHealthScore";
import FoodHealthScoreList from "@/components/Admin/FoodHealthScore/FoodHealthScoreList";
import FoodHealthScoreShow from "@/components/Admin/FoodHealthScore/FoodHealthScoreShow";
import { StarIcon } from "lucide-react";

// Add these imports at the top
import CreateFoodHealthWarning from "@/components/Admin/FoodHealthWarning/CreateFoodHealthWarning";
import EditFoodHealthWarning from "@/components/Admin/FoodHealthWarning/EditFoodHealthWarning";
import FoodHealthWarningList from "@/components/Admin/FoodHealthWarning/FoodHealthWarningList";
import FoodHealthWarningShow from "@/components/Admin/FoodHealthWarning/FoodHealthWarningShow";
import { AlertTriangleIcon } from "lucide-react";

// Add these imports at the top
import CreateFoodVerification from "@/components/Admin/FoodVerification/CreateFoodVerification";
import EditFoodVerification from "@/components/Admin/FoodVerification/EditFoodVerification";
import FoodVerificationList from "@/components/Admin/FoodVerification/FoodVerificationList";
import FoodVerificationShow from "@/components/Admin/FoodVerification/FoodVerificationShow";
import { ShieldCheckIcon } from "lucide-react";

interface AdminLoaderData {
  role: string;
}

const AdminPage = () => {
  const { setRole } = useAuthStore();
  const loaderData = useLoaderData() as AdminLoaderData;

  // Set role from loader data as an extra security measure
  useEffect(() => {
    if (loaderData.role === "admin") {
      setRole(loaderData.role);
    }
  }, [loaderData.role, setRole]);

  return (
    <Admin
      basename="/admin"
      dataProvider={dataProvider}
      title="Admin Dashboard"
      layout={AdminDashboardShell}
    >
      {/* User Management */}
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        show={UserShow}
        icon={UsersIcon}
        options={{ label: "Users" }}
      />
      <Resource
        name="user_settings"
        list={UserSettingsList}
        create={CreateUserSettings}
        edit={EditUserSettings}
        show={UserSettingsShow}
        icon={SettingsIcon}
        options={{ label: "User Settings" }}
      />
      <Resource
        name="user_roles"
        list={UserRoleList}
        create={CreateUserRole}
        edit={EditUserRole}
        show={UserRoleShow}
        icon={TagIcon}
        options={{ label: "User Roles" }}
      />
      <Resource
        name="roles"
        list={RoleList}
        create={CreateRole}
        edit={EditRole}
        show={RoleShow}
        icon={TagIcon}
        options={{ label: "Roles" }}
      />

      {/* Subscription Management */}
      <Resource
        name="subscription_plans"
        list={SubscriptionPlanList}
        create={CreateSubscriptionPlan}
        edit={EditSubscriptionPlan}
        show={SubscriptionPlanShow}
        icon={DollarSignIcon}
        options={{ label: "Subscription Plans" }}
      />
      <Resource
        name="user_subscriptions"
        list={UserSubscriptionList}
        create={CreateUserSubscription}
        edit={EditUserSubscription}
        show={UserSubscriptionShow}
        icon={CreditCardIcon}
        options={{ label: "User Subscriptions" }}
      />

      {/* Food Management */}
      <Resource
        name="foods"
        list={FoodList}
        edit={EditFood}
        create={CreateFood}
        show={FoodShow}
        icon={CitrusIcon}
        options={{ label: "Foods" }}
      />
      <Resource
        name="food_details"
        list={FoodDetailList}
        create={CreateFoodDetail}
        edit={EditFoodDetail}
        show={FoodDetailShow}
        icon={ClipboardListIcon}
        options={{ label: "Food Details" }}
      />
      <Resource
        name="food_types"
        list={FoodTypeList}
        create={CreateFoodType}
        edit={EditFoodType}
        show={FoodTypeShow}
        icon={TagIcon}
        options={{ label: "Food Types" }}
      />
      <Resource
        name="food_barcodes"
        list={FoodBarcodeList}
        create={CreateFoodBarcode}
        edit={EditFoodBarcode}
        show={FoodBarcodeShow}
        icon={BarcodeIcon}
        options={{ label: "Food Barcodes" }}
      />
      <Resource
        name="food_health_scores"
        list={FoodHealthScoreList}
        create={CreateFoodHealthScore}
        edit={EditFoodHealthScore}
        show={FoodHealthScoreShow}
        icon={StarIcon}
        options={{ label: "Food Health Scores" }}
      />
      <Resource
        name="food_health_warnings"
        list={FoodHealthWarningList}
        create={CreateFoodHealthWarning}
        edit={EditFoodHealthWarning}
        show={FoodHealthWarningShow}
        icon={AlertTriangleIcon}
        options={{ label: "Food Health Warnings" }}
      />
      <Resource
        name="food_verification"
        list={FoodVerificationList}
        create={CreateFoodVerification}
        edit={EditFoodVerification}
        show={FoodVerificationShow}
        icon={ShieldCheckIcon}
        options={{ label: "Food Verification" }}
      />

      {/* Brand Management */}
      <Resource
        name="brands"
        list={BrandList}
        create={CreateBrand}
        edit={EditBrand}
        show={BrandShow}
        icon={ShoppingBagIcon}
        options={{ label: "Brands" }}
      />
      <Resource
        name="brand_categories"
        list={BrandCategoryList}
        create={CreateBrandCategory}
        edit={EditBrandCategory}
        show={BrandCategoryShow}
        icon={FolderKanbanIcon}
        options={{ label: "Brand Categories" }}
      />
      <Resource
        name="brand_subcategories"
        list={BrandSubcategoryList}
        create={CreateBrandSubcategory}
        edit={EditBrandSubcategory}
        show={BrandSubcategoryShow}
        icon={SquareChartGanttIcon}
        options={{ label: "Brand Subcategories" }}
      />

      {/* User Ban Management */}
      <Resource
        name="user_bans"
        list={UserBanList}
        create={CreateUserBan}
        edit={EditUserBan}
        show={UserBanShow}
        icon={BanIcon}
        options={{ label: "User Bans" }}
      />

      {/* Health Management */}
      <Resource
        name="allergens"
        list={AllergenList}
        create={CreateAllergen}
        edit={EditAllergen}
        show={AllergenShow}
        icon={NutOffIcon}
        options={{ label: "Allergens" }}
      />
      <Resource
        name="health_conditions"
        list={HealthConditionList}
        create={CreateHealthCondition}
        edit={EditHealthCondition}
        show={HealthConditionShow}
        icon={AlertCircleIcon}
        options={{ label: "Health Conditions" }}
      />
      <Resource
        name="exercises"
        list={ExerciseList}
        create={CreateExercise}
        edit={EditExercise}
        show={ExerciseShow}
        icon={DumbbellIcon}
      />
      <Resource
        name="exercise_programs"
        list={ExerciseProgramList}
        create={CreateExerciseProgram}
        edit={EditExerciseProgram}
        show={ExerciseProgramShow}
        icon={CalendarRangeIcon}
        options={{ label: "Exercise Programs" }}
      />
      <Resource
        name="exercise_programs_details"
        list={ExerciseProgramDetailList}
        create={CreateExerciseProgramDetail}
        edit={EditExerciseProgramDetail}
        show={ExerciseProgramDetailShow}
        icon={ListCheckIcon}
        options={{ label: "Program Details" }}
      />

      <Resource
        name="dieticians"
        list={DieticianList}
        create={CreateDietician}
        edit={EditDietician}
        show={DieticianShow}
        icon={Flower2Icon}
        options={{ label: "Dieticians" }}
      />
      <Resource
        name="trainers"
        list={TrainerList}
        create={CreateTrainer}
        edit={EditTrainer}
        show={TrainerShow}
        icon={MedalIcon}
        options={{ label: "Trainers" }}
      />
    </Admin>
  );
};

export default AdminPage;
