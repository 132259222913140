import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const FoodBarcodeFilters = [
  <TextInput
    key="barcode"
    source="barcode"
    label="Search by barcode"
    alwaysOn
  />,
  <ReferenceInput key="food_id" source="food_id" reference="foods">
    <SelectInput label="Food" optionText="name" />
  </ReferenceInput>,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FoodBarcodeList = () => (
  <List
    filters={FoodBarcodeFilters}
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="barcode" />
      <ReferenceField source="food_id" reference="foods">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DateField source="deleted_at" />
    </Datagrid>
  </List>
);

export default FoodBarcodeList;
