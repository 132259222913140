import { useToast } from "@/components/ui/use-toast";
import { fetchData } from "@/helper/functions/fetch";
import { useFormStore } from "@/helper/store/formStore";
import { ToastAction } from "@radix-ui/react-toast";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import type { FC } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog";
import type { EditDialogProp } from "./EditDialog";

export const DeleteAlert: FC<
  Pick<EditDialogProp, "FieldLabel" | "Endpoint" | "OnSuccess">
> = ({ FieldLabel, Endpoint, OnSuccess }) => {
  const { toast } = useToast();
  const deleteValue = async () => {
    try {
      const response = await fetchData(Endpoint, "DELETE", {}, false);

      if (response.ok) {
        toast({
          title: `${FieldLabel} deleted`,
          description: `You have deleted the ${FieldLabel.toLowerCase()}.`,
        });
        setTimeout(() => OnSuccess(), 1300);
      } else {
        console.error("Unexpected response status:", response.status);
        toast({
          title: `Failed to delete ${FieldLabel.toLowerCase()}`,
          description: `Unexpected response status: ${response.status}`,
          variant: "destructive",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
      }
    } catch (error) {
      console.error("Network or server error:", error);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: `There was a problem with your request. Error:${error}`,
        action: <ToastAction altText="Try again">Try again</ToastAction>,
      });
    }
  };
  const { deleteModal, setDeleteModal } = useFormStore();
  return (
    <AlertDialog open={deleteModal} onOpenChange={setDeleteModal}>
      <VisuallyHidden.Root>
        <AlertDialogTrigger type="button">Open</AlertDialogTrigger>
      </VisuallyHidden.Root>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          {/* TODO add a link here to show where softly delete ones to revert */}
          <AlertDialogDescription>
            You can undelete the action in the Settings-Deleted tab in 30 days.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel type="button">Cancel</AlertDialogCancel>
          <AlertDialogAction type="button" onClick={deleteValue}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
