/* eslint-disable @typescript-eslint/no-explicit-any */
import { GoalsAndActivityValidationSchema } from "@/helper/Schemas/OnboardingSchemas";
import { useStepper } from "@/helper/hooks/useStepper";
import { fieldTypeBuilder } from "@/helper/lib/utils";
import { activityLevels, goals } from "@/helper/static/Onboarding/arrays";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";
import { BoardingCombobox, BoardingRadioInput } from "../UIElements";

const HealthGoalFieldName = fieldTypeBuilder("health_profile", "health_goal");
const ActivityLevelFieldName = fieldTypeBuilder(
  "health_profile",
  "activity_level",
);
export function GoalsAndActivity() {
  const {
    boarding,
    setBoarding,
    nextStep,
    stepNumber,
    setStepValidity,
    setReviewMode,
  } = useBoardingStore();

  const initValues = {
    health_profile: {
      activity_level: boarding.health_profile?.activity_level,
      health_goal: boarding.health_profile?.health_goal,
    },
  };

  const { nextStep: stepperNextFunction } = useStepper();
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmitHandler = (values: any) => {
    setBoarding(values);
    setReviewMode(true);

    stepperNextFunction();
    nextStep();
    setStepValidity(stepNumber, true);
  };

  return (
    <MultiStepComponentFormWrapper
      initValues={initValues}
      onSubmit={onSubmitHandler}
      validationSchema={GoalsAndActivityValidationSchema}
    >
      <div className="flex w-full flex-col items-center justify-center gap-2 sm:flex-row">
        <div className={"lg:w-2/3"}>
          <div className="space-y-2 md:min-w-44">
            <BoardingRadioInput
              FieldName={HealthGoalFieldName}
              Values={goals}
              InputLabel="Hedef Seçiniz"
              defaultValue={
                boarding.health_profile?.health_goal || goals[0].value
              }
            />
          </div>
        </div>
        <div className="grid gap-2">
          <BoardingCombobox
            FieldName={ActivityLevelFieldName}
            Options={activityLevels}
            DefaultLabel="Aktivite Seviyesi Seçiniz"
            EmptyText="Aktivite seviyesi bulunamadı."
            PlaceholderText="Aktivite seviyesini arayın..."
            TooltipContent="Aktivite seviyenizi buradan seçebilirsiniz. Seçenekler arasında şunlar yer alır: Sedanter, Hafif Egzersiz (1-3 gün), Orta Egzersiz (3-5 gün), Ağır Egzersiz (6-7 gün), Profesyonel Sporcu veya fiziksel işçilik gerektiren yoğun aktiviteler."
          />
        </div>
      </div>
    </MultiStepComponentFormWrapper>
  );
}
