import FoodForm from "../../components/Forms/FormComponents/FoodForm";

// öğün ekleme bölümü olacak istediği öğünün ismini verebilecek.
// yemek listesi fetch edilirken loading ekranı gösterilecek.
// yemek ekleme bölümü olacak.
// yemekleri hangi öğüne eklemek istediği sorulacak.
// Yemek ve öğüm sayfaları ayrı olabilir gerekirse.
// TODO bu sayfayı açtığında açar açmaz yemek listesi fetch edilecek. ve combobox ile gösterilecek.

function FoodPage() {
  return (
    <>
      <section className="grid gap-6">
        <h1 className="select-none text-3xl font-semibold leading-6">
          Add new Food
        </h1>
        <FoodForm />
      </section>
    </>
  );
}

export default FoodPage;
