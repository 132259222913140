/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { useMediaQuery } from "@/helper/hooks/useMediaQuery";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import clsx from "clsx";
import { Check, ChevronsUpDown } from "lucide-react";
import type React from "react";
import { useState } from "react";
import { cn } from "@/helper/lib/utils.ts";

type Status = {
  value: string | number;
  label: string;
};

interface Props {
  Options: Array<Status>;
  DefaultLabel: string;
  EmptyText: string;
  PlaceholderText: string;
  onChange?: (value: string | number) => void;
  formValue?: string | number;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  field?: any; // Optional
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  form?: any; // Optional
  buttonClassForWidth?: string;
  buttonClasses?: string;
}

const Combobox: React.FC<Props> = ({
  Options,
  DefaultLabel,
  EmptyText,
  PlaceholderText,
  onChange,
  formValue,
  field,
  form,
  buttonClassForWidth,
  buttonClasses,
}) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [localValue, setLocalValue] = useState(""); // Value for local state
  const handleSelect = (value: string | number) => {
    // If Formik is used, update the Formik state, otherwise, update the local state
    if (value && onChange) {
      onChange(value);
    } else if (field && form) {
      form.setFieldValue(field.name, value);
    } else {
      setLocalValue(value.toString());
    }
    setOpen(false);
  };
  const displayValue = formValue ? formValue : field ? field.value : localValue;

  if (isDesktop) {
    return (
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              buttonClasses && buttonClasses,
              buttonClassForWidth ? buttonClassForWidth : "w-[200px]",
              "justify-between",
            )}
          >
            {displayValue
              ? Options.find((option) => option.value === displayValue)?.label
              : DefaultLabel}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder={PlaceholderText} />
            <CommandList>
              {Options.length === 0 ? (
                <CommandEmpty>{EmptyText}</CommandEmpty>
              ) : (
                <CommandGroup>
                  {Options?.map((option) => (
                    <CommandItem
                      key={option?.value}
                      onSelect={() => handleSelect(option?.value as string)}
                    >
                      <Check
                        className={clsx(
                          "mr-2 h-4 w-4",
                          displayValue === option?.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                      {option?.label || "Empty"}
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button variant="outline" className="w-full justify-start">
          {displayValue
            ? Options.find((option) => option.value === displayValue)?.label
            : DefaultLabel}
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <VisuallyHidden.Root>
          <DrawerHeader>
            <DrawerTitle>{DefaultLabel}</DrawerTitle>
            <DrawerDescription>{PlaceholderText}</DrawerDescription>
          </DrawerHeader>
        </VisuallyHidden.Root>
        <div className="mt-4 border-t">
          <DrawerCommand
            handleSelect={handleSelect}
            List={Options}
            PlaceHolder={PlaceholderText}
          />
        </div>
      </DrawerContent>
    </Drawer>
  );
};

function DrawerCommand({
  List,
  handleSelect,
  PlaceHolder,
}: {
  List: Array<Status>;
  handleSelect: (value: string | number) => void;
  PlaceHolder: string;
}) {
  return (
    <Command>
      <CommandInput placeholder={PlaceHolder} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {List.map((status) => (
            <CommandItem
              key={status.value}
              value={status?.value?.toString()}
              onSelect={(value) => handleSelect(value as string)}
            >
              {status.label}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

export default Combobox;
