import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  NumberInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const ExerciseFilters = [
  <TextInput key="name" source="name" label="Name" alwaysOn />,
  <TextInput key="description" source="description" label="Description" />,
  <NumberInput
    key="recommended_reps"
    source="recommended_reps"
    label="Recommended Reps"
  />,
  <NumberInput
    key="recommended_sets"
    source="recommended_sets"
    label="Recommended Sets"
  />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const ExerciseList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "name", order: "ASC" }}
    filters={ExerciseFilters}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <NumberField source="recommended_reps" />
      <NumberField source="recommended_sets" />
      <NumberField source="calories_burned_per_rep" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="exercises" />
    </Datagrid>
  </List>
);

export default ExerciseList;
