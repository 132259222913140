import {
  CreateButton,
  Datagrid,
  ExportButton,
  FilterButton,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const RoleFilters = [
  <TextInput key="name" source="name" label="Search by name" alwaysOn />,
  <TextInput key="id" source="id" label="Role ID" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const RoleList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "name", order: "ASC" }}
    filters={RoleFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
    </Datagrid>
  </List>
);

export default RoleList;
