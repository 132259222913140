import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const DieticianFilters = [
  <TextInput key="user_id" source="user_id" label="User ID" alwaysOn />,
  <TextInput key="bio" source="bio" label="Bio" />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const DieticianList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
    filters={DieticianFilters}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="users" label="User">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="bio" />
      <NumberField source="years_of_experience" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="dieticians" />
    </Datagrid>
  </List>
);

export default DieticianList;
