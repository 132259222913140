import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  DeleteButton,
  FilterButton,
  CreateButton,
  TopToolbar,
  ReferenceInput,
  SelectInput,
  ShowButton,
} from "react-admin";
import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";

const UserRoleFilters = [
  <ReferenceInput source="user_id" reference="users" alwaysOn key="user_id">
    <SelectInput
      label="User"
      optionText={(record) =>
        record
          ? `${record.first_name} ${record.last_name} (${record.email})`
          : ""
      }
    />
  </ReferenceInput>,
  <ReferenceInput source="role_id" reference="roles" alwaysOn key="role_id">
    <SelectInput label="Role" optionText={(record) => record?.name || ""} />
  </ReferenceInput>,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
  </TopToolbar>
);

export const UserRoleList = () => (
  <List
    actions={<ListActions />}
    filters={UserRoleFilters}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <ReferenceField
        source="user_id"
        reference="users"
        label="User"
        link={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        source="user_id"
        reference="users"
        label="Name"
        link={false}
      >
        <TextField source="first_name" />
      </ReferenceField>
      <ReferenceField
        source="role_id"
        reference="roles"
        label="Role"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="is_admin" label="Admin Access" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <ShowButton />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="user_roles" />
    </Datagrid>
  </List>
);

export default UserRoleList;
