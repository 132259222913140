import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
} from "react-admin";

export const UserRoleShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          User Role Details
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <ReferenceField source="user_id" reference="users" label="User">
              <TextField source="email" />
            </ReferenceField>
          </Grid>
          <Grid size={6}>
            <ReferenceField source="role_id" reference="roles" label="Role">
              <TextField source="name" />
            </ReferenceField>
          </Grid>
          <Grid size={6}>
            <BooleanField source="is_admin" label="Admin Access" />
          </Grid>
          <Grid size={6}>
            <DateField source="created_at" label="Created At" />
          </Grid>
          <Grid size={6}>
            <DateField source="updated_at" label="Last Updated" />
          </Grid>
          <Grid size={6}>
            <DateField source="deleted_at" label="Deleted At" />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          User Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <ReferenceField source="user_id" reference="users">
              <TextField source="first_name" label="First Name" />
            </ReferenceField>
          </Grid>
          <Grid size={6}>
            <ReferenceField source="user_id" reference="users">
              <TextField source="last_name" label="Last Name" />
            </ReferenceField>
          </Grid>
          <Grid size={12}>
            <ReferenceField source="user_id" reference="users">
              <TextField source="email" label="Email" />
            </ReferenceField>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Role Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <ReferenceField source="role_id" reference="roles">
              <TextField source="description" label="Role Description" />
            </ReferenceField>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserRoleShow;
