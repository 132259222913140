import type React from "react";
import { create } from "zustand";
import { HealthGoal } from "../graphql/generated/graphql";
import { defaultBirthDate } from "../lib/utils";
import type { CustomCreateUserInput } from "../types/Boarding";

interface MultiStepForm {
	number: number;
	title: string;
	description: string;
	content: React.JSX.Element;
}

interface BoardingStore {
	boarding: CustomCreateUserInput;
	setBoarding: (update: Partial<CustomCreateUserInput>) => void;
	reviewMode: boolean;
	setReviewMode: (value: boolean) => void;
	stepNumber: number;
	setStepNumber: (stepNumber: number) => void;
	previousStep: () => void;
	nextStep: () => void;
	MultiStepForm: MultiStepForm[];
	setMultiStepForm: (update: MultiStepForm[]) => void;
	stepsValidity: { [key: number]: boolean };
	setStepValidity: (stepNumber: number, isValid: boolean) => void;
}

export const useBoardingStore = create<BoardingStore>((set) => ({
	boarding: {
		username: "",
		email: "",
		provider: "",
		social_id: "",
		first_name: "",
		last_name: "",
		is_active: false,
		profile_picture: "",
		provider_profile_picture: "",
		health_profile: {
			weight: 0,
			height: 0,
			birth_date: defaultBirthDate,
			gender: "male",
			activity_level: 0,
			health_goal: HealthGoal.LoseWeight,
			smoking_status: false,
			alcohol_consumption: false,
			// custom_calorie_goal: 2000,
			// goal_start_date: new Date(),
			// goal_daily_water_intake: 2000,
			dietary_preference_id: "",
		},
		settings: {
			theme: "system",
			language: "",
			weight_unit: "",
			height_unit: "",
			water_unit: "",
			energy_unit: "",
			preferred_meal_country: "",
			country_code: "",
		},
		allergies: [],
		healthConditions: [],
		custom_meal_wanted: false,
	},
	setBoarding: (update) =>
		set((state) => ({
			...state,
			boarding: {
				...state.boarding,
				...update,
				health_profile: update.health_profile
					? { ...state.boarding.health_profile, ...update.health_profile }
					: state.boarding.health_profile,
				settings: update.settings
					? { ...state.boarding.settings, ...update.settings }
					: state.boarding.settings,
			},
		})),
	reviewMode: false,
	setReviewMode: (value) => set(() => ({ reviewMode: value })),
	GoalProfileChooser: {
		profileChooser: false,
	},
	stepNumber: -1,
	setStepNumber: (stepNumber) => set(() => ({ stepNumber })),
	previousStep: () => set((state) => ({ stepNumber: state.stepNumber - 1 })),
	nextStep: () => set((state) => ({ stepNumber: state.stepNumber + 1 })),
	MultiStepForm: [],
	setMultiStepForm: (update) => set(() => ({ MultiStepForm: update })),
	stepsValidity: {},
	setStepValidity: (stepNumber, isValid) =>
		set((state) => ({
			stepsValidity: {
				...state.stepsValidity,
				[stepNumber]: isValid,
			},
		})),
}));
