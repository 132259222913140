/* eslint-disable @typescript-eslint/no-explicit-any */
import ErrorAlert from "@/components/Forms/Alert";
import { ComboboxComponent } from "@/components/Forms/Inputs/ComboboxComponent";
import { InputComponent } from "@/components/Forms/Inputs/InputComponent";
import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { FormMultiSelect } from "@/components/Forms/Inputs/MultiSelectComponent";
import { FormCheckboxesAsRadio } from "@/components/Forms/Inputs/RadioCheckboxes";
import { RadioInputComponent } from "@/components/Forms/Inputs/RadioInputs";
import { Card } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { GraphQLUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { getAllergiesAndConditions } from "@/helper/graphql/queries";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { Field } from "formik";
import { motion } from "framer-motion";
import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { Label } from "../ui/label";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";

type BoardingInputFieldProps = {
  Name: string;
  Placeholder: string;
  InputLabel: string;
  InputType: "text" | "number";
  FieldID: string;
};

export function BoardingInputField({
  Name: FieldName,
  Placeholder,
  InputLabel,
  InputType = "text",
  FieldID,
}: BoardingInputFieldProps) {
  return (
    <div className="w-full space-y-2">
      <InputLabelComponent
        For={FieldID}
        LabelText={InputLabel}
        FieldName={FieldName}
      />
      <InputComponent
        Name={FieldName}
        Placeholder={Placeholder}
        InputType={InputType}
        FieldId={FieldID}
      />
      <InputError Name={FieldName} />
    </div>
  );
}

export function BoardingRow({
  children,
  classes,
  extraClasses,
  col,
  grid,
}: {
  children: ReactNode;
  classes?: string;
  col?: boolean;
  grid?: boolean;
  extraClasses?: string;
}) {
  return (
    <div
      className={
        classes ??
        clsx(
          "w-full",
          grid ? "grid" : "flex",
          col
            ? "flex-col"
            : "flex-col items-start gap-2 sm:flex-row sm:items-center sm:justify-between",
          extraClasses,
        )
      }
    >
      {children}
    </div>
  );
}

// Define the `initial`, `animate`, and `transition` props as constants
const initialProps = { opacity: 0 };
const animateProps = { opacity: 1 };
const transitionProps = { duration: 0.5, ease: "easeInOut" };

const MotionCard = motion.create(Card);

export const BoardingCard = ({ children }: { children: ReactNode }) => {
  return (
    <MotionCard
      className="row-span-2 flex w-full max-w-7xl flex-col border border-primary bg-stone-200 dark:bg-stone-800"
      initial={initialProps}
      animate={animateProps}
      transition={transitionProps}
    >
      {children}
    </MotionCard>
  );
};

export function BoardingCombobox({
  FieldName,
  DefaultLabel,
  EmptyText,
  Options,
  PlaceholderText,
  buttonClassForWidth,
  TooltipContent,
}: {
  FieldName: string;
  Options: { value: string | number; label: string }[];
  DefaultLabel: string;
  EmptyText: string;
  PlaceholderText: string;
  buttonClassForWidth?: string;
  TooltipContent: string;
}) {
  return (
    <div className={"flex flex-col gap-2 max-sm:w-full"}>
      <InputLabelComponent
        LabelText={DefaultLabel}
        FieldName={FieldName}
        tooltip
        Content={TooltipContent}
      />
      <ComboboxComponent
        DefaultLabel={DefaultLabel}
        EmptyText={EmptyText}
        FieldName={FieldName}
        Options={Options}
        PlaceholderText={PlaceholderText}
        buttonClassForWidth={buttonClassForWidth}
        doesHaveParentType
      />
      <InputError Name={FieldName} />
    </div>
  );
}

interface BoardingRadioInputValues {
  FieldName: string;
  Values: {
    value: string;
    label: string;
  }[];
  InputLabel: string;
  defaultValue: string;
  trueOrFalse?: boolean;
}

export function BoardingRadioInput({
  FieldName,
  Values,
  InputLabel,
  defaultValue,
  trueOrFalse = false,
}: BoardingRadioInputValues) {
  const valuesWithId = Values.map((item) => ({
    ...item,
    id: item.value,
  }));
  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <InputLabelComponent LabelText={InputLabel} FieldName={FieldName} />
      <RadioInputComponent
        FieldName={FieldName}
        Values={valuesWithId}
        defaultValue={defaultValue}
        trueOrFalse={trueOrFalse}
        InputLabel={InputLabel}
      />
      <InputError Name={FieldName} />
    </div>
  );
}

interface BoardingCustomGoalChoiceInputProps {
  FieldName: string;
  Values: { id: string; label: string; value: string }[];
  InputLabel: string;
  defaultValue: string;
  CustomFunc: Dispatch<SetStateAction<boolean>>;
  InputId: string;
}

export const BoardingCustomGoalChoiceInput: FC<
  BoardingCustomGoalChoiceInputProps
> = ({ CustomFunc, FieldName, InputLabel, Values, defaultValue, InputId }) => {
  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <InputLabelComponent
        LabelText={InputLabel}
        For={InputId}
        FieldName={FieldName}
      />
      <Field name={FieldName}>
        {/*  biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
        {({ field, form }: { field: any; form: any }) => {
          const radioHandler = (value: string) => {
            const fieldValue = value === "yes";
            form.setFieldValue(field.name, fieldValue);
            CustomFunc(fieldValue as boolean);
          };
          return (
            <RadioGroup
              defaultValue={defaultValue}
              className="grid w-full items-center justify-start gap-2 lg:grid-flow-col"
            >
              {Values.map((item) => (
                <div className="flex items-center space-x-2" key={item.id}>
                  <RadioGroupItem
                    value={item.value}
                    id={item.id}
                    onClick={() => radioHandler(item.value)}
                  />
                  <Label htmlFor={item.id}>{item.label}</Label>
                </div>
              ))}
            </RadioGroup>
          );
        }}
      </Field>
      <InputError Name={FieldName} />
    </div>
  );
};

interface BoardingCheckboxesProps {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  Field: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  Form: any;
  Value: string;
  Text: string;
  FormKey: string;
}

export const BoardingCheckboxes: FC<BoardingCheckboxesProps> = ({
  Field,
  Form,
  FormKey,
  Text,
  Value,
}) => {
  return (
    <FormCheckboxesAsRadio
      Field={Field}
      Text={Text}
      Value={Value}
      Name={FormKey}
      Form={Form}
    />
  );
};

export const BoardingMotionDiv = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

export const BoardingMultiSelect = ({
  Name,
  Placeholder,
  searchPlaceholder,
}: {
  Name: string;
  Placeholder: string;
  searchPlaceholder: string;
}) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["allergiesAndConditions"],
    queryFn: () => GraphQLUrlBuilder(getAllergiesAndConditions),
    select: (data) => {
      const items = data ?? [];
      if (!Array.isArray(items)) {
        return [{ items: [] }];
      }
      const mappedItems = items.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      return [{ items: mappedItems }];
    },
    staleTime: 30 * 60 * 1000, // 30 minutes
    gcTime: 45 * 60 * 1000, // 45 minutes
  });

  if (isError) {
    return <ErrorAlert errorMessage={(error as Error).message} />;
  }

  if (isLoading) {
    return <Skeleton className="h-10 bg-background" />;
  }

  return (
    <FormMultiSelect
      name={Name}
      options={data ?? []}
      placeholder={Placeholder}
      searchPlaceholder={searchPlaceholder}
      width="w-full"
    />
  );
};
