import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const EditBrandSubcategory = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Brand Subcategory Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextInput
              source="subcategory_name"
              validate={[required()]}
              label="Subcategory Name"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <ReferenceInput
              source="brand_category_id"
              reference="brand_categories"
            >
              <SelectInput
                label="Brand Category"
                optionText="category_name"
                validate={[required()]}
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditBrandSubcategory;
