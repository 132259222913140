import { HealthGoal } from "@/helper/graphql/generated/graphql";
import { fieldTypeBuilder } from "@/helper/lib/utils";

export const activityLevels = [
	{ value: 1, label: "Minimal" },
	{ value: 2, label: "Low" },
	{ value: 3, label: "Moderate" },
	{ value: 4, label: "High" },
	{ value: 5, label: "Very High" },
];

export const languages = [
	{ value: "tr", label: "Türkçe" },
	{ value: "en", label: "English" },
];

export const weightUnits = [
	{ value: "g", label: "Gram" },
	{ value: "kg", label: "Kilogram" },
	{ value: "lb", label: "Pound" },
	{ value: "oz", label: "Ounce" },
];

export const servingSizeUnits = [
	{ value: "g", label: "Gram" },
	{ value: "mg", label: "Miligram" },
	{ value: "oz", label: "Ounce" },
	{ value: "cup", label: "Cup" },
	{ value: "tbsp", label: "Tablespoon" },
	{ value: "tsp", label: "Teaspoon" },
];

export const heightUnits = [
	{ value: "cm", label: "Centimeter" },
	{ value: "m", label: "Meter" },
	{ value: "ft-in", label: "Feet and Inches" },
];

export const waterUnits = [
	{ value: "ml", label: "Mililitre" },
	{ value: "liters", label: "Litre" },
	{ value: "oz", label: "Fluid Ounce" },
	{ value: "gal", label: "Gallon" },
];

export const energyUnits = [
	{ value: "kcal", label: "Kilocalorie" },
	{ value: "joules", label: "Joules" },
	{ value: "BTU", label: "British Thermal Unit" },
];

export const goals = [
	{ value: HealthGoal.LoseWeight, label: "Weight loss" },
	{ value: HealthGoal.GainMuscle, label: "Gain muscle" },
	{ value: HealthGoal.ImproveHealth, label: "Improve health" },
	{ value: HealthGoal.MaintainWeight, label: "Maintain weight" },
];

export const themes = [
	{ value: "light", label: "Light" },
	{ value: "dark", label: "Dark" },
	{ value: "system", label: "System" },
];

const FieldNameBuilder = (field: string) => fieldTypeBuilder("settings", field);

export const UserSettingsArray = [
	{
		id: 1,
		DefaultLabel: "Dil Seçiniz",
		EmptyText: "Dil bulunamadı.",
		PlaceholderText: "Dil arayın...",
		FieldName: FieldNameBuilder("language"),
		Options: languages,
		TooltipContent:
			"Uygulama dilini buradan seçebilirsiniz. Bu seçim, uygulama genelinde kullanılacak dili belirler.",
	},
	{
		id: 2,
		DefaultLabel: "Kilo Birimi Seçiniz",
		EmptyText: "Kilo birimi bulunamadı.",
		PlaceholderText: "Kilo birimi arayın...",
		FieldName: FieldNameBuilder("weight_unit"),
		Options: weightUnits,
		TooltipContent:
			"Kullanmak istediğiniz kilo birimini buradan seçebilirsiniz. Tercihiniz, uygulama genelinde bu birimin kullanılmasını sağlar.",
	},
	{
		id: 3,
		DefaultLabel: "Yükseklik Birimi Seçiniz",
		EmptyText: "Yükseklik birimi bulunamadı.",
		PlaceholderText: "Yükseklik birimi arayın...",
		FieldName: FieldNameBuilder("height_unit"),
		Options: heightUnits,
		TooltipContent:
			"Yükseklik birimi seçiminizi buradan yapabilirsiniz. Bu seçim, uygulama genelinde kullanılacak yükseklik birimini belirler.",
	},
	{
		id: 4,
		DefaultLabel: "Su Birimi Seçiniz",
		EmptyText: "Su birimi bulunamadı.",
		PlaceholderText: "Su birimi arayın...",
		FieldName: FieldNameBuilder("water_unit"),
		Options: waterUnits,
		TooltipContent:
			"Su birimi seçiminizi buradan yapabilirsiniz. Bu seçim, uygulamada kullanılacak su birimini belirler.",
	},
	{
		id: 5,
		DefaultLabel: "Enerji Birimi Seçiniz",
		EmptyText: "Enerji birimi bulunamadı.",
		PlaceholderText: "Enerji birimi arayın...",
		FieldName: FieldNameBuilder("energy_unit"),
		Options: energyUnits,
		TooltipContent:
			"Enerji birimini buradan seçebilirsiniz. Bu seçim, uygulamada kullanılacak enerji birimini belirler.",
	},
];
