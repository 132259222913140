/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query User($userId: ID!) {\n    user(id: $userId) {\n      id\n      username\n      email\n      first_name\n      last_name\n      is_active\n      profile_picture\n      provider_profile_picture\n      settings {\n        id\n        user_id\n        language\n        weight_unit\n        height_unit\n        water_unit\n        energy_unit\n        preferred_meal_country\n        country_code\n      }\n      role {\n        is_admin\n      }\n      ban {\n        is_banned\n        banned_at\n        ban_reason\n      }\n      health {\n        healthMetrics {\n          tdee\n          bmi\n          recommended_daily_water_intake\n        }\n        waterIntake {\n          amount\n          amount_unit\n        }\n        dailyCalories {\n          consumed_calories\n          burned_calories\n          net_calories\n        }\n      }\n    }\n  }\n": types.UserDocument,
    "\n  query AllergiesAndConditions {\n    allergies {\n      id\n      name\n    }\n    healthConditions {\n      id\n      name\n    }\n  }\n": types.AllergiesAndConditionsDocument,
    "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n": types.CreateUserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query User($userId: ID!) {\n    user(id: $userId) {\n      id\n      username\n      email\n      first_name\n      last_name\n      is_active\n      profile_picture\n      provider_profile_picture\n      settings {\n        id\n        user_id\n        language\n        weight_unit\n        height_unit\n        water_unit\n        energy_unit\n        preferred_meal_country\n        country_code\n      }\n      role {\n        is_admin\n      }\n      ban {\n        is_banned\n        banned_at\n        ban_reason\n      }\n      health {\n        healthMetrics {\n          tdee\n          bmi\n          recommended_daily_water_intake\n        }\n        waterIntake {\n          amount\n          amount_unit\n        }\n        dailyCalories {\n          consumed_calories\n          burned_calories\n          net_calories\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query User($userId: ID!) {\n    user(id: $userId) {\n      id\n      username\n      email\n      first_name\n      last_name\n      is_active\n      profile_picture\n      provider_profile_picture\n      settings {\n        id\n        user_id\n        language\n        weight_unit\n        height_unit\n        water_unit\n        energy_unit\n        preferred_meal_country\n        country_code\n      }\n      role {\n        is_admin\n      }\n      ban {\n        is_banned\n        banned_at\n        ban_reason\n      }\n      health {\n        healthMetrics {\n          tdee\n          bmi\n          recommended_daily_water_intake\n        }\n        waterIntake {\n          amount\n          amount_unit\n        }\n        dailyCalories {\n          consumed_calories\n          burned_calories\n          net_calories\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllergiesAndConditions {\n    allergies {\n      id\n      name\n    }\n    healthConditions {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query AllergiesAndConditions {\n    allergies {\n      id\n      name\n    }\n    healthConditions {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;