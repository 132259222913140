import {
  BooleanInput,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const providers = [
  { id: "google.com", name: "Google" },
  { id: "facebook.com", name: "Facebook" },
  { id: "apple.com", name: "Apple" },
  { id: "password", name: "Email & Password" },
];

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="username" />
      <TextInput source="email" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <BooleanInput source="is_active" />
      <BooleanInput source="is_admin" />
      <SelectInput source="provider" choices={providers} />
      <TextInput source="social_id" />
      <TextInput source="profile_picture" />
      <TextInput source="provider_profile_picture" />
    </SimpleForm>
  </Edit>
);
