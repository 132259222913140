import { Button } from "@/components/ui/button";
import { useFormikContext } from "formik";
import type { FC } from "react";

interface Props {
  Text?: string;
  classes?: string;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | null
    | undefined;
  LoadingText?: boolean;
  isOnboardingButton?: boolean;
}

const FormButton: FC<Props> = ({
  Text,
  classes,
  variant,
  LoadingText,
  isOnboardingButton,
}) => {
  const { isSubmitting, isValid, dirty } = useFormikContext();
  const ButtonText = Text ?? "Submit";

  const isDisabled = isSubmitting || !(isValid && dirty);
  const isOnboardingDisabled = isSubmitting || !isValid;

  return (
    <Button
      type="submit"
      isDisabled={isOnboardingButton ? isOnboardingDisabled : isDisabled}
      className={classes}
      variant={variant ?? "default"}
      isLoading={isSubmitting}
      isLoadingWithText={LoadingText}
    >
      {ButtonText}
    </Button>
  );
};
export default FormButton;
