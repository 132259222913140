/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik } from "formik";
import type { FC, ReactNode } from "react";
import { DebugForm } from "../Forms/DebugForm";
import { CardContent } from "../ui/card";
import { OnboardingFooter } from "./Footer";

interface MultiStepFormProps {
  children: ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  initValues: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onSubmit: any;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  validationSchema: any;
}

export const MultiStepComponentFormWrapper: FC<MultiStepFormProps> = ({
  children,
  initValues,
  onSubmit,
  validationSchema,
}) => {
  return (
    <Formik
      initialValues={initValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema || null}
    >
      {({ errors, touched, values }) => (
        <Form>
          <CardContent className="flex w-full flex-col gap-y-2 md:gap-y-4">
            {children}
          </CardContent>
          <OnboardingFooter />
          {import.meta.env.DEV && (
            <DebugForm values={values} errors={errors} touched={touched} />
          )}
        </Form>
      )}
    </Formik>
  );
};
