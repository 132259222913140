import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import request from "graphql-request";
import { getAuthToken } from "./auth/getAuthToken";

export function APIUrlBuilder(
  endpointUrl: string,
  queryParams?: Record<string, string>,
) {
  const base_url = import.meta.env.VITE_BACKEND_API_URL;
  const api_url = import.meta.env.VITE_BACKEND_API_ENDPOINT;

  // Correctly format the URL by ensuring exactly one slash between segments
  const trimmedBaseUrl = base_url.replace(/\/+$/, ""); // Remove trailing slashes
  const trimmedApiUrl = api_url.replace(/^\/+|\/+$/g, ""); // Remove leading/trailing slashes
  const trimmedEndpointUrl = endpointUrl.replace(/^\/+/, ""); // Remove leading slashes

  const fullUrl = `${trimmedBaseUrl}/${trimmedApiUrl}/${trimmedEndpointUrl}`;

  // Building query string manually if queryParams are provided
  let queryString = "";
  if (queryParams) {
    queryString = Object.entries(queryParams)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&");
  }

  return queryString ? `${fullUrl}?${queryString}` : fullUrl;
}

export async function GraphQLUrlBuilder<TResult, TVariables>(
  query: TypedDocumentNode<TResult, TVariables>,
  variables?: Record<string, unknown> | undefined,
  additionalHeaders?: HeadersInit,
): Promise<TResult> {
  const api_url = import.meta.env.VITE_GRAPHQL_API_URL;
  const token = await getAuthToken();

  if (!api_url) {
    throw new Error("GraphQL API URL is not defined");
  }

  try {
    new URL(api_url);
  } catch (error) {
    throw new Error(`Invalid GraphQL API URL: ${api_url}`);
  }

  const headers: HeadersInit = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
    ...additionalHeaders,
  };

  return request<TResult>(api_url, query, variables, headers);
}
