import { useBoardingStore } from "@/helper/store/boardingStore";
import FormButton from "../Forms/Buttons/FormButton";
import { Button } from "../ui/button";
import { CardFooter } from "../ui/card";
import { useStepper } from "@/helper/hooks/useStepper";

export const OnboardingFooter = () => {
  const { reviewMode, setReviewMode, MultiStepForm, stepNumber, previousStep } =
    useBoardingStore();
  const { prevStep } = useStepper();

  if (stepNumber === -1) return null;

  const isReviewStep = stepNumber === MultiStepForm.length - 2;
  const isLastStep = stepNumber === MultiStepForm.length - 3;

  const handlePreviousStep = () => {
    if (reviewMode) {
      setReviewMode(false);
    }
    previousStep();
    prevStep();
  };

  const buttonProps = {
    className: "w-full font-semibold",
    variant: "default" as const,
    type: "submit" as const,
  };

  const buttonText = (() => {
    if (isReviewStep && reviewMode) return "Geri";
    if (isLastStep) return "İncele";
    return "Sonraki Adım";
  })();

  return (
    <CardFooter className="flex w-full items-center justify-center gap-2">
      {stepNumber !== 0 && (
        <Button
          className="w-full font-semibold"
          variant="outline"
          onClick={handlePreviousStep}
        >
          Önceki Adım
        </Button>
      )}
      {isReviewStep && reviewMode ? (
        <FormButton
          classes="font-semibold w-full"
          Text="Gönder"
          isOnboardingButton
        />
      ) : (
        <Button {...buttonProps}>{buttonText}</Button>
      )}
    </CardFooter>
  );
};
