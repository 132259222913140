import { useEffect, useState } from "react";

// Define breakpoints
const breakpoints = {
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 1400px)",
};

export function useMediaQuery(query: string) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches);
    }

    const result = matchMedia(query);
    result.addEventListener("change", onChange);
    setValue(result.matches);

    return () => result.removeEventListener("change", onChange);
  }, [query]);

  return value;
}

// Predefined hooks for common breakpoints
export const useIsSmallScreen = () => useMediaQuery(breakpoints.sm);
export const useIsMediumScreen = () => useMediaQuery(breakpoints.md);
export const useIsDesktop = () => useMediaQuery(breakpoints.lg);
export const useIsLargeScreen = () => useMediaQuery(breakpoints.xl);
export const useIsExtraLargeScreen = () => useMediaQuery(breakpoints["2xl"]);
