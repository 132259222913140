import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { MealValidationSchema } from "@/helper/Schemas/water";
import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { formatUTCTimeComesFrommGo } from "@/helper/functions/date";
import { fetchData } from "@/helper/functions/fetch";
import { mealsQueryKey } from "@/helper/static/forms";
import { useAuthStore } from "@/helper/store/authStore";
import type { UserConsumedMealWithModel } from "@/helper/types/FoodAndMeals";
import { useQuery } from "@tanstack/react-query";
import {
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { useMemo, useState } from "react";
import { MealFormContent } from "../Forms/FormComponents/MealForm";
import { DeleteAlert } from "../Forms/OptionsDropdown/DeleteDialog";
import { OptionDropdown } from "../Forms/OptionsDropdown/Dropdown";
import { EditDialog } from "../Forms/OptionsDropdown/EditDialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { CustomSkeleton } from "../ui/skeleton";
import { useToast } from "../ui/use-toast";

const getColumns = (
  refetch: () => void,
): ColumnDef<UserConsumedMealWithModel>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  },
  {
    accessorKey: "meal_name",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Meal Name
        {column.getIsSorted() === "asc" ? (
          <ArrowUp className="ml-2 h-4 w-4" />
        ) : column.getIsSorted() === "desc" ? (
          <ArrowDown className="ml-2 h-4 w-4" />
        ) : (
          <ArrowUpDown className="ml-2 h-4 w-4" />
        )}
      </Button>
    ),
    cell: ({ row }) => row.getValue("meal_name"),
    enableColumnFilter: true,
    enableSorting: true,
  },
  {
    accessorKey: "meal_time",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Meal Time To Eat
        {column.getIsSorted() === "asc" ? (
          <ArrowUp className="ml-2 h-4 w-4" />
        ) : column.getIsSorted() === "desc" ? (
          <ArrowDown className="ml-2 h-4 w-4" />
        ) : (
          <ArrowUpDown className="ml-2 h-4 w-4" />
        )}
      </Button>
    ),
    cell: ({ row }) => formatUTCTimeComesFrommGo(row.getValue("meal_time")),
    enableColumnFilter: true,
    enableSorting: true,
  },
  {
    accessorKey: "created_at",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Added Date
        {column.getIsSorted() === "asc" ? (
          <ArrowUp className="ml-2 h-4 w-4" />
        ) : column.getIsSorted() === "desc" ? (
          <ArrowDown className="ml-2 h-4 w-4" />
        ) : (
          <ArrowUpDown className="ml-2 h-4 w-4" />
        )}
      </Button>
    ),
    cell: ({ row }) =>
      format(new Date(row.getValue("created_at")), "dd/MM/yyyy"),
    enableColumnFilter: true,
    enableSorting: true,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const meal = row.original;
      const editEndpoint = APIUrlBuilder(`/meals/${meal.meal_id}`);

      const Fieldnames = ["meal_name", "meal_time"];

      return (
        <>
          <OptionDropdown />
          <EditDialog
            DefaultValue={{
              meal_name: meal.meal_name,
              meal_time: new Date(meal.meal_time) || new Date(), // Ensure this is a Date
            }}
            FieldName={Fieldnames} // Use an array for field names
            FieldLabel="Meal"
            Endpoint={editEndpoint}
            OnSuccess={refetch}
            Placeholder="Meal name"
            ValidationSchema={MealValidationSchema}
            Description="Edit the meal here. Click Save changes when you're done."
            Title="Edit Meal"
            BodyKey={Fieldnames} // Use an array for body keys
          >
            <MealFormContent
              FieldNameForName="meal_name"
              FieldNameForTime="meal_time"
            />
          </EditDialog>
          <DeleteAlert
            Endpoint={editEndpoint}
            FieldLabel="Meal name"
            OnSuccess={refetch}
          />
        </>
      );
    },
  },
];

export function MealDataTable() {
  const { UUID } = useAuthStore();
  const endpoint = APIUrlBuilder(`/meals/user/${UUID}`);
  const mealsKey = [...mealsQueryKey, UUID];
  const { toast } = useToast();
  const {
    data: MealList,
    isError,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryFn: () => fetchData(endpoint),
    queryKey: mealsKey,
    enabled: !!UUID,
    select: (data) => {
      const mealsList = (data?.meals as UserConsumedMealWithModel[]) || [];
      return mealsList.filter((meal) => !meal.deleted_at);
    },
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const data = useMemo(() => {
    return Array.isArray(MealList) ? MealList : [];
  }, [MealList]);

  const columns = getColumns(refetch);
  const table = useReactTable({
    data: data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  // TODO after deleting and adding again and deleting again causes error in react table

  const handleDeleteSelected = async () => {
    const selectedRowIds = Object.keys(rowSelection);
    const mealIds = selectedRowIds.map(
      (rowId) => table.getRow(rowId).original.meal_id,
    );

    try {
      const deleteEndpoint = APIUrlBuilder("/meals/bulk-delete");
      const data = await fetchData(
        deleteEndpoint,
        "DELETE",
        {
          meal_ids: mealIds,
        },
        false,
      );
      if (data.ok) {
        toast({
          title: "Success!",
          description: `Successfully deleted ${mealIds.length} meal${mealIds.length > 1 ? "s" : ""}.`,
        });
        refetch();
      } else {
        console.error(
          `Failed to delete meal${mealIds.length > 1 ? "s" : ""}`,
          data,
        );
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There was a problem with your request. Try again later.",
        });
      }
    } catch (error) {
      console.error("Failed to delete meals", error);
    }
  };

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Meals</CardTitle>
        <CardDescription>Manage your meals here.</CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="space-y-2">
            {[1, 2, 3].map((i) => (
              <CustomSkeleton key={i} className="h-4 w-full" />
            ))}
          </div>
        ) : (
          <>
            <div className="mb-4 flex items-center justify-between max-md:pt-4">
              <div className="flex flex-col max-md:gap-2 md:flex-row md:space-x-2">
                <Input
                  placeholder="Filter meal name..."
                  value={
                    (table
                      .getColumn("meal_name")
                      ?.getFilterValue() as string) || ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("meal_name")
                      ?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />
                <Input
                  placeholder="Filter time..."
                  value={
                    (table
                      .getColumn("meal_time")
                      ?.getFilterValue() as string) || ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("meal_time")
                      ?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />
                <Input
                  placeholder="Filter added date..."
                  value={
                    (table
                      .getColumn("created_at")
                      ?.getFilterValue() as string) || ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("created_at")
                      ?.setFilterValue(event.target.value)
                  }
                  className="max-w-sm"
                />
              </div>
              <Button
                variant="destructive"
                onClick={handleDeleteSelected}
                disabled={table.getFilteredSelectedRowModel().rows.length === 0}
              >
                Delete Selected
              </Button>
            </div>
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead key={header.id} className="sm:text-center">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} className="sm:text-center">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="sm:text-center"
                    >
                      No meals found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </CardContent>
    </Card>
  );
}
