import clsx from "clsx";
import { Form } from "formik";
import type { FC, ReactNode } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../ui/card";
import FormButton from "./Buttons/FormButton";

// 1. İki ayrı arayüz tanımlayın
interface BaseProps {
	children: ReactNode;
	withCard?: false;
	cardTitle?: string;
	cardDescription?: string;
	cardClassName?: string;
}

interface WithCardProps {
	children: ReactNode;
	withCard: true;
	cardTitle: string;
	cardDescription: string;
	cardClassName?: string;
}

// 2. Bu arayüzleri birleştirerek Props tipini oluşturun
type Props = BaseProps | WithCardProps;

const FormWrapper: FC<Props> = ({
	children,
	withCard,
	cardTitle,
	cardDescription,
	cardClassName,
}) => {
	return (
		<Form>
			{withCard ? (
				<Card>
					<CardHeader>
						<CardTitle>{cardTitle || "Add new food"}</CardTitle>
						<CardDescription>
							{cardDescription ||
								"Add a new food to your meal here. Click Add when you're done."}
						</CardDescription>
					</CardHeader>
					<CardContent className={clsx(cardClassName || "space-y-2")}>
						{children}
					</CardContent>
					<CardFooter>
						<FormButton Text="Add" />
					</CardFooter>
				</Card>
			) : (
				children
			)}
		</Form>
	);
};

export default FormWrapper;
