import { useAuthStore } from "@/helper/store/authStore"; // Assuming you're using Zustand
import { signInWithPopup } from "firebase/auth";
import type { NavigateFunction } from "react-router-dom";
import { APIUrlBuilder } from "../../ApiUrlbuilder";
import { fetchData } from "../../fetch";
import { auth, googleProvider } from "../firebase";

export const googleSignIn = async (navigate: NavigateFunction) => {
	try {
		const result = await signInWithPopup(auth, googleProvider);
		const user = result.user;
		const token = await user.getIdToken(true); // Force token refresh

		// Send token to backend for validation and setting
		const endpoint = APIUrlBuilder("/auth/token");
		const response = await fetchData(endpoint, "POST", { token });

		const { userID, exists } = response;

		const { setIsRegistered, setUser, setRole, setSubscription, setUUID } =
			useAuthStore.getState();

		setUser(user);
		setUUID(userID);
		setIsRegistered(exists);

		// Set role and subscription from custom claims
		const idTokenResult = await user.getIdTokenResult(true);
		setRole(idTokenResult.claims.role as string);
		setSubscription(idTokenResult.claims.subscription as string);

		if (exists) {
			navigate("/"); // Redirect to the dashboard or home
		} else {
			navigate("/onboarding");
		}

		return;
	} catch (error) {
		console.error("Error signing in with Google:", error);
		throw error;
	}
};
