import { Box, type Theme, useMediaQuery } from "@mui/material";

import { HomeIcon, SettingsIcon } from "lucide-react";

import { IconButton } from "@mui/material";
import {
  AppBar,
  Layout,
  LoadingIndicator,
  TitlePortal,
  ToggleThemeButton,
} from "react-admin";
import { Link } from "react-router-dom";
import Logo from "../Logo";

const HomeButton = () => {
  return (
    <Link to="/admin">
      <IconButton color="inherit">
        <HomeIcon />
      </IconButton>
    </Link>
  );
};

const SettingsButton = () => {
  return (
    <Link to="/settings">
      <IconButton color="inherit">
        <SettingsIcon />
      </IconButton>
    </Link>
  );
};

export const AppBarToolbar = () => (
  <>
    <HomeButton />
    <SettingsButton />
    <ToggleThemeButton />
    <LoadingIndicator />
  </>
);

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm"),
  );
  return (
    <AppBar color="primary" toolbar={<AppBarToolbar />}>
      <div className="mr-2">
        <Logo />
      </div>
      <TitlePortal />
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

export const AdminDashboardShell = ({
  children,
}: {
  children: React.ReactNode;
}) => <Layout appBar={CustomAppBar}>{children}</Layout>;
