import { Label } from "@/components/ui/label";
import { cn } from "@/helper/lib/utils";
import { type FieldMetaProps, useField } from "formik";
import { Info } from "lucide-react";
import type { FC, ReactNode } from "react";
import FormTooltip from "../FormTooltip";
/* eslint-disable @typescript-eslint/no-explicit-any */

interface LabelProps {
  LabelText: string;
  For?: string;
  FieldName?: string;
  tooltip?: boolean;
  Content?: ReactNode | string;
  Label?: ReactNode | string;
  classes?: string;
}

export const InputLabelComponent: FC<LabelProps> = ({
  For,
  LabelText,
  FieldName,
  tooltip,
  Content: tooltipContent,
  Label,
  classes,
}) => {
  const [, meta] = useField(FieldName ?? "");
  if (tooltip) {
    return (
      <div className="flex items-center gap-2">
        <LabelComponent
          LabelText={LabelText}
          meta={meta}
          For={For}
          classes={classes}
        />
        <FormTooltip
          Label={Label ?? <Info className="h-4 w-4" />}
          Content={tooltipContent}
        />
      </div>
    );
  }
  return <LabelComponent LabelText={LabelText} meta={meta} For={For} />;
};

const LabelComponent = ({
  For,
  meta,
  LabelText,
  classes,
}: {
  For?: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  meta: FieldMetaProps<any>;
  LabelText: string;
  classes?: string;
}) => {
  return (
    <Label
      htmlFor={For}
      className={cn(classes, meta.error && meta.touched ? "text-red-500" : "")}
    >
      {LabelText}:
    </Label>
  );
};
