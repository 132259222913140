import { Typography } from "@mui/material";
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const UserSubscriptionShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          User Subscription Information
        </Typography>
        <TextField source="id" label="ID" />
        <ReferenceField source="user_id" reference="users" label="User">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField
          source="plan_id"
          reference="subscription_plans"
          label="Plan"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="status" />
        <TextField source="frequency" />
        <TextField source="payment_method" />
        <TextField source="payment_status" />
        <DateField source="subscription_start_date" />
        <DateField source="subscription_end_date" />
        <TextField source="free_trial_days" />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Updated At" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserSubscriptionShow;
