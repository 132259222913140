import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required,
} from "react-admin";

export const EditUserRole = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Edit User Role
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <ReferenceInput source="user_id" reference="users">
              <SelectInput
                validate={[required()]}
                label="User"
                optionText={(record) =>
                  `${record.first_name} ${record.last_name} (${record.email})`
                }
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={6}>
            <ReferenceInput source="role_id" reference="roles">
              <SelectInput
                validate={[required()]}
                label="Role"
                optionText="name"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={6}>
            <BooleanInput source="is_admin" label="Grant Admin Access" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditUserRole;
