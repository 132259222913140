import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  TextField,
  TopToolbar,
} from "react-admin";

const ExerciseProgramDetailFilters = [
  <NumberInput key="day_of_week" source="day_of_week" label="Day of Week" />,
  <NumberInput key="custom_reps" source="custom_reps" label="Custom Reps" />,
  <NumberInput key="custom_sets" source="custom_sets" label="Custom Sets" />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const ExerciseProgramDetailList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "day_of_week", order: "ASC" }}
    filters={ExerciseProgramDetailFilters}
  >
    <Datagrid>
      <ReferenceField
        source="program_id"
        reference="exercise_programs"
        label="Program"
      >
        <TextField source="program_name" />
      </ReferenceField>
      <ReferenceField
        source="exercise_id"
        reference="exercises"
        label="Exercise"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="day_of_week" />
      <NumberField source="custom_reps" />
      <NumberField source="custom_sets" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="exercise_programs_details" />
    </Datagrid>
  </List>
);

export default ExerciseProgramDetailList;
