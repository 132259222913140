import { DateTimePicker } from "@/components/ui/DatePicker";
import { DateTimeInput } from "@/components/ui/DateTimeInput";
import { useMediaQuery } from "@/helper/hooks/useMediaQuery";
import type { FC } from "react";
import { DestructedFormikField } from "./DestructedField";

interface FormDatePickerProps {
  FieldName: string;
  numberOfMonths?: number;
  MonthAndYearPicker?: boolean;
}

export const FormDatePicker: FC<FormDatePickerProps> = ({
  FieldName,
  numberOfMonths,
  MonthAndYearPicker,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <DestructedFormikField FieldName={FieldName}>
      {({ field, form }) => {
        // Directly access the field.onChange function
        const handleDateChange = (date: Date) => {
          // Set the correct field value for Formik
          form.setFieldValue(field.name, date);
        };

        return (
          <DateTimePicker
            value={field.value}
            onChange={handleDateChange as (value: Date | undefined) => void}
            numberOfMonths={(!isDesktop && 1) || numberOfMonths || 1}
            hideTime={MonthAndYearPicker}
            renderTrigger={({ open, value, setOpen }) => (
              <DateTimeInput
                value={value}
                onChange={(x) => !open && form.setFieldValue(field.name, x)}
                format="dd/MM/yyyy"
                disabled={open}
                onCalendarClick={() => setOpen(!open)}
              />
            )}
          />
        );
      }}
    </DestructedFormikField>
  );
};
