/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, Typography } from "@mui/material";
import type { FC } from "react";
import { Labeled, useRecordContext } from "react-admin";

interface TranslationsFieldProps {
  label?: string;
  source?: string;
}

export const TranslationsField: FC<TranslationsFieldProps> = ({
  source = "translations",
}) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  const translations = record[source];

  // Helper function to check if translations are nested
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const isNestedTranslation = (obj: any): boolean => {
    return Object.values(obj).some((value) => typeof value === "object");
  };

  if (isNestedTranslation(translations)) {
    // Handle nested translations grouped by language
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        {Object.entries(translations).map(([lang, fields]) => (
          <Box key={lang}>
            <Labeled label={lang.toUpperCase()}>
              <Box display="flex" gap={1} flexWrap="wrap">
                {Object.entries(fields as Record<string, string>).map(
                  ([field, text]) => (
                    <Chip
                      key={`${lang}-${field}`}
                      label={`${field}: ${text}`}
                      size="small"
                    />
                  ),
                )}
              </Box>
            </Labeled>
          </Box>
        ))}
      </Box>
    );
  }

  // Handle simple translations grouped by language
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {Object.entries(translations).map(([lang, text]) => (
        <Box key={lang}>
          <Typography variant="subtitle2" gutterBottom>
            {lang.toUpperCase()}
          </Typography>
          <Labeled label={text as string}>
            <Chip size="small" />
          </Labeled>
        </Box>
      ))}
    </Box>
  );
};
