import { graphql } from "./generated/gql";

export const getInitialData = graphql(`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      username
      email
      first_name
      last_name
      is_active
      profile_picture
      provider_profile_picture
      settings {
        id
        user_id
        language
        weight_unit
        height_unit
        water_unit
        energy_unit
        preferred_meal_country
        country_code
      }
      role {
        is_admin
      }
      ban {
        is_banned
        banned_at
        ban_reason
      }
      health {
        healthMetrics {
          tdee
          bmi
          recommended_daily_water_intake
        }
        waterIntake {
          amount
          amount_unit
        }
        dailyCalories {
          consumed_calories
          burned_calories
          net_calories
        }
      }
    }
  }
`);

export const getAllergiesAndConditions = graphql(`
  query AllergiesAndConditions {
    allergies {
      id
      name
    }
    healthConditions {
      id
      name
    }
  }
`);

export const createUser = graphql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`);
