import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";
import { TranslationsInput } from "../TranslationInput";

export const CreateFoodDetail = () => (
  <Create>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="food_id" reference="foods">
            <SelectInput
              validate={[required()]}
              label="Food"
              optionText="name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <NumberInput
            source="protein_quality_score"
            label="Protein Quality Score"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Fats Breakdown
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="trans_fats" label="Trans Fats (g)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="monounsaturated_fats"
            label="Monounsaturated Fats (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="polyunsaturated_fats"
            label="Polyunsaturated Fats (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="omega_3" label="Omega 3 (g)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="omega_6" label="Omega 6 (g)" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Fiber & Sugar Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="fiber" label="Total Fiber (g)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="soluble_fiber"
            label="Soluble Fiber (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="insoluble_fiber"
            label="Insoluble Fiber (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="sugar" label="Total Sugar (g)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="added_sugar" label="Added Sugar (g)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="sugar_alcohols"
            label="Sugar Alcohols (g)"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Carbohydrates
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12, md: 6 }}>
          <NumberInput source="net_carbs" label="Net Carbs (g)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <NumberInput source="starch" label="Starch (g)" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Minerals
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="sodium" label="Sodium (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="potassium" label="Potassium (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="calcium" label="Calcium (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="iron" label="Iron (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="magnesium" label="Magnesium (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="zinc" label="Zinc (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="selenium" label="Selenium (mcg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="copper" label="Copper (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="manganese" label="Manganese (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="phosphorus" label="Phosphorus (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="chromium" label="Chromium (mcg)" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Vitamins
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="vitamin_a" label="Vitamin A (IU)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="vitamin_c" label="Vitamin C (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="vitamin_d" label="Vitamin D (IU)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="vitamin_e" label="Vitamin E (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="vitamin_k" label="Vitamin K (mcg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="thiamin" label="Thiamin (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="riboflavin" label="Riboflavin (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="niacin" label="Niacin (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="vitamin_b6" label="Vitamin B6 (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="folate" label="Folate (mcg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="vitamin_b12"
            label="Vitamin B12 (mcg)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="biotin" label="Biotin (mcg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="pantothenic_acid"
            label="Pantothenic Acid (mg)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput source="choline" label="Choline (mg)" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Essential Amino Acids
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.histidine"
            label="Histidine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.isoleucine"
            label="Isoleucine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.leucine"
            label="Leucine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.lysine"
            label="Lysine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.methionine"
            label="Methionine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.phenylalanine"
            label="Phenylalanine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.threonine"
            label="Threonine (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.tryptophan"
            label="Tryptophan (g)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <NumberInput
            source="essential_amino_acids.valine"
            label="Valine (g)"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Other Nutritional Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="cholesterol"
            label="Cholesterol (mg)"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="caffeine" label="Caffeine (mg)" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="alcohol" label="Alcohol (g)" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Scores & Indices
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="glycemic_index"
            label="Glycemic Index"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput source="glycemic_load" label="Glycemic Load" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="popularity_score"
            label="Popularity Score"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Dietary Properties
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_raw" label="Is Raw" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_organic" label="Is Organic" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_gmo_free" label="Is GMO Free" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_vegan" label="Is Vegan" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput
            source="is_vegetarian"
            label="Is Vegetarian"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput
            source="is_dairy_free"
            label="Is Dairy Free"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput
            source="is_gluten_free"
            label="Is Gluten Free"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_kosher" label="Is Kosher" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_halal" label="Is Halal" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput source="is_paleo" label="Is Paleo" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <BooleanInput
            source="is_keto_friendly"
            label="Is Keto Friendly"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Search Terms & Tags
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <TranslationsInput source="search_terms" label="Search Terms" />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TranslationsInput source="category_tags" label="Category Tags" />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TranslationsInput source="dietary_tags" label="Dietary Tags" />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TranslationsInput source="occasion_tags" label="Occasion Tags" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default CreateFoodDetail;
