import { create } from "zustand";

interface CountryDropdownStateProps {
  countryValue: string;
  setCountryValue: (countries: string) => void;
  openCountryDropdown: boolean;
  setOpenCountryDropdown: (openCountry: boolean) => void;
  stateValue: string;
  setStateValue: (state: string) => void;
}

export const useCountryDropdownStore = create<CountryDropdownStateProps>(
  (set) => ({
    countryValue: "",
    setCountryValue: (country: string) => {
      set({ countryValue: country });
    },
    openCountryDropdown: false,
    setOpenCountryDropdown: (openCountry: boolean) => {
      set({ openCountryDropdown: openCountry });
    },
    stateValue: "",
    setStateValue: (state: string) => {
      set({ stateValue: state });
    },
  }),
);
