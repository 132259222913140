/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormBoxSwitch } from "@/components/Forms/Inputs/BoxSwitchComponent";
import { HealthSectionValidationSchema } from "@/helper/Schemas/OnboardingSchemas";
import { useStepper } from "@/helper/hooks/useStepper";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";
import { BoardingMultiSelect, BoardingRow } from "../UIElements";

export function HealthProfile() {
  const { boarding, setBoarding, nextStep, setStepValidity, stepNumber } =
    useBoardingStore();

  const initValues = {
    health_profile: {
      smoking_status: boarding.health_profile?.smoking_status ?? false,
      alcohol_consumption:
        boarding.health_profile?.alcohol_consumption ?? false,
    },
    healthConditions: boarding.healthConditions,
    allergies: boarding.allergies,
    custom_meal_wanted: boarding.custom_meal_wanted ?? false,
  };
  const { nextStep: stepperNextFunction } = useStepper();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmitHandler = (values: any) => {
    setBoarding(values);
    stepperNextFunction();
    nextStep();
    setStepValidity(stepNumber, true);
  };

  // TODO yes no kısımlarını bir farklı array ile statik hale getir
  return (
    <MultiStepComponentFormWrapper
      initValues={initValues}
      onSubmit={onSubmitHandler}
      validationSchema={HealthSectionValidationSchema}
    >
      <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
        <FormBoxSwitch
          name="health_profile.smoking_status"
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          label="Sigara içiyor musunuz?"
        />
        <FormBoxSwitch
          name="health_profile.alcohol_consumption"
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          label="Alkol kullanıyor musunuz?"
        />
      </div>
      <BoardingRow col extraClasses="gap-2">
        <FormBoxSwitch
          name="custom_meal_wanted"
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          label="Öğün planı istiyor musunuz?"
          Tooltip={{
            tooltip: true,
            Content:
              "Öğün planı istiyorsanız, öğün planınızı belirtiniz. Bu seçim size hazır oluşturulmuş öğünler sunacaktır. İstediğiniz zaman öğün planlarınızı değiştirebilir ve özelleştirebilirsiniz.",
          }}
        />
      </BoardingRow>
      <BoardingRow col extraClasses="gap-2">
        {/* TODO bunu comboboxa dönüştür veya multiselect yap */}
        <BoardingMultiSelect
          Name="allergies"
          Placeholder="Alerjileriniz"
          searchPlaceholder="Alerjilerinizi seçiniz"
        />
        <BoardingMultiSelect
          Name="healthConditions"
          Placeholder="Existing Conditions"
          searchPlaceholder="Choose your existing conditions"
        />
      </BoardingRow>
    </MultiStepComponentFormWrapper>
  );
}
