export const difficultyLevels = [
  { id: "beginner", name: "Beginner" },
  { id: "intermediate", name: "Intermediate" },
  { id: "advanced", name: "Advanced" },
];

export const targetMuscles = [
  { id: "chest", name: "Chest" },
  { id: "back", name: "Back" },
  { id: "shoulders", name: "Shoulders" },
  { id: "legs", name: "Legs" },
  { id: "arms", name: "Arms" },
  { id: "core", name: "Core" },
];

export const equipment = [
  { id: "dumbbells", name: "Dumbbells" },
  { id: "barbell", name: "Barbell" },
  { id: "kettlebell", name: "Kettlebell" },
  { id: "resistance_bands", name: "Resistance Bands" },
  { id: "bodyweight", name: "Bodyweight Only" },
];

export const targetAudience = [
  { id: "beginners", name: "Beginners" },
  { id: "intermediate", name: "Intermediate" },
  { id: "advanced", name: "Advanced" },
  { id: "seniors", name: "Seniors" },
  { id: "athletes", name: "Athletes" },
];

export const exerciseTypes = [
  { id: "bodyweight", name: "Bodyweight" },
  { id: "cardio", name: "Cardio" },
  { id: "strength", name: "Strength" },
  { id: "flexibility", name: "Flexibility" },
];
