import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
} from "react-admin";

const verificationMethods = [
  { id: "lab_tested", name: "Lab Tested" },
  { id: "third_party", name: "Third Party" },
  { id: "manufacturer", name: "Manufacturer" },
];

const verificationStatuses = [
  { id: "pending", name: "Pending" },
  { id: "verified", name: "Verified" },
  { id: "rejected", name: "Rejected" },
];

const FoodVerificationFilters = [
  <ReferenceInput key="food_id" source="food_id" reference="foods">
    <SelectInput label="Food" optionText="name" alwaysOn />
  </ReferenceInput>,
  <SelectInput
    key="verification_status"
    source="verification_status"
    choices={verificationStatuses}
    label="Verification Status"
  />,
  <SelectInput
    key="verification_method"
    source="verification_method"
    choices={verificationMethods}
    label="Verification Method"
  />,
  <ReferenceInput
    key="verified_by_id"
    source="verified_by_id"
    reference="dieticians"
  >
    <SelectInput label="Verified By" optionText="bio" />
  </ReferenceInput>,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FoodVerificationList = () => (
  <List
    filters={FoodVerificationFilters}
    actions={<ListActions />}
    sort={{ field: "verified_date", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <ReferenceField source="food_id" reference="foods">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="verification_status" />
      <TextField source="verification_method" />
      <ReferenceField source="verified_by_id" reference="users">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="verified_date" />
      <NumberField source="data_quality_score" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default FoodVerificationList;
