import { Typography } from "@mui/material";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";
import { TranslationsField } from "../TranslationField";

export const FoodTypeShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Food Type Information
        </Typography>
        <TextField source="id" label="ID" />
        <TextField source="type_name" label="Name" />
        <TextField source="type_description" label="Description" />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Updated At" />
        <Typography variant="h6" gutterBottom>
          Translations
        </Typography>
        <TranslationsField />
      </SimpleShowLayout>
    </Show>
  );
};

export default FoodTypeShow;
