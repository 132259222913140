import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DateField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const FoodBarcodeShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Barcode">
            <TextField source="barcode" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Food">
            <ReferenceField source="food_id" reference="foods">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Created At">
            <DateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Updated At">
            <DateField source="updated_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Deleted At">
            <DateField source="deleted_at" showTime />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default FoodBarcodeShow;
