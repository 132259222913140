import { ListAndEditUserFoods } from "@/components/Forms/FormComponents/FoodForm";

export default function ListFoods() {
  return (
    <section className="grid gap-6">
      <h1 className="select-none text-3xl font-semibold leading-6">
        Add new Food
      </h1>
      <ListAndEditUserFoods />
    </section>
  );
}
