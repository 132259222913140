import { Typography } from "@mui/material";
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const UserSettingsShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          User Settings Information
        </Typography>
        <TextField source="id" label="ID" />
        <ReferenceField source="user_id" reference="users" label="User">
          <TextField source="username" />
        </ReferenceField>
        <TextField source="theme" />
        <TextField source="language" />
        <TextField source="weight_unit" />
        <TextField source="height_unit" />
        <TextField source="water_unit" />
        <TextField source="energy_unit" />
        <TextField source="preferred_meal_country" />
        <TextField source="country_code" />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Updated At" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserSettingsShow;
