import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Edit, SimpleForm, TextInput, required } from "react-admin";

export const EditBrandCategory = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Brand Category Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextInput
              source="category_name"
              validate={[required()]}
              label="Category Name"
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditBrandCategory;
