import { Bug, CircleHelp, Ellipsis, Settings } from "lucide-react";

import { CollapsableSidebarButton } from "@/components/Sidebar/CollapsibleSidebarButton";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useNavigation from "@/helper/hooks/useNavigation";
import { cn } from "@/helper/lib/utils";
import { useSidebarStore } from "@/helper/store/sidebarStore";
import type { ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";
import ThemeToggle from "../ui/mode-toggle";
import { ScrollArea } from "../ui/scroll-area";

interface SidebarContentProps {
  isScrollable?: boolean;
}

export function SidebarContent({ isScrollable = true }: SidebarContentProps) {
  if (isScrollable) {
    return (
      <ScrollableContent>
        <Content />
      </ScrollableContent>
    );
  }
  return <Content />;
}

const ScrollableContent = ({ children }: { children: ReactNode }) => {
  return (
    <ScrollArea className="[&>div>div[style]]:!block">{children}</ScrollArea>
  );
};

const Content = () => {
  const menuList = useNavigation();
  const { collapsed: isOpen } = useSidebarStore();
  return (
    <nav className="mt-8 h-full w-full">
      <ul className="flex min-h-[calc(100dvh-48px-36px-16px-32px)] flex-col items-start space-y-1 px-2 lg:min-h-[calc(100dvh-32px-40px-32px)]">
        {menuList.map(({ groupLabel, subPaths }, index) => (
          <li
            className={cn("w-full", groupLabel ? "pt-5" : "")}
            key={index + groupLabel}
          >
            {(isOpen && groupLabel) || isOpen === undefined ? (
              <p className="max-w-[248px] truncate px-4 pb-2 text-sm font-medium text-muted-foreground">
                {groupLabel}
              </p>
            ) : !isOpen && isOpen !== undefined && groupLabel ? (
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger className="w-full">
                    <div className="flex w-full items-center justify-center">
                      <Ellipsis className="h-5 w-5" />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <p>{groupLabel}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <p className="pb-2" />
            )}
            {subPaths.map(
              ({ href, label, icon: Icon, current, submenus }, index) =>
                submenus.length === 0 ? (
                  <div className="w-full" key={index + label}>
                    <TooltipProvider disableHoverableContent>
                      <Tooltip delayDuration={100}>
                        <TooltipTrigger asChild>
                          <Button
                            variant={current ? "default" : "ghost"}
                            className="mb-1 h-10 w-full justify-start"
                            asChild
                          >
                            <Link to={href}>
                              <span
                                className={cn(isOpen === false ? "" : "mr-4")}
                              >
                                <Icon size={18} />
                              </span>
                              <p
                                className={cn(
                                  "max-w-[200px] truncate",
                                  isOpen === false
                                    ? "-translate-x-96 opacity-0"
                                    : "translate-x-0 opacity-100",
                                )}
                              >
                                {label}
                              </p>
                            </Link>
                          </Button>
                        </TooltipTrigger>
                        {isOpen === false && (
                          <TooltipContent side="right">{label}</TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                ) : (
                  <div className="w-full" key={index + label}>
                    <CollapsableSidebarButton
                      icon={Icon}
                      label={label}
                      active={current}
                      submenus={submenus}
                      isOpen={isOpen}
                    />
                  </div>
                ),
            )}
          </li>
        ))}
        <li className="flex w-full grow items-end">
          <div
            className={cn(
              "flex w-full items-center",
              isOpen ? "justify-between" : "justify-center",
            )}
          >
            {import.meta.env.MODE === "development" && isOpen && (
              <ThemeToggle />
            )}
            {/* TODO add a help page link */}
            {isOpen && (
              <Button variant="ghost" size="icon">
                <a href="/help">
                  <CircleHelp size={18} />
                </a>
              </Button>
            )}
            {isOpen && (
              <Button
                type="button"
                title="Report bug"
                variant="ghost"
                onClick={() => console.log("Reported a bug")}
                size="icon"
              >
                <Bug size={18} />
              </Button>
            )}
            <TooltipProvider disableHoverableContent>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() => {}}
                    variant="ghost"
                    className=""
                    asChild
                    size="icon"
                  >
                    <NavLink to="/settings">
                      <Settings size={18} />
                    </NavLink>
                  </Button>
                </TooltipTrigger>
                {isOpen === false && (
                  <TooltipContent side="right">Settings</TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          </div>
        </li>
      </ul>
    </nav>
  );
};
