import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const FoodHealthScoreShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Food">
            <ReferenceField source="food_id" reference="foods">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Dietician">
            <ReferenceField source="dietician_id" reference="dieticians">
              <TextField source="bio" />
            </ReferenceField>
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Scoring Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Overall Score">
            <NumberField source="overall_score" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Nutritional Balance">
            <NumberField source="scoring_criteria.nutritional_balance" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Ingredient Quality">
            <NumberField source="scoring_criteria.ingredient_quality" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Processing Level">
            <NumberField source="scoring_criteria.processing_level" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Additive Safety">
            <NumberField source="scoring_criteria.additive_safety" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Environmental Impact">
            <NumberField source="scoring_criteria.environmental_impact" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Review Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Notes">
            <TextField source="notes" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Review Date">
            <DateField source="review_date" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Timestamps
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Created At">
            <DateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Updated At">
            <DateField source="updated_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Deleted At">
            <DateField source="deleted_at" showTime />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default FoodHealthScoreShow;
