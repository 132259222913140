import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTheme } from "@/helper/store/themeStore";
import type { FC } from "react";
import { DestructedFormikField } from "./DestructedField";

interface Props {
  fieldName?: string;
  buttonClasses?: string;
  size?: "sm" | "lg" | "icon" | "default" | null | undefined;
  align?: "start" | "end" | "center" | undefined;
}

export const ThemeDropdownComponent: FC<Props> = ({ fieldName }) => {
  const { setTheme } = useTheme();

  const name = "theme";
  return (
    <DestructedFormikField FieldName={fieldName ?? name}>
      {({ field, form }) => (
        <Select
          value={field.value}
          onValueChange={(value) => {
            setTheme(value as "light" | "dark" | "system");
            return form.setFieldValue(field.name, value);
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="light">Light</SelectItem>
            <SelectItem value="dark">Dark</SelectItem>
            <SelectItem value="system">System</SelectItem>
          </SelectContent>
        </Select>
      )}
    </DestructedFormikField>
  );
};
