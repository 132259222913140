import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanField,
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { TranslationsField } from "../TranslationField";

export const FoodDetailShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Food">
            <ReferenceField source="food_id" reference="foods">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Protein Quality Score">
            <NumberField source="protein_quality_score" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Fats Breakdown
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Trans Fats (g)">
            <NumberField source="trans_fats" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Monounsaturated Fats (g)">
            <NumberField source="monounsaturated_fats" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Polyunsaturated Fats (g)">
            <NumberField source="polyunsaturated_fats" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Omega 3 (g)">
            <NumberField source="omega_3" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Omega 6 (g)">
            <NumberField source="omega_6" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Fiber & Sugar Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Total Fiber (g)">
            <NumberField source="fiber" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Soluble Fiber (g)">
            <NumberField source="soluble_fiber" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Insoluble Fiber (g)">
            <NumberField source="insoluble_fiber" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Total Sugar (g)">
            <NumberField source="sugar" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Added Sugar (g)">
            <NumberField source="added_sugar" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Sugar Alcohols (g)">
            <NumberField source="sugar_alcohols" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Carbohydrates
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Net Carbs (g)">
            <NumberField source="net_carbs" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Starch (g)">
            <NumberField source="starch" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Minerals
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Sodium (mg)">
            <NumberField source="sodium" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Potassium (mg)">
            <NumberField source="potassium" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Calcium (mg)">
            <NumberField source="calcium" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Iron (mg)">
            <NumberField source="iron" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Magnesium (mg)">
            <NumberField source="magnesium" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Zinc (mg)">
            <NumberField source="zinc" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Selenium (mcg)">
            <NumberField source="selenium" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Copper (mg)">
            <NumberField source="copper" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Manganese (mg)">
            <NumberField source="manganese" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Phosphorus (mg)">
            <NumberField source="phosphorus" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Chromium (mcg)">
            <NumberField source="chromium" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Vitamins
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin A (IU)">
            <NumberField source="vitamin_a" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin C (mg)">
            <NumberField source="vitamin_c" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin D (IU)">
            <NumberField source="vitamin_d" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin E (mg)">
            <NumberField source="vitamin_e" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin K (mcg)">
            <NumberField source="vitamin_k" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Thiamin (mg)">
            <NumberField source="thiamin" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Riboflavin (mg)">
            <NumberField source="riboflavin" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Niacin (mg)">
            <NumberField source="niacin" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin B6 (mg)">
            <NumberField source="vitamin_b6" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Folate (mcg)">
            <NumberField source="folate" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Vitamin B12 (mcg)">
            <NumberField source="vitamin_b12" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Biotin (mcg)">
            <NumberField source="biotin" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Pantothenic Acid (mg)">
            <NumberField source="pantothenic_acid" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Choline (mg)">
            <NumberField source="choline" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Essential Amino Acids
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Histidine (g)">
            <NumberField source="essential_amino_acids.histidine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Isoleucine (g)">
            <NumberField source="essential_amino_acids.isoleucine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Leucine (g)">
            <NumberField source="essential_amino_acids.leucine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Lysine (g)">
            <NumberField source="essential_amino_acids.lysine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Methionine (g)">
            <NumberField source="essential_amino_acids.methionine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Phenylalanine (g)">
            <NumberField source="essential_amino_acids.phenylalanine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Threonine (g)">
            <NumberField source="essential_amino_acids.threonine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Tryptophan (g)">
            <NumberField source="essential_amino_acids.tryptophan" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Valine (g)">
            <NumberField source="essential_amino_acids.valine" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Other Nutritional Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Cholesterol (mg)">
            <NumberField source="cholesterol" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Caffeine (mg)">
            <NumberField source="caffeine" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Alcohol (g)">
            <NumberField source="alcohol" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Scores & Indices
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Glycemic Index">
            <NumberField source="glycemic_index" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Glycemic Load">
            <NumberField source="glycemic_load" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Popularity Score">
            <NumberField source="popularity_score" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Dietary Properties
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Raw">
            <BooleanField source="is_raw" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Organic">
            <BooleanField source="is_organic" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is GMO Free">
            <BooleanField source="is_gmo_free" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Vegan">
            <BooleanField source="is_vegan" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Vegetarian">
            <BooleanField source="is_vegetarian" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Dairy Free">
            <BooleanField source="is_dairy_free" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Gluten Free">
            <BooleanField source="is_gluten_free" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Kosher">
            <BooleanField source="is_kosher" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Halal">
            <BooleanField source="is_halal" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Paleo">
            <BooleanField source="is_paleo" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }}>
          <Labeled label="Is Keto Friendly">
            <BooleanField source="is_keto_friendly" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Search Terms & Tags
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Search Terms">
            <TranslationsField label="Search Terms" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Category Tags">
            <TranslationsField label="Category Tags" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Dietary Tags">
            <TranslationsField label="Dietary Tags" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Occasion Tags">
            <TranslationsField label="Occasion Tags" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Timestamps
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Created At">
            <DateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Updated At">
            <DateField source="updated_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Deleted At">
            <DateField source="deleted_at" showTime />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default FoodDetailShow;
