import * as Yup from "yup";

export const WaterValidationSchema = Yup.object().shape({
  water_intake: Yup.number().positive().required("Water intake is required"),
});

export const MealValidationSchema = Yup.object().shape({
  meal_name: Yup.string().required("Meal name is required"),
  meal_time: Yup.date().required("Meal time is required"),
});
