import SettingsFormLayoutWrapper from "../../Forms/Layout/LayoutWrapper";
import { SettingsThemeSwitcher } from "../../ThemeSwitcher";

export default function SettingsDisplaySection() {
  return (
    <SettingsFormLayoutWrapper
      Title="Theme"
      Description="Select the theme for the dashboard."
    >
      <div className="w-full md:col-span-2">
        <SettingsThemeSwitcher />
      </div>
    </SettingsFormLayoutWrapper>
  );
}
