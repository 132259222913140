import type { RouteObject } from "react-router-dom";
import { SettingsAccountPage } from "./components/Settings/Forms/account";
import SettingsPersonalInfoSection from "./components/Settings/Sections/Account/PersonalInfo";
import SettingsDisplaySection from "./components/Settings/Sections/Display/Display";
import { adminLoader } from "./helper/DataLoaders/AdminLoader";
import { onboardingLoader } from "./helper/DataLoaders/OnboardingLoader";
import { rootLoader } from "./helper/DataLoaders/RootLoader";
import AdminPage from "./pages/Admin/AdminPage";
import ErrorPage from "./pages/ErrorPage";
import ExercisePage from "./pages/Exercise";
import FoodPage from "./pages/Food/AddFood";
import ListFoods from "./pages/Food/ListFoods";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import Logout from "./pages/Logout";
import MealPage from "./pages/Meal/AddMeal";
import ListMealsPage from "./pages/Meal/ListMeals";
import NotFoundPage from "./pages/Not-Found";
import RegisterPage from "./pages/Register";
import SentryTestPage from "./pages/SentryTest";
import TestPage from "./pages/TestPage";
import WaterPage from "./pages/Water/page";
import AuthLayout from "./pages/auth/AuthLayout";
import OnboardingPage from "./pages/onboarding";
import Root from "./pages/root";
import UserSettingsNotificationForm from "./pages/settings/NotificationForm";
import UserSettingsPage from "./pages/settings/settings";
// Import other components...

// Create base routes
const baseRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    loader: rootLoader,
    errorElement: <ErrorPage />,

    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "exercise",
        element: <ExercisePage />,
      },
      {
        path: "meal",
        children: [
          {
            index: true,
            element: <MealPage />,
          },
          {
            path: "list",
            element: <ListMealsPage />,
          },
        ],
      },
      {
        path: "food",
        children: [
          {
            index: true,
            element: <FoodPage />,
          },
          {
            path: "list",
            children: [
              {
                index: true,
                element: <ListFoods />,
              },
              // TODO: add all foods list. /food/list/all
            ],
          },
        ],
      },
      {
        path: "water",
        element: <WaterPage />,
      },
    ],
  },

  {
    path: "/settings",
    element: <UserSettingsPage />,
    children: [
      {
        index: true,
        element: <SettingsAccountPage />,
      },
      {
        path: "account",
        element: <SettingsPersonalInfoSection />,
      },
      {
        path: "notifications",
        element: <UserSettingsNotificationForm />,
      },
      {
        path: "billing",
        element: <div>Billing</div>,
      },
      {
        path: "security",
        element: <div>Security</div>,
      },
      {
        path: "display",
        element: <SettingsDisplaySection />,
      },
    ],
  },

  {
    path: "/admin/*",
    element: <AdminPage />,
    loader: adminLoader,
  },

  {
    path: "/onboarding",
    element: <OnboardingPage />,
    loader: onboardingLoader,
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
    ],
  },

  {
    path: "/500",
    element: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

// Add development-only routes
const devRoutes: RouteObject[] = [
  {
    path: "/sentry",
    element: <SentryTestPage />,
  },
  {
    path: "/test",
    element: <TestPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
];

// Combine routes based on environment
export const routes: RouteObject[] = import.meta.env.DEV
  ? [...baseRoutes, ...devRoutes]
  : baseRoutes;
