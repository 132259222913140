import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";
import { CountryCodeChoices, MealCountryChoices } from "./EditUserSettings";

const themeChoices = [
  { id: "light", name: "Light" },
  { id: "dark", name: "Dark" },
  { id: "system", name: "System" },
];

const languageChoices = [
  { id: "en", name: "English" },
  { id: "es", name: "Spanish" },
  { id: "fr", name: "French" },
  { id: "de", name: "German" },
  { id: "tr", name: "Turkish" },
];

const weightUnitChoices = [
  { id: "kg", name: "Kilograms" },
  { id: "lb", name: "Pounds" },
];

const heightUnitChoices = [
  { id: "cm", name: "Centimeters" },
  { id: "in", name: "Inches" },
];

const waterUnitChoices = [
  { id: "ml", name: "Milliliters" },
  { id: "oz", name: "Ounces" },
];

const energyUnitChoices = [
  { id: "kcal", name: "Kilocalories" },
  { id: "kj", name: "Kilojoules" },
];

export const CreateUserSettings = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          User Settings Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <ReferenceInput source="user_id" reference="users">
              <SelectInput
                validate={[required()]}
                label="User"
                optionText="username"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="theme"
              choices={themeChoices}
              validate={[required()]}
              label="Theme"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="language"
              choices={languageChoices}
              validate={[required()]}
              label="Language"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="weight_unit"
              choices={weightUnitChoices}
              validate={[required()]}
              label="Weight Unit"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="height_unit"
              choices={heightUnitChoices}
              validate={[required()]}
              label="Height Unit"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="water_unit"
              choices={waterUnitChoices}
              validate={[required()]}
              label="Water Unit"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="energy_unit"
              choices={energyUnitChoices}
              validate={[required()]}
              label="Energy Unit"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="preferred_meal_country"
              choices={MealCountryChoices}
              validate={[required()]}
              label="Meal Country"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="country_code"
              choices={CountryCodeChoices}
              validate={[required()]}
              label="Country Code"
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateUserSettings;
