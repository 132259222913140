import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { OnboardingSchema } from "@/helper/Schemas/OnboardingSchemas";
import { GraphQLUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { convertValuesForBackend } from "@/helper/functions/Onboarding";
import type {
  CreateUserInput,
  CreateUserMutation,
} from "@/helper/graphql/generated/graphql";
import { createUser } from "@/helper/graphql/queries";
import { capitalizeFirstLetter, cn } from "@/helper/lib/utils";
import { goals } from "@/helper/static/Onboarding/arrays";
import { useAuthStore } from "@/helper/store/authStore";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { useUnitStore } from "@/helper/store/unitStore";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { MultiStepComponentFormWrapper } from "../MultiStepFormWrapper";

// TODO review kısmını hallet
// şuanda incele butonuna basınca review kısmına geçiyor ve orada bilgileri gösteriyor ama gönder ve geri dön butonu yok.

export function ReviewSection() {
  const { setIsRegistered, setUUID } = useAuthStore();
  const { boarding } = useBoardingStore();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { mutateAsync } = useMutation<
    CreateUserMutation,
    Error,
    CreateUserInput
  >({
    mutationFn: (input: CreateUserInput) =>
      GraphQLUrlBuilder(createUser, { input }),
    onSuccess: (data: CreateUserMutation) => {
      toast({
        title: "Kayıt Başarılı!",
        description:
          "Kayıt işlemi başarılı bir şekilde tamamlandı. Ana sayfaya yönlendiriliyorsunuz.",
      });
      setUUID(data?.createUser?.id);
      setIsRegistered(true);

      // TODO later on remove this to make people believe that app is gone faster
      setTimeout(() => {
        navigate("/");
      }, 3000);
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: `${error.message}`,
        action: <ToastAction altText="Try again">Try again</ToastAction>,
      });
    },
  });

  const { selectedHeightUnit, selectedWeightUnit } = useUnitStore();
  const handleFinalSubmit = async (
    values: CreateUserInput,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      setSubmitting(true);
      if (!values || !boarding) return;

      const payload = convertValuesForBackend(boarding as CreateUserInput);

      await mutateAsync(payload as CreateUserInput);
    } catch (error) {
      console.error("Network or server error:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <MultiStepComponentFormWrapper
      initValues={boarding}
      validationSchema={OnboardingSchema}
      onSubmit={(
        values: CreateUserInput,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
      ) => handleFinalSubmit(values, { setSubmitting })}
    >
      <dl className="grid grid-cols-1 px-4 sm:grid-cols-3 md:grid-cols-4">
        {/* Review details display */}
        <ReviewSectionField
          label="First name"
          value={capitalizeFirstLetter(boarding?.first_name || "")}
        />
        <ReviewSectionField
          label="Last name"
          value={capitalizeFirstLetter(boarding?.last_name || "")}
        />
        <ReviewSectionField
          label="Birth Date"
          value={format(boarding.health_profile?.birth_date, "dd/MM/yyyy")}
        />
        <ReviewSectionField
          label="Gender"
          value={boarding.health_profile?.gender}
        />
        <ReviewSectionField
          label="Height"
          value={`${boarding.health_profile?.height} ${selectedHeightUnit}`}
        />
        <ReviewSectionField
          label="Weight"
          value={`${boarding.health_profile?.weight} ${selectedWeightUnit}`}
        />
        <ReviewSectionField
          label="Activity Level"
          value={boarding.health_profile?.activity_level}
        />
        <ReviewSectionField
          label="Allergies"
          value={
            boarding.allergies?.allergies &&
            boarding.allergies.allergies.length > 0
              ? boarding.allergies.allergies
              : "Yok"
          }
        />
        <ReviewSectionField
          label="Existing Conditions"
          value={
            boarding.healthConditions?.conditions &&
            boarding.healthConditions.conditions.length > 0
              ? boarding.healthConditions.conditions
              : "Yok"
          }
        />
        <ReviewSectionField
          label="Goal"
          value={boarding.health_profile?.health_goal}
        />
        <ReviewSectionField label="Theme" value={boarding.settings?.theme} />
        <ReviewSectionField
          label="Language"
          value={boarding.settings?.language || "Error"}
        />
        <ReviewSectionField
          label="Weight Unit(Preferred Unit)"
          value={selectedWeightUnit}
        />
        <ReviewSectionField
          label="Height Unit(Preferred Unit)"
          value={selectedHeightUnit}
        />
        <ReviewSectionField
          label="Water Unit"
          value={boarding.settings?.water_unit || "Error"}
        />
        <ReviewSectionField
          label="Energy Unit"
          value={boarding.settings?.energy_unit || "Error"}
        />
        <ReviewSectionField
          label="Smoking Status"
          value={boarding.health_profile?.smoking_status}
        />
        <ReviewSectionField
          label="Alcohol Consumption"
          value={boarding.health_profile?.alcohol_consumption}
        />
        <ReviewSectionField
          label="Meal Country"
          value={boarding.settings?.preferred_meal_country || "Error"}
          LastSoloItem
        />
        <div className="border-t border-accent px-4 sm:col-span-3 sm:px-0 md:col-span-4" />
      </dl>
    </MultiStepComponentFormWrapper>
  );
}

const ReviewSectionField = ({
  label,
  value,
  LastSoloItem,
}: {
  label: string;
  value: string | string[] | boolean | number | undefined;
  LastSoloItem?: boolean;
}) => {
  switch (typeof value) {
    case "boolean":
      if (value) {
        value = "Evet";
      } else {
        value = "Hayır";
      }
      break;
    // TODO: Add more cases for different types
    default:
      break;
  }
  switch (label) {
    case "Goal":
      value = goals.find((goal) => goal.value === value)?.label || "Yok";
      break;
  }
  return (
    <div
      className={cn(
        LastSoloItem ? "sm:col-span-3 md:col-span-2" : "sm:col-span-1",
        "border-t border-accent px-4 py-6 sm:px-0",
      )}
    >
      <dt className="text-sm font-medium leading-6 text-foreground">{label}</dt>
      <dd className="mt-1 text-sm leading-6 text-foreground/50 sm:mt-2">
        {value || "Yok"}
      </dd>
    </div>
  );
};
