import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

// TODO add custom filters such as alergens, brand category, brand subcategory
const FoodFilters = [
  <TextInput key="name" source="name" label="Search by name" alwaysOn />,
  <TextInput key="id" source="id" label="ID" />,
  <ReferenceInput key="brand_id" source="brand_id" reference="brands">
    <SelectInput label="Brand" optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    key="food_type_id"
    source="food_type_id"
    reference="food_types"
  >
    <SelectInput label="Food Type" optionText="type_name" />
  </ReferenceInput>,
  <TextInput key="country" source="country" label="Country" />,
  <NumberInput key="calories" source="calories" label="Calories" />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
  <DateInput key="deleted_at" source="deleted_at" label="Soft Deleted At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FoodList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "name", order: "ASC" }}
    filters={FoodFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="UUID" />
      <TextField source="name" />
      <ReferenceField source="brand_id" reference="brands" label="Brand">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="food_type_id"
        reference="food_types"
        label="Food Type"
      >
        <TextField source="type_name" />
      </ReferenceField>
      <TextField source="country" label="Country" />
      <DateField source="created_at" label="Created At" />
      <DateField source="updated_at" label="Updated At" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="foods" />
    </Datagrid>
  </List>
);

export default FoodList;
