import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";

const frequencyChoices = [
  { id: "monthly", name: "Monthly" },
  { id: "yearly", name: "Yearly" },
  { id: "quarterly", name: "Quarterly" },
];

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "pending", name: "Pending" },
  { id: "cancelled", name: "Cancelled" },
];

const paymentStatusChoices = [
  { id: "paid", name: "Paid" },
  { id: "pending", name: "Pending" },
  { id: "failed", name: "Failed" },
  { id: "refunded", name: "Refunded" },
];

const paymentMethodChoices = [
  { id: "credit_card", name: "Credit Card" },
  { id: "debit_card", name: "Debit Card" },
  { id: "paypal", name: "PayPal" },
  { id: "bank_transfer", name: "Bank Transfer" },
];

export const EditUserSubscription = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          User Subscription Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <ReferenceInput source="user_id" reference="users">
              <SelectInput
                validate={[required()]}
                label="User"
                optionText="username"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={6}>
            <ReferenceInput source="plan_id" reference="subscription_plans">
              <SelectInput
                validate={[required()]}
                label="Subscription Plan"
                optionText="name"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="frequency"
              choices={frequencyChoices}
              validate={[required()]}
              label="Frequency"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="status"
              choices={statusChoices}
              validate={[required()]}
              label="Status"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="payment_method"
              choices={paymentMethodChoices}
              validate={[required()]}
              label="Payment Method"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="payment_status"
              choices={paymentStatusChoices}
              validate={[required()]}
              label="Payment Status"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <DateInput
              source="subscription_start_date"
              validate={[required()]}
              label="Start Date"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <DateInput
              source="subscription_end_date"
              validate={[required()]}
              label="End Date"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="free_trial_days"
              label="Free Trial Days"
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditUserSubscription;
