import filteredCountries from "@/helper/static/Country/filtered_countries.json";
import { servingSizeUnits } from "@/helper/static/Onboarding/arrays";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { TranslationsInput } from "../TranslationInput";

const countries = filteredCountries.map((country) => ({
  id: country.name,
  name: country.name,
}));

const servingSizeUnitsChoices = servingSizeUnits.map((unit) => ({
  id: unit.value,
  name: unit.label,
}));

export const CreateFood = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <TextInput
              source="name"
              validate={[required()]}
              label="Food Name"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <ReferenceInput
              source="brand_id"
              reference="brands"
              label="Brand ID"
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <ReferenceInput
              source="food_type_id"
              reference="food_types"
              label="Food Type ID"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Nutritional Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <NumberInput
              source="calories"
              validate={[required()]}
              label="Calories"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="fats"
              validate={[required()]}
              label="Total Fats (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="saturated_fats"
              validate={[required()]}
              label="Saturated Fats (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="fiber"
              validate={[required()]}
              label="Fiber (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="sugar"
              validate={[required()]}
              label="Sugar (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="sodium"
              validate={[required()]}
              label="Sodium (mg)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="proteins"
              validate={[required()]}
              label="Proteins (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="carbs"
              validate={[required()]}
              label="Carbohydrates (g)"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Additional Details
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <TextInput
              source="serving_size"
              validate={[required()]}
              label="Serving Size"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="serving_size_unit"
              validate={[required()]}
              label="Serving Size Unit"
              choices={servingSizeUnitsChoices}
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <SelectInput
              source="country"
              validate={[required()]}
              choices={countries}
              label="Country of Origin"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={12}>
            <TranslationsInput source="translations" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateFood;
