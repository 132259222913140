import { EyeIcon, EyeOffIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input, type InputProps } from "@/components/ui/input";
import { cn } from "@/helper/lib/utils";
import { forwardRef, useState } from "react";
import { InputComponent } from "../Forms/Inputs/InputComponent";

interface Props extends InputProps {
  FormComponent?: boolean;
  FieldName?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, Props>(
  ({ className, FormComponent, FieldName, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const disabled =
      props.value === "" || props.value === undefined || props.disabled;

    return (
      <div className="relative">
        {FormComponent && FieldName ? (
          <InputComponent
            Name={FieldName}
            Placeholder={props.placeholder as string}
            InputType={showPassword ? "text" : "password"}
            FieldId={props.id as string}
            classes={cn("hide-password-toggle pr-10", className)}
            required={props.required}
            ref={ref}
            {...props}
          />
        ) : (
          <Input
            type={showPassword ? "text" : "password"}
            className={cn("hide-password-toggle pr-10", className)}
            ref={ref}
            {...props}
          />
        )}
        <Button
          type="button"
          variant="ghost"
          size="sm"
          className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
          onClick={() => setShowPassword((prev) => !prev)}
          disabled={disabled}
        >
          {showPassword && !disabled ? (
            <EyeIcon className="h-4 w-4" aria-hidden="true" />
          ) : (
            <EyeOffIcon className="h-4 w-4" aria-hidden="true" />
          )}
          <span className="sr-only">
            {showPassword ? "Hide password" : "Show password"}
          </span>
        </Button>

        {/* hides browsers password toggles */}
        <style>{`
					.hide-password-toggle::-ms-reveal,
					.hide-password-toggle::-ms-clear {
						visibility: hidden;
						pointer-events: none;
						display: none;
					}
		`}</style>
      </div>
    );
  },
);
PasswordInput.displayName = "PasswordInput";

export { PasswordInput };
