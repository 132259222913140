import { WaterDataTable } from "@/components/DataTables/WaterDataTable";
import WaterForm from "../../components/Forms/FormComponents/WaterForm";

export default function WaterPage() {
  return (
    <section className="grid gap-y-3">
      <WaterForm />
      <WaterDataTable />
    </section>
  );
}
