import FormWrapper from "@/components/Forms/FormCardWrapper";
import { InputComponent } from "@/components/Forms/Inputs/InputComponent";
import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { Label } from "@/components/ui/label";
import { TimePickerInput } from "@/components/ui/time-picker-input";
import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { MealValidationSchema } from "@/helper/Schemas/water";
import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { fetchData } from "@/helper/functions/fetch";
import { mealsQueryKey } from "@/helper/static/forms";
import { useAuthStore } from "@/helper/store/authStore";
import {
  type InvalidateQueryFilters,
  useQueryClient,
} from "@tanstack/react-query";
import { Formik, useFormikContext } from "formik";
import { type FC, useRef } from "react";
import { DebugForm } from "../DebugForm";

function MealForm() {
  const { UUID } = useAuthStore();
  const { toast } = useToast();

  const queryClient = useQueryClient();
  const mealsKey = [...mealsQueryKey, UUID];
  const BodyKeys = ["meal_name", "meal_time"];

  const refetch = () =>
    queryClient.invalidateQueries(mealsKey as InvalidateQueryFilters);
  return (
    <Formik
      initialValues={{ meal_name: "", meal_time: new Date(Date.now()) }}
      onSubmit={async (
        { meal_name, meal_time },
        { setSubmitting, resetForm },
      ) => {
        setSubmitting(true);
        const endpoint = APIUrlBuilder("/meals");
        const payload = {
          meal_name: meal_name,
          user_id: UUID,
          meal_time: meal_time,
        };
        try {
          const data = await fetchData(endpoint, "POST", payload);
          if (data.success) {
            toast({
              title: "Adding new meal successful!",
              description: "You have successfully added a new meal.",
            });
            resetForm();
            refetch();
          } else {
            console.error("Form submission error:", data);
            toast({
              variant: "destructive",
              title: "Uh oh! Something went wrong.",
              description: `${data.message} Error:${data.data}`,
              action: <ToastAction altText="Try again">Try again</ToastAction>,
            });
          }
        } catch (error) {
          console.error("Network or server error:", error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: `There was a problem with your request. Error:${error}`,
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        }
        setSubmitting(false);
      }}
      validationSchema={MealValidationSchema}
    >
      {() => (
        <FormWrapper
          withCard
          cardTitle="Add new meal"
          cardDescription="Add a new meal here. Click Add when you're done."
        >
          <MealFormContent
            FieldNameForName={BodyKeys[0]}
            FieldNameForTime={BodyKeys[1]}
          />
        </FormWrapper>
      )}
    </Formik>
  );
}

export default MealForm;

interface MealFormContentProps {
  FieldNameForName: string;
  FieldNameForTime: string;
}

export const MealFormContent: FC<MealFormContentProps> = ({
  FieldNameForName,
  FieldNameForTime,
}) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<{
    [key: string]: string | Date;
  }>();

  const minuteRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLInputElement>(null);

  return (
    <div className="grid gap-4">
      <div className="grid gap-1">
        <InputLabelComponent
          For={FieldNameForName}
          LabelText="Meal name"
          FieldName={FieldNameForName}
        />
        <InputComponent
          FieldId={FieldNameForName}
          Placeholder="Meal name"
          Name={FieldNameForName}
        />
        <InputError Name={FieldNameForName} />
      </div>
      <div className="grid gap-2">
        <InputLabelComponent
          For={FieldNameForTime}
          LabelText="Meal Time"
          FieldName={FieldNameForTime}
        />
        <div className="flex items-center justify-start gap-1">
          <div className="grid gap-1 text-center">
            <Label className="text-xs">Hours</Label>
            <TimePickerInput
              picker="hours"
              date={values[FieldNameForTime] as Date}
              setDate={(value) => setFieldValue(FieldNameForTime, value)}
              ref={hourRef}
              onRightFocus={() => minuteRef.current?.focus()}
            />
          </div>
          <span className="pt-4 text-xl font-semibold text-background">:</span>
          <div className="grid gap-1 text-center">
            <Label className="text-xs">Minutes</Label>
            <TimePickerInput
              picker="minutes"
              date={values[FieldNameForTime] as Date}
              setDate={(value) => setFieldValue(FieldNameForTime, value)}
              ref={minuteRef}
              onLeftFocus={() => hourRef.current?.focus()}
            />
          </div>
        </div>
      </div>
      <DebugForm values={values} errors={errors} touched={touched} />
    </div>
  );
};
