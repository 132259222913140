import { Button } from "@/components/ui/button";
import type React from "react";

interface Props {
  Label: string;
  Svg: React.ReactElement;
  onClick: () => void;
}

const SocialButton: React.FC<Props> = ({ Label, Svg, onClick }) => {
  return (
    <>
      <Button className="flex gap-2" variant="outline" onClick={onClick}>
        {Svg}
        <span className="text-sm font-semibold leading-6">{Label}</span>
      </Button>
    </>
  );
};
export default SocialButton;
