import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
} from "react-admin";

const FoodHealthScoreFilters = [
  <ReferenceInput key="food_id" source="food_id" reference="foods">
    <SelectInput label="Food" optionText="name" alwaysOn />
  </ReferenceInput>,
  <ReferenceInput
    key="dietician_id"
    source="dietician_id"
    reference="dieticians"
  >
    <SelectInput label="Dietician" optionText="bio" />
  </ReferenceInput>,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FoodHealthScoreList = () => (
  <List
    filters={FoodHealthScoreFilters}
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <ReferenceField source="food_id" reference="foods">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="dietician_id" reference="dieticians">
        <TextField source="bio" />
      </ReferenceField>
      <NumberField source="overall_score" />
      <TextField source="notes" />
      <DateField source="review_date" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default FoodHealthScoreList;
