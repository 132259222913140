import { useAuthStore } from "@/helper/store/authStore";
import { auth } from "./firebase";
import { useUserStore } from "@/helper/store/userStore";

export const signOut = async () => {
  try {
    // Sign out from Firebase
    await auth.signOut();

    // Reset auth and user stores
    useAuthStore.getState().resetAuth();
    useUserStore.getState().resetUser();
  } catch (error) {
    console.error("Error signing out:", error);
  }
};
