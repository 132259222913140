import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const ExerciseProgramFilters = [
  <TextInput key="program_name" source="program_name" label="Name" alwaysOn />,
  <TextInput key="description" source="description" label="Description" />,
  <TextInput key="user_id" source="user_id" label="User ID" />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const ExerciseProgramList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "program_name", order: "ASC" }}
    filters={ExerciseProgramFilters}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="program_name" />
      <TextField source="description" />
      <ReferenceField source="user_id" reference="users" label="User">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="exercise_programs" />
    </Datagrid>
  </List>
);

export default ExerciseProgramList;
