/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FoodWithModel, UserConsumedMeal } from "../types/FoodAndMeals";
import type {
  HealthMetrics,
  User,
  UserHealthProfiles,
  UserRole,
  UserSettings,
  UserWaterIntake,
} from "../types/UserTypes";
import { auth } from "./auth/firebase";

interface Response {
  is_unique: boolean;
  exists: boolean;
  userID: string;
  intakes: UserWaterIntake[];
  meals: UserConsumedMeal[];
  foods: FoodWithModel[];
  user_settings: UserSettings;
  user_role: UserRole;
  health_profile: UserHealthProfiles;
  health_metrics: HealthMetrics;
  user: User;
  map(
    arg0: (item: { FoodTypeID: string; TypeName: string }) => {
      value: string;
      label: string;
    },
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ): any;
  success: boolean;
  message: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: Record<string, any>;
}

export async function fetchData(
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  body?: object,
  expectJsonResponse = true,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): Promise<Response | any> {
  const options: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method !== "GET" && body) {
    options.body = JSON.stringify(body);
  }

  // Get the current user and refresh the token
  const currentUser = auth.currentUser;
  if (currentUser) {
    try {
      const token = await currentUser.getIdToken(true);
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Handle token refresh error (e.g., redirect to login)
    }
  }

  const response = await fetch(endpoint, options);
  if (!response.ok) {
    const errorText = await response.text();
    console.error(`Error response from server: ${errorText}`);
    throw new Error(
      `Failed to fetch data from ${method}-${endpoint}: ${errorText}`,
    );
  }

  if (expectJsonResponse) {
    return await response.json();
  }
  return response;
}
