/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";

// Define the structure of your exercise form values
interface ExerciseFormValues {
  name: string;
  setCount: number;
  totalSets: number;
  dayOfWeek: number;
  isCompleted: boolean;
}

// Define the structure for errors
type FormErrors = {
  [Property in keyof ExerciseFormValues]?: string;
};

// Utility to validate individual fields
const validateField = (
  field: keyof ExerciseFormValues,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  value: any,
): string | null => {
  switch (field) {
    case "name":
      if (!value.trim()) return "Name is required";
      if (value.length < 3) return "Name must be at least 3 characters long";
      return null;
    case "setCount":
    case "totalSets":
      if (value <= 0) return `${field} must be greater than zero`;
      return null;
    case "dayOfWeek":
      if (value < 1 || value > 7) return "Select a valid day";
      return null;
    default:
      return null;
  }
};

// Utility to validate the entire form
const validateForm = (formData: ExerciseFormValues): FormErrors => {
  return Object.keys(formData).reduce((acc: FormErrors, key) => {
    const error = validateField(
      key as keyof ExerciseFormValues,
      formData[key as keyof ExerciseFormValues],
    );
    if (error) acc[key as keyof ExerciseFormValues] = error;
    return acc;
  }, {});
};

// Define the structure of your Zustand store
interface FormState {
  formData: ExerciseFormValues;
  errors: FormErrors;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setField: (field: keyof ExerciseFormValues, value: any) => void;
  validateField: (field: keyof ExerciseFormValues) => void;
  submitForm: () => void;
  editModal: boolean;
  setEditModal: (value: boolean) => void;
  deleteModal: boolean;
  setDeleteModal: (value: boolean) => void;
}

export const useFormStore = create<FormState>((set, get) => ({
  formData: {
    name: "",
    setCount: 0,
    totalSets: 0,
    dayOfWeek: 1,
    isCompleted: false,
  },
  errors: {},
  setField: (field, value) =>
    set((state) => ({
      formData: { ...state.formData, [field]: value },
      errors: { ...state.errors, [field]: validateField(field, value) },
    })),
  validateField: (field) =>
    set((state) => ({
      errors: {
        ...state.errors,
        [field]: validateField(field, state.formData[field]),
      },
    })),
  submitForm: () => {
    const errors = validateForm(get().formData);
    if (Object.values(errors).some((error) => error !== null)) {
      set({ errors });
    } else {
      console.log("Form data is valid, perform submission:", get().formData);
      // Handle form submission here
    }
  },
  editModal: false,
  setEditModal: (value) => set({ editModal: value }),
  deleteModal: false,
  setDeleteModal: (value) => set({ deleteModal: value }),
}));
