import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const UserSettingsFilters = [
  <TextInput key="id" source="id" label="Settings ID" alwaysOn />,
  <TextInput key="user_id" source="user_id" label="User ID" />,
  <TextInput key="theme" source="theme" label="Theme" />,
  <TextInput key="language" source="language" label="Language" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const UserSettingsList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
    filters={UserSettingsFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <ReferenceField source="user_id" reference="users" label="User">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="theme" />
      <TextField source="language" />
      <TextField source="weight_unit" />
      <TextField source="height_unit" />
      <TextField source="water_unit" />
      <TextField source="energy_unit" />
      <TextField source="preferred_meal_country" />
      <TextField source="country_code" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default UserSettingsList;
