export const specialties = [
  { id: "strength", name: "Strength" },
  { id: "yoga", name: "Yoga" },
  { id: "hiit", name: "HIIT" },
  { id: "pilates", name: "Pilates" },
  { id: "cardio", name: "Cardio" },
];

export const certifications = [
  { id: "NASM", name: "NASM" },
  { id: "ACE", name: "ACE" },
  { id: "ISSA", name: "ISSA" },
  { id: "ACSM", name: "ACSM" },
];
