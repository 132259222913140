import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
} from "react-admin";

const FoodHealthWarningFilters = [
  <ReferenceInput key="food_id" source="food_id" reference="foods">
    <SelectInput label="Food" optionText="name" alwaysOn />
  </ReferenceInput>,
  <ReferenceInput
    key="condition_id"
    source="condition_id"
    reference="health_conditions"
  >
    <SelectInput label="Health Condition" optionText="condition_name" />
  </ReferenceInput>,
  <SelectInput
    key="warning_severity"
    source="warning_severity"
    choices={[
      { id: "low", name: "Low" },
      { id: "medium", name: "Medium" },
      { id: "high", name: "High" },
    ]}
    label="Warning Severity"
  />,
  <SelectInput
    key="warning_type"
    source="warning_type"
    choices={[
      { id: "avoid", name: "Avoid" },
      { id: "limit", name: "Limit" },
      { id: "caution", name: "Caution" },
    ]}
    label="Warning Type"
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FoodHealthWarningList = () => (
  <List
    filters={FoodHealthWarningFilters}
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <ReferenceField source="food_id" reference="foods">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="condition_id"
        reference="health_conditions"
        label="Health Condition"
      >
        <TextField source="condition_name" />
      </ReferenceField>
      <TextField source="warning_severity" />
      <TextField source="warning_type" />
      <TextField source="description" />
      <TextField source="recommendation" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default FoodHealthWarningList;
