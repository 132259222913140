import { auth } from "./firebase";

// Function to get custom claims from Firebase
export const getCustomClaims = async () => {
	// Wait for auth state to be initialized
	if (auth.currentUser === null) {
		await new Promise((resolve) => {
			const unsubscribe = auth.onAuthStateChanged((user) => {
				if (user !== null) {
					unsubscribe();
					resolve(user);
				}
			});
		});
	}

	const currentUser = auth.currentUser;
	if (!currentUser) {
		throw new Error("No user is signed in.");
	}

	try {
		const idTokenResult = await currentUser.getIdTokenResult(true);
		const { role, subscription } = idTokenResult.claims;

		if (!role) {
			throw new Error("No role claim found");
		}

		if (import.meta.env.DEV) {
			console.log(`User role: ${role}, Subscription: ${subscription}`);
		}

		return idTokenResult.claims;
	} catch (error) {
		console.error("Error fetching custom claims:", error);
		throw error;
	}
};
