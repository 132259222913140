import { cn } from "@/helper/lib/utils";
import { useSidebarStore } from "@/helper/store/sidebarStore.ts";
import Logo from "../Logo";
import { SidebarContent } from "./SidebarContent";
import { SidebarToggle } from "./sidebar-toggle";

const SidebarDesktop = () => {
  const { collapsed, setIsCollapsed } = useSidebarStore();
  return (
    <aside
      className={cn(
        "fixed left-0 top-0 z-50 h-dvh -translate-x-full bg-sidebar transition-[width] duration-300 ease-in-out lg:translate-x-0",
        !collapsed ? "w-[90px]" : "w-72",
        "text bg-green-300 dark:bg-sidebar",
      )}
    >
      <SidebarToggle isOpen={collapsed} setIsOpen={setIsCollapsed} />
      <div
        className={cn(
          "relative flex h-full flex-col px-3 py-4 shadow-md",
          "overflow-y-auto",
          // scrollable && "overflow-y-auto",
        )}
      >
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <SidebarContent />
      </div>
    </aside>
  );
};
export default SidebarDesktop;
