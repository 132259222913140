import { useAuthStore } from "@/helper/store/authStore"; // Assuming you're using Zustand
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import type { NavigateFunction } from "react-router-dom";
import { APIUrlBuilder } from "../../ApiUrlbuilder";
import { fetchData } from "../../fetch";
import { auth } from "../firebase";

const facebookProvider = new FacebookAuthProvider();

export const facebookSignIn = async (navigate: NavigateFunction) => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    const user = result.user;
    const token = await user.getIdToken(true); // Force token refresh

    // Send token to backend for validation and setting
    const endpoint = APIUrlBuilder("/auth/login");
    const response = await fetchData(endpoint, "POST", { token });

    const { userID, exists } = response;

    const { setIsRegistered, setUser, setRole, setSubscription, setUUID } =
      useAuthStore.getState();

    setUser(user);
    setUUID(userID);
    setIsRegistered(exists);

    // Set role and subscription from custom claims
    const idTokenResult = await user.getIdTokenResult(true);
    setRole(idTokenResult.claims.role as string);
    setSubscription(idTokenResult.claims.subscription as string);

    if (exists) {
      navigate("/"); // Redirect to the dashboard or home
    } else {
      navigate("/onboarding");
    }

    return;
  } catch (error) {
    console.error("Error signing in with Facebook:", error);
    throw error;
  }
};
