import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";
import { TranslationsInput } from "../TranslationInput";

const verificationMethods = [
  { id: "lab_tested", name: "Lab Tested" },
  { id: "third_party", name: "Third Party" },
  { id: "manufacturer", name: "Manufacturer" },
];

const verificationStatuses = [
  { id: "pending", name: "Pending" },
  { id: "verified", name: "Verified" },
  { id: "rejected", name: "Rejected" },
];

export const CreateFoodVerification = () => (
  <Create>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="food_id" reference="foods">
            <SelectInput
              validate={[required()]}
              label="Food"
              optionText="name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="verified_by_id" reference="dieticians">
            <SelectInput
              validate={[required()]}
              label="Verified By"
              optionText="bio"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Verification Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <SelectInput
            source="verification_status"
            choices={verificationStatuses}
            validate={[required()]}
            label="Verification Status"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <SelectInput
            source="verification_method"
            choices={verificationMethods}
            validate={[required()]}
            label="Verification Method"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <DateInput
            source="verified_date"
            validate={[required()]}
            label="Verification Date"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Quality Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="data_quality_score"
            validate={[required()]}
            min={0}
            max={1}
            step={0.01}
            label="Data Quality Score"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <BooleanInput source="app_verified" label="App Verified" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <BooleanInput
            source="community_verified"
            label="Community Verified"
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Translations
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <TranslationsInput source="translations" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default CreateFoodVerification;
