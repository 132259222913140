import { UsernameSchema } from "@/helper/Schemas/username";
import * as Yup from "yup";
import { activityLevels, goals } from "../static/Onboarding/arrays";

const activity = activityLevels.map((activityLevel) => {
	return activityLevel.value;
});

const reOrganizedGoalsArray = goals.map((goal) => {
	return goal.value;
});

export const PersonalInfoValidationSchema = (
	usernameError: string,
	setReadyToCallAPI: (value: boolean) => void,
	setUsernameError: (value: string) => void,
) =>
	Yup.object().shape({
		username: UsernameSchema(
			usernameError,
			setReadyToCallAPI,
			setUsernameError,
		),
		first_name: Yup.string()
			.min(2, "Minimum 2 karakter gereklidir")
			.required("Ad alanı zorunludur"),
		last_name: Yup.string()
			.min(2, "Minimum 2 karakter gereklidir")
			.required("Soyad alanı zorunludur"),
		health_profile: Yup.object().shape({
			birth_date: Yup.date()
				.required("Doğum tarihi zorunludur") // Doğum tarihi alanı için zorunluluk ekleniyor.
				.max(new Date(), "Gelecek tarihler geçerli değil"), // Doğum tarihinin bugün veya daha eski bir tarih olması gerektiğini belirtir.
			gender: Yup.string().required("Cinsiyet alanı zorunludur"),
			height: Yup.number()
				.required("Boy alanı zorunludur")
				// .max(380, "Boy en fazla 380 olabilir")
				.positive("Boy pozitif olmalıdır"),
			weight: Yup.number()
				.required("Kilo alanı zorunludur")
				// .max(800, "Kilo en fazla 800 olabilir")
				.positive("Kilo pozitif olmalıdır"),
		}),
	});

export const HealthSectionValidationSchema = Yup.object().shape({
	health_profile: Yup.object().shape({
		smoking_status: Yup.boolean().required("Sigara içme alanı zorunludur"),
		alcohol_consumption: Yup.string().required("Alkol içme alanı zorunludur"),
	}),
	allergies: Yup.array(), //buna gene sonra bak
	healthConditions: Yup.array(),
	custom_meal_wanted: Yup.boolean().required("Özel yemek isteği zorunludur"),
});

export const GoalsAndActivityValidationSchema = Yup.object().shape({
	health_profile: Yup.object().shape({
		activity_level: Yup.number()
			.required("Aktivite seviyesi seçimi zorunludur")
			.oneOf(activity, "Geçersiz aktivite seviyesi"),
		health_goal: Yup.string()
			.required("Hedef seçimi zorunludur")
			.oneOf(reOrganizedGoalsArray, "Geçersiz hedef"),
	}),
});

export const SettingsSectionValidationSchema = Yup.object().shape({
	settings: Yup.object().shape({
		preferred_meal_country: Yup.string().required("Ülke seçimi zorunludur"),
		theme: Yup.string().required("Tema seçimi zorunludur"),
		language: Yup.string().required("Dil seçimi zorunludur"),
		weight_unit: Yup.string().required("Kilo birimi seçimi zorunludur"),
		height_unit: Yup.string().required("Boy birimi seçimi zorunludur"),
		water_unit: Yup.string().required("Su birimi seçimi zorunludur"),
		energy_unit: Yup.string().required("Enerji birimi seçimi zorunludur"),
	}),
});

export const OnboardingSchema = Yup.object().shape({
	username: Yup.string()
		.min(5, "Username is too short - should be 4 chars minimum.")
		.max(15, "Username is too long - should be 15 chars maximum.")
		.matches(
			/^[a-zA-Z0-9_]+$/,
			"Username can only contain alphanumeric characters and underscores.",
		)
		.required("Username is required"),
	first_name: Yup.string()
		.min(2, "Minimum 2 karakter gereklidir")
		.required("Ad alanı zorunludur"),
	last_name: Yup.string()
		.min(2, "Minimum 2 karakter gereklidir")
		.required("Soyad alanı zorunludur"),
	health_profile: Yup.object().shape({
		birth_date: Yup.date()
			.required("Doğum tarihi zorunludur") // Doğum tarihi alanı için zorunluluk ekleniyor.
			.max(new Date(), "Gelecek tarihler geçerli değil"), // Doğum tarihinin bugün veya daha eski bir tarih olması gerektiğini belirtir.
		gender: Yup.string().required("Cinsiyet alanı zorunludur"),
		height: Yup.number()
			.required("Boy alanı zorunludur")
			// .max(380, "Boy en fazla 380 olabilir")
			.positive("Boy pozitif olmalıdır"),
		weight: Yup.number()
			.required("Kilo alanı zorunludur")
			// .max(800, "Kilo en fazla 800 olabilir")
			.positive("Kilo pozitif olmalıdır"),
		activity_level: Yup.number()
			.required("Aktivite seviyesi seçimi zorunludur")
			.oneOf(activity, "Geçersiz aktivite seviyesi"),
		health_goal: Yup.string()
			.required("Hedef seçimi zorunludur")
			.oneOf(reOrganizedGoalsArray, "Geçersiz hedef")
			.min(1, "Hedef seçimi zorunludur"),
		smoking_status: Yup.boolean().required("Sigara içme alanı zorunludur"),
		alcohol_consumption: Yup.boolean().required("Alkol içme alanı zorunludur"),
	}),
	settings: Yup.object().shape({
		theme: Yup.string().required("Tema seçimi zorunludur"),
		language: Yup.string().required("Dil seçimi zorunludur"),
		weight_unit: Yup.string().required("Kilo birimi seçimi zorunludur"),
		height_unit: Yup.string().required("Boy birimi seçimi zorunludur"),
		water_unit: Yup.string().required("Su birimi seçimi zorunludur"),
		energy_unit: Yup.string().required("Enerji birimi seçimi zorunludur"),
	}),
	existing_conditions: Yup.array(),
	allergies: Yup.array(),
});
