import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const FoodTypeFilters = [
  <TextInput
    key="type_name"
    source="type_name"
    label="Search by name"
    alwaysOn
  />,
  <TextInput key="food_type_id" source="food_type_id" label="Food Type ID" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FoodTypeList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "type_name", order: "ASC" }}
    filters={FoodTypeFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="food_type_id" label="ID" />
      <TextField source="type_name" label="Name" />
      <TextField source="type_description" label="Description" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default FoodTypeList;
