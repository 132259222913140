import { useSidebarStore } from "@/helper/store/sidebarStore";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SidebarDesktop from "../Sidebar/SidebarDesktop";
import SidebarMobile from "../Sidebar/SidebarMobile";

export function Sidebar() {
  const { collapsed, setIsCollapsed, setMobileSidebarOpen } = useSidebarStore();

  const [isMobile, setIsMobile] = useState(false);
  const lastDesktopCollapsedState = useRef(collapsed);
  const DESKTOP_BREAKPOINT = 1024;
  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      const mobile = window.innerWidth < DESKTOP_BREAKPOINT;

      if (mobile !== isMobile) {
        setIsMobile(mobile);

        if (mobile) {
          lastDesktopCollapsedState.current = collapsed;
          setIsCollapsed(true);
          setMobileSidebarOpen(false);
        } else {
          setIsCollapsed(lastDesktopCollapsedState.current);
        }
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [collapsed, setIsCollapsed, isMobile, setMobileSidebarOpen]);

  // Close mobile sidebar on route change
  // biome-ignore lint/correctness/useExhaustiveDependencies: <location used to supress the location change in mobile version>
  useEffect(() => {
    if (isMobile) {
      setMobileSidebarOpen(false);
    }
  }, [location, isMobile, setMobileSidebarOpen]);

  return <>{isMobile ? <SidebarMobile /> : <SidebarDesktop />}</>;
}
