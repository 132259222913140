import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CreateSubscriptionPlan = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Subscription Plan Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <TextInput
              source="name"
              validate={[required()]}
              label="Plan Name"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="price"
              validate={[required()]}
              label="Price"
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <TextInput
              source="description"
              validate={[required()]}
              label="Description"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateSubscriptionPlan;
