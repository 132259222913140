import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import type {
	ExistingCondition,
	HealthMetrics,
	User,
	UserHealthProfiles,
	UserRole,
	UserSettings,
} from "../types/UserTypes";

// isAdmin ve deletedAt property'lerini çıkararak yeni bir User type'ı tanımlayın
type user = Omit<User, "is_admin" | "provider" | "social_id">;

// Sağlık profillerinden istenmeyen alanları çıkararak yeni bir UserHealthProfiles type'ı tanımlayın
type UserHealthProfilesObject = Omit<
	UserHealthProfiles,
	"user_id" | "birth_date"
>;

export interface ExtendedUserHealthProfiles extends UserHealthProfilesObject {
	age: number;
	existing_conditions: ExistingCondition[];
}
type UserHealthMetricsObject = Omit<HealthMetrics, "user_id">;
export type UserSettingsObject = Omit<
	UserSettings,
	"id" | "user_id" | "created_at" | "updated_at" | "deleted_at"
>;
interface UserStore {
	User: user;
	setUser: (user: user) => void;
	HealthProfile: ExtendedUserHealthProfiles;
	setHealthProfile: (profile: ExtendedUserHealthProfiles) => void;
	HealthMetrics: UserHealthMetricsObject;
	setHealthMetrics: (metrics: UserHealthMetricsObject) => void;
	UserRoles: UserRole;
	setUserRoles: (roles: UserRole) => void;
	UserSettings: UserSettingsObject;
	setUserSettings: (
		settings:
			| Partial<UserSettingsObject>
			| ((prev: UserSettingsObject) => UserSettingsObject),
	) => void;
	resetUser: () => void;
}

const initialHealthProfile: ExtendedUserHealthProfiles = {
	weight: 0,
	height: 0,
	age: 0,
	gender: "",
	activity_level: 0,
	dietary_preferences: "",
	health_goal: "",
	smoking_status: false,
	alcohol_consumption: false,
	existing_conditions: [],
	custom_calorie_goal: 0,
	goal_start_date: new Date(),
	goal_daily_water_intake: 0,
	created_at: new Date(),
	updated_at: new Date(),
	deleted_at: new Date(),
	has_existing_conditions: false,
};

const initialUser: user = {
	id: "",
	username: "",
	email: "",
	first_name: "",
	last_name: "",
	is_active: true,
	profile_picture: "",
	created_at: new Date(),
	updated_at: new Date(),
	deleted_at: new Date(),
};

const initialHealthMetrics: UserHealthMetricsObject = {
	bmi: 0,
	tdee: 0,
	vitamin_c: 0,
	vitamin_d: 0,
	iron: 0,
	calcium: 0,
	magnesium: 0,
	fiber: 0,
	potassium: 0,
	protein: 0,
	goal_protein: 0,
	carbs: 0,
	fats: 0,
	recommended_daily_water_intake: 0,
	created_at: new Date(),
	updated_at: new Date(),
	deleted_at: new Date(),
};

const initialUserRoles = {
	id: "",
	name: "",
	user_id: "",
	role_id: "",
	description: "",
	is_admin: false,
	created_at: new Date(),
	updated_at: new Date(),
	deleted_at: new Date(),
} as const;

const initialUserSettings: UserSettingsObject = {
	theme: "system",
	energy_unit: "kcal",
	height_unit: "cm",
	language: "en",
	preferred_meal_country: "Turkey",
	water_unit: "ml",
	weight_unit: "g",
};

export const useUserStore = create(
	persist<UserStore>(
		(set) => ({
			User: initialUser,
			setUser: (user) =>
				set((state) => ({
					...state,
					User: user,
				})),
			HealthProfile: initialHealthProfile,
			setHealthProfile: (profile) =>
				set((state) => ({
					...state,
					HealthProfile: profile,
				})),
			HealthMetrics: initialHealthMetrics,
			setHealthMetrics: (metrics) =>
				set((state) => ({
					...state,
					HealthMetrics: metrics,
				})),
			UserRoles: initialUserRoles,
			setUserRoles: (roles) =>
				set((state) => ({
					...state,
					UserRoles: roles,
				})),
			UserSettings: initialUserSettings,
			setUserSettings: (settings) =>
				set((state) => ({
					...state,
					UserSettings:
						typeof settings === "function"
							? settings(state.UserSettings)
							: { ...state.UserSettings, ...settings },
				})),
			resetUser: () =>
				set(() => ({
					User: initialUser,
					HealthProfile: initialHealthProfile,
					HealthMetrics: initialHealthMetrics,
					UserRoles: initialUserRoles,
					UserSettings: initialUserSettings,
				})),
		}),
		{
			name: "user-storage", // Unique name
			storage: createJSONStorage(() => sessionStorage), // Stores information about whether the user has previously logged in using 'sessionStorage'
		},
	),
);
