import SwitchComponent from "@/components/Forms/Inputs/SwitchComponent";
import SettingsFormLayoutWrapper from "@/components/Settings/Forms/Layout/LayoutWrapper";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Formik } from "formik";
import * as Yup from "yup";

const NotificationSchema = Yup.object().shape({
  marketing: Yup.boolean(),
  communication: Yup.boolean(),
  security: Yup.boolean(),
});

export default function UserSettingsNotificationForm() {
  return (
    <Formik
      initialValues={{
        communication: false,
        marketing: false,
        security: true,
      }}
      onSubmit={async ({ communication, marketing, security }) => {
        console.log({ communication, marketing, security });
      }}
      validationSchema={NotificationSchema}
    >
      <SettingsFormLayoutWrapper
        Title="Email Notifications"
        Description="Select the notifications you want to receive."
      >
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>Email Notifications</CardTitle>
            <CardDescription>
              Select the notifications you want to receive.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex justify-between">
              <div>
                <Label>Communication emails</Label>
                <p className="text-[0.8rem] text-muted-foreground">
                  Receive emails about your account activity.
                </p>
              </div>
              <SwitchComponent Name="communication" />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <Label>Marketing emails</Label>
                <p className="text-[0.8rem] text-muted-foreground">
                  Receive emails about new products, features, and more.
                </p>
              </div>
              <SwitchComponent Name="marketing" />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <Label>Security emails</Label>
                <p className="text-[0.8rem] text-muted-foreground">
                  Receive emails about your account activity and security.
                </p>
              </div>
              <SwitchComponent Name="security" disabled />
            </div>
          </CardContent>
        </Card>
      </SettingsFormLayoutWrapper>
    </Formik>
  );
}
