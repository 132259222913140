import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const BrandSubcategoryFilters = [
  <TextInput
    key="subcategory_name"
    source="subcategory_name"
    label="Search by name"
    alwaysOn
  />,
  <TextInput key="subcategory_id" source="id" label="Subcategory ID" />,
  <TextInput
    key="brand_category_id"
    source="brand_category_id"
    label="Category ID"
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const BrandSubcategoryList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "subcategory_name", order: "ASC" }}
    filters={BrandSubcategoryFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="subcategory_name" label="Name" />
      <ReferenceField
        source="brand_category_id"
        reference="brand_categories"
        label="Category"
      >
        <TextField source="category_name" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default BrandSubcategoryList;
