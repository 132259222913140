import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { fetchData } from "@/helper/functions/fetch";
import { useAuthStore } from "@/helper/store/authStore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

export const emailRegister = async (email: string, password: string) => {
  try {
    // Register the user with email and password
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredential.user;

    // Get the Firebase token after user is created
    const token = await user.getIdToken(true); // Force token refresh

    // Send token to backend for validation and setting
    const endpoint = APIUrlBuilder("/auth/login");
    const response = await fetchData(endpoint, "POST", { token });

    const { userID, exists } = response;

    const { setIsRegistered, setUser, setRole, setSubscription, setUUID } =
      useAuthStore.getState();

    setUser(user);
    setUUID(userID);
    setIsRegistered(exists);

    // Set role and subscription from custom claims
    const idTokenResult = await user.getIdTokenResult(true);
    setRole(idTokenResult.claims.role as string);
    setSubscription(idTokenResult.claims.subscription as string);

    return exists;
  } catch (error) {
    console.error("Error registering with email:", error);
    throw error;
  }
};
