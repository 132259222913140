import { useEffect } from "react";
import { useTheme } from "@/helper/store/themeStore"; // Adjust the path to where your themeStore is located

export const useApplyTheme = () => {
  const theme = useTheme((state) => state.theme);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove("light", "dark", "system");

    let appliedTheme = theme;

    // Apply the system preference
    if (theme === "system") {
      appliedTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    }

    // Add the current theme class to the root element
    root.classList.add(appliedTheme);

    // No need to persist to localStorage here as Zustand's persist middleware handles it
  }, [theme]); // Only re-apply when theme changes
};
1;
