import { PasswordInput } from "@/components/Inputs/PasswordInput";
import type { InputProps } from "@/components/ui/input";
import type React from "react";
import { DestructedFormikField } from "./DestructedField";

interface Props extends InputProps {
  FieldName: string;
}

const FormPasswordInput: React.FC<Props> = ({ FieldName, ...props }) => {
  return (
    <DestructedFormikField FieldName={FieldName}>
      {({ field }) => {
        // Directly access the field.onChange function
        return (
          <PasswordInput
            id={FieldName}
            FormComponent
            FieldName={FieldName}
            value={field.value}
            required
            {...props}
          />
        );
      }}
    </DestructedFormikField>
  );
};
export default FormPasswordInput;
