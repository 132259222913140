import { MealDataTable } from "@/components/DataTables/MealDataTable";

function ListMealsPage() {
  return (
    <section className="grid gap-6">
      <h1 className="select-none text-3xl font-semibold leading-6">
        Add new Meal
      </h1>
      <MealDataTable />
    </section>
  );
}
export default ListMealsPage;
