import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { TranslationsInput } from "../TranslationInput";

export const CreateFoodType = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Food Type Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={12}>
            <TextInput
              source="type_name"
              validate={[required()]}
              label="Type Name"
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <TextInput
              source="type_description"
              validate={[required()]}
              label="Description"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid size={12}>
            <TranslationsInput
              source="translations"
              isNested
              fields={["type_name", "type_description"]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateFoodType;
