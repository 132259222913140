import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CreateBrand = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Brand Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={6}>
            <TextInput
              source="name"
              validate={[required()]}
              label="Brand Name"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <ReferenceInput
              source="brand_category_id"
              reference="brand_categories"
            >
              <SelectInput
                label="Brand Category"
                optionText="category_name"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateBrand;
