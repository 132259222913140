import { AlertCircle } from "lucide-react";
import type React from "react";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";

interface Props {
	errorMessage: string;
}

const ErrorAlert: React.FC<Props> = ({ errorMessage }) => {
	return (
		<Alert variant="destructive">
			<AlertCircle className="h-4 w-4" />
			<AlertTitle>Error</AlertTitle>
			<AlertDescription>
				<span>{errorMessage || "An error occurred while fetching data."}</span>
			</AlertDescription>
		</Alert>
	);
};
export default ErrorAlert;
