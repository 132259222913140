import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";

export const EditExerciseProgramDetail = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Program Exercise Details
        </Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <ReferenceInput
              source="program_id"
              reference="exercise_programs"
              label="Program"
              fullWidth
            >
              <SelectInput optionText="program_name" />
            </ReferenceInput>
          </Grid>
          <Grid size={12}>
            <ReferenceInput
              source="exercise_id"
              reference="exercises"
              label="Exercise"
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
          <Grid size={4}>
            <NumberInput
              source="day_of_week"
              validate={[required()]}
              label="Day of Week"
              min={1}
              max={7}
              fullWidth
            />
          </Grid>
          <Grid size={4}>
            <NumberInput
              source="custom_reps"
              validate={[required()]}
              label="Custom Reps"
              fullWidth
            />
          </Grid>
          <Grid size={4}>
            <NumberInput
              source="custom_sets"
              validate={[required()]}
              label="Custom Sets"
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditExerciseProgramDetail;
