import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface Exercise {
	UUID: string;
	name: string;
	setCount: number;
	isCompleted: boolean;
	totalSets: number;
	dayOfWeek: number;
}

interface ExerciseStore {
	exercises: Exercise[];
	formData: Exercise;
	setFormData: (field: string, value: unknown) => void;
	resetFormData: () => void;
	addExercise: (exercise: Exercise) => void;
	removeExercise: (exercise: Exercise) => void;
	updateExercise: (exercise: Exercise) => void;
	toggleExerciseCompletion: (exercise: Exercise) => void;
	clearExercises: () => void;
}

export const useExerciseStore = create(
	persist<ExerciseStore>(
		(set) => ({
			exercises: [],
			formData: {
				UUID: "",
				name: "",
				setCount: 0,
				isCompleted: false,
				totalSets: 0,
				dayOfWeek: 0,
			},
			setFormData: (field, value) =>
				set((state) => ({
					formData: { ...state.formData, [field]: value },
				})),
			resetFormData: () =>
				set({
					formData: {
						UUID: "",
						name: "",
						setCount: 0,
						isCompleted: false,
						totalSets: 0,
						dayOfWeek: 0,
					},
				}),
			addExercise: (exercise) =>
				set((state) => ({ exercises: [...state.exercises, exercise] })),
			removeExercise: (exercise) =>
				set((state) => ({
					exercises: state.exercises.filter((e) => e.UUID !== exercise.UUID),
				})),
			updateExercise: (exercise) =>
				set((state) => ({
					exercises: state.exercises.map((e) =>
						e.UUID === exercise.UUID ? exercise : e,
					),
				})),
			toggleExerciseCompletion: (exercise) =>
				set((state) => ({
					exercises: state.exercises.map((e) =>
						e.UUID === exercise.UUID
							? { ...e, isCompleted: !e.isCompleted }
							: e,
					),
				})),
			clearExercises: () => set({ exercises: [] }),
		}),
		{
			name: "exercise-storage", // Benzersiz bir isim
			storage: createJSONStorage(() => localStorage), // Egzersiz bilgilerini 'localStorage' kullanarak saklar
		},
	),
);

// // Egzersizleri günlerine göre gruplandırma fonksiyonu
// export const groupExercisesByDay = (exercises: Exercise[]) => {
// 	return exercises.reduce(
// 		(grouped, exercise) => {
// 			if (!grouped[exercise.dayOfWeek]) {
// 				grouped[exercise.dayOfWeek] = [];
// 			}
// 			grouped[exercise.dayOfWeek].push(exercise);
// 			return grouped;
// 		},
// 		{} as Record<number, Exercise[]>,
// 	);
// };
