import { Typography } from "@mui/material";
import {
  DateField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const ExerciseProgramDetailShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Program Exercise Details
        </Typography>
        <ReferenceField
          source="program_id"
          reference="exercise_programs"
          label="Program"
        >
          <TextField source="program_name" />
        </ReferenceField>
        <ReferenceField
          source="exercise_id"
          reference="exercises"
          label="Exercise"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="day_of_week" />
        <NumberField source="custom_reps" />
        <NumberField source="custom_sets" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ExerciseProgramDetailShow;
