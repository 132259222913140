import { Typography } from "@mui/material";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

export const HealthConditionShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Health Condition Information
        </Typography>
        <TextField source="id" />
        <TextField source="condition_name" />
        <TextField source="description" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default HealthConditionShow;
