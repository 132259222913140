import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanField,
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { TranslationsField } from "../TranslationField";

export const FoodVerificationShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Food">
            <ReferenceField source="food_id" reference="foods">
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Verified By">
            <ReferenceField source="verified_by_id" reference="users">
              <TextField source="username" />
            </ReferenceField>
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Verification Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Verification Status">
            <TextField source="verification_status" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Verification Method">
            <TextField source="verification_method" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Verification Date">
            <DateField source="verified_date" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Quality Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Data Quality Score">
            <NumberField source="data_quality_score" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="App Verified">
            <BooleanField source="app_verified" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Community Verified">
            <BooleanField source="community_verified" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Translations
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <TranslationsField label="Translations" />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Timestamps
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Created At">
            <DateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Updated At">
            <DateField source="updated_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Deleted At">
            <DateField source="deleted_at" showTime />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default FoodVerificationShow;
