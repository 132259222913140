/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, type FieldProps, type FormikFormProps } from "formik";
import type { FC, ReactNode } from "react";

// TODO bunun typeları düzeltilecek

interface DestructedFormikFieldProps {
  FieldName: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  children: (props: { field: any; form: any }) => ReactNode;
}

export const DestructedFormikField: FC<DestructedFormikFieldProps> = ({
  FieldName,
  children,
}) => {
  return (
    <Field name={FieldName}>
      {({ field, form }: { field: FieldProps; form: FormikFormProps }) => {
        return children({ field, form });
      }}
    </Field>
  );
};
