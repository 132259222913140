import { Checkbox } from "@/components/ui/checkbox";
import type React from "react";
import { DestructedFormikField } from "./DestructedField";

interface Props {
  FieldName: string;
}

const FormCheckBox: React.FC<Props> = ({ FieldName }) => {
  return (
    <DestructedFormikField FieldName={FieldName}>
      {({ field, form }) => {
        const handleChange = (value: boolean) => {
          form.setFieldValue(field.name, value);
        };

        return (
          <Checkbox
            id={FieldName}
            name={FieldName}
            checked={field.value}
            onCheckedChange={handleChange}
          />
        );
      }}
    </DestructedFormikField>
  );
};
export default FormCheckBox;
