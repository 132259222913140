import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Create, SimpleForm, TextInput, required } from "react-admin";

export const CreateAllergen = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Allergen Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={12}>
            <TextInput
              source="allergen_name"
              validate={[required()]}
              label="Allergen Name"
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateAllergen;
