export const specialties = [
  { id: "weight_management", name: "Weight Management" },
  { id: "sports_nutrition", name: "Sports Nutrition" },
  { id: "diabetes", name: "Diabetes" },
  { id: "pediatric", name: "Pediatric" },
  { id: "eating_disorders", name: "Eating Disorders" },
];

export const certifications = [
  { id: "RD", name: "Registered Dietitian" },
  { id: "CNS", name: "Certified Nutrition Specialist" },
  { id: "CSSD", name: "Board Certified Specialist in Sports Dietetics" },
  {
    id: "CSOWM",
    name: "Board Certified Specialist in Obesity and Weight Management",
  },
];
