import LoginRegisterWrapper from "@/components/LoginRegisterWrapper";
import { toast } from "@/components/ui/use-toast";
import { LoginSchema, RegisterSchema } from "@/helper/Schemas/Auth";
import { emailSignIn } from "@/helper/functions/auth/SignMethods/EmailPassword";
import { emailRegister } from "@/helper/functions/auth/register";
import type { FormikHelpers, FormikValues } from "formik";
import {
  type NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

const loginSubmit = async (
  values: FormikValues,
  { setSubmitting }: FormikHelpers<FormikValues>,
  navigate: NavigateFunction,
) => {
  setSubmitting(true);
  try {
    const { exists } = await emailSignIn(values.email, values.password);
    if (exists) {
      navigate("/");
    } else {
      navigate("/onboarding");
    }
  } catch (error) {
    console.error("Login failed:", error);
  } finally {
    setSubmitting(false);
  }
};

const registerSubmit = async (
  values: FormikValues,
  { setSubmitting }: FormikHelpers<FormikValues>,
  navigate: NavigateFunction,
) => {
  setSubmitting(true);
  try {
    const { exists } = await emailRegister(values.email, values.password);
    if (exists) {
      navigate("/");
    } else {
      navigate("/onboarding");
    }
  } catch (error: unknown) {
    console.error("Registration failed:", error);
    if (error instanceof Error) {
      const errorMessage = error.message;
      if (errorMessage.includes("auth/email-already-in-use")) {
        toast({
          title: "Email already exists",
          description: "Please use a different email address.",
          variant: "destructive",
        });
      } else {
        toast({
          title: "Registration failed",
          description: errorMessage || "An unknown error occurred",
          variant: "destructive",
        });
      }
    }
  } finally {
    setSubmitting(false);
  }
};

export default function AuthLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLogin = location.pathname === "/login";

  return (
    <LoginRegisterWrapper
      initialValues={
        isLogin
          ? {
              email: "",
              password: "",
            }
          : {
              email: "",
              password: "",
              confirmPassword: "",
              terms: false,
            }
      }
      validationSchema={isLogin ? LoginSchema : RegisterSchema}
      onSubmit={(values, formikHelpers) =>
        isLogin
          ? loginSubmit(values, formikHelpers, navigate)
          : registerSubmit(values, formikHelpers, navigate)
      }
      registerOrLogin={isLogin ? "login" : "register"}
    >
      <Outlet />
    </LoginRegisterWrapper>
  );
}
