import { Typography } from "@mui/material";
import { Show, SimpleShowLayout, TextField } from "react-admin";

export const RoleShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Role Information
        </Typography>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
      </SimpleShowLayout>
    </Show>
  );
};

export default RoleShow;
