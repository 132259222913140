import { create } from "zustand";

interface UsernameState {
  usernameError: string;
  setUsernameError: (error: string) => void;
  readyToCallAPI: boolean;
  setReadyToCallAPI: (value: boolean) => void;
}

export const useUsernameStore = create<UsernameState>((set) => ({
  usernameError: "",
  setUsernameError: (error) => set({ usernameError: error }),
  readyToCallAPI: false,
  setReadyToCallAPI: (value: boolean) => set({ readyToCallAPI: value }),
}));
