import { cva } from "class-variance-authority";
import clsx from "clsx";

export const buttonVariants = cva(
	clsx(
		// Base styles
		"inline-flex select-none items-center justify-center whitespace-nowrap rounded-md font-medium text-sm ring-offset-background transition-colors",
		// Focus styles
		"focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
		// Active styles
		"active:scale-[0.99] active:opacity-75",
		// Disabled styles
		"disabled:pointer-events-none disabled:opacity-50",
	),
	{
		variants: {
			variant: {
				default: "bg-primary text-primary-foreground hover:bg-primary/90",
				destructive:
					"bg-destructive text-destructive-foreground hover:bg-destructive/90",
				outline:
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				secondary:
					"bg-secondary text-secondary-foreground hover:bg-secondary/80",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline",
			},
			size: {
				default: "h-10 px-4 py-2",
				sm: "h-9 rounded-md px-3",
				lg: "h-11 rounded-md px-8",
				icon: "h-10 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);
