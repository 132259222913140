import { Button } from "@/components/ui/button";
import { useBoardingStore } from "@/helper/store/boardingStore";

export function WelcomeSection() {
  const { nextStep } = useBoardingStore();
  return (
    <div className="flex items-center justify-center p-4">
      <Button className="w-full font-semibold" onClick={nextStep}>
        Tamam, Başlayalım!
      </Button>
    </div>
  );
}
