import { auth } from "./firebase";

// Function to get the current auth token
export async function getAuthToken() {
	const currentUser = auth.currentUser;
	if (currentUser) {
		try {
			return await currentUser.getIdToken(true);
		} catch (error) {
			console.error("Error refreshing token:", error);
			return null;
		}
	}
	return null;
}
