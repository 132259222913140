import * as Yup from "yup";

export const UsernameSchema = (
  usernameError: string,
  setReadyToCallAPI: (value: boolean) => void,
  setUsernameError: (value: string) => void,
) =>
  Yup.string()
    .min(5, "Username is too short - should be 5 chars minimum.")
    .max(15, "Username is too long - should be 15 chars maximum.")
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "Username can only contain alphanumeric characters and underscores.",
    )
    .required("Username is required")
    .test(
      "check-username-error",
      "This username is already taken.",
      function (value) {
        const { parent } = this;

        // Eğer değer boşsa veya validasyonlardan geçememişse, API çağrısını yapma
        if (
          !value ||
          value.length < 5 ||
          !/^[a-zA-Z0-9_]+$/.test(value) ||
          Object.values(parent).some((field) => !field)
        ) {
          setReadyToCallAPI(false);
          setUsernameError(""); // Hata mesajını temizle
          return true;
        }

        // Tüm validasyonlar geçildikten sonra API çağrısını yap
        setReadyToCallAPI(true);

        // Eğer usernameError mevcutsa validasyon hatası döndür
        if (usernameError) {
          return this.createError({ message: usernameError });
        }

        // Eğer `usernameError` yoksa hata mesajını temizle
        setUsernameError("");
        return true; // Validasyon başarılıdır
      },
    );
