import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  BooleanField,
  BooleanInput,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const UserFilters = [
  <TextInput
    key="username"
    source="username"
    label="Search by username"
    alwaysOn
  />,
  <TextInput key="email" source="email" label="Search by email" />,
  <TextInput
    key="first_name"
    source="first_name"
    label="Search by first name"
  />,
  <TextInput key="last_name" source="last_name" label="Search by last name" />,
  <BooleanInput key="is_active" source="is_active" label="Active" />,
  <BooleanInput key="is_admin" source="is_admin" label="Admin" />,
  <TextInput key="provider" source="provider" label="Provider" />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
  <DateInput key="deleted_at" source="deleted_at" label="Soft Deleted At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const UserList = () => (
  <List
    actions={<ListActions />}
    filters={UserFilters}
    sort={{ field: "username", order: "ASC" }}
  >
    <Datagrid>
      <TextField source="id" label="UUID" />
      <TextField source="username" />
      <TextField source="email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <BooleanField source="is_active" />
      <BooleanField source="is_admin" />
      <TextField source="provider" />
      <DateField source="last_login" label="Last Login" />
      <DateField source="created_at" label="Created At" />
      <DateField source="updated_at" label="Updated At" />
      <EditButton resource="users" />
      <DeleteButton resource="users" />
      <DatagridDangerouslyDeleteButton resource="users" />
    </Datagrid>
  </List>
);

export default UserList;
