import { Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrayField,
  BooleanField,
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  WithListContext,
} from "react-admin";
import { TranslationsField } from "../TranslationField";

export const DieticianShow = () => {
  return (
    <Show>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="User">
              <ReferenceField source="user_id" reference="users">
                <TextField source="username" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Years of Experience">
              <NumberField source="years_of_experience" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Labeled label="Bio">
              <TextField source="bio" />
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Professional Details
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Specialties">
              <ArrayField source="specialties">
                <WithListContext
                  render={({ data }) => (
                    <ul>
                      {data?.map((item) => (
                        <li key={item.id}>{item.name || item}</li>
                      ))}
                    </ul>
                  )}
                />
              </ArrayField>
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Certifications">
              <ArrayField source="certification">
                <WithListContext
                  render={({ data }) => (
                    <ul>
                      {data?.map((item) => (
                        <li key={item.id}>{item.name || item}</li>
                      ))}
                    </ul>
                  )}
                />
              </ArrayField>
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Business Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Hourly Rate">
              <NumberField source="hourly_rate" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Available for 1-on-1">
              <BooleanField source="available_for_1on1" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Total Clients">
              <NumberField source="total_clients" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Rating">
              <NumberField source="rating" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Labeled label="Profile Video URL">
              <TextField source="profile_video_url" />
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TranslationsField label="Translations" />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Timestamps
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Created At">
              <DateField source="created_at" showTime />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Updated At">
              <DateField source="updated_at" showTime />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Deleted At">
              <DateField source="deleted_at" showTime />
            </Labeled>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default DieticianShow;
