import type React from "react";

interface Props {
  children: React.ReactNode;
  Title: string;
  Description: string;
}

const SettingsFormLayoutWrapper: React.FC<Props> = ({
  children,
  Title,
  Description,
}) => {
  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-foreground">
          {Title}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">{Description}</p>
      </div>
      {children}
    </div>
  );
};
export default SettingsFormLayoutWrapper;
