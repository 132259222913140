import { cn } from "@/helper/lib/utils";
import { Link } from "react-router-dom";
import Image from "./Image";

interface LogoProps {
  classes?: string;
  justImage?: boolean; // Controller for logo to handle link or not
}

export default function Logo({ classes, justImage }: LogoProps) {
  if (justImage) {
    return (
      <>
        <Image
          width={600}
          height={600}
          className={cn("h-8 w-auto shrink-0", classes)}
          src="https://tailwindui.com/plus/img/logos/mark.svg?color=green&shade=600"
          alt="Your Company"
        />
        <span className="sr-only">Acme Inc</span>
      </>
    );
  }

  return (
    <Link to="/" className="flex items-center gap-2">
      <Image
        width={600}
        height={600}
        className={cn("h-8 w-auto shrink-0", classes)}
        src="https://tailwindui.com/plus/img/logos/mark.svg?color=green&shade=600"
        alt="Your Company"
      />
      <span className="sr-only">Acme Inc</span>
    </Link>
  );
}
