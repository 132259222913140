import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

const warningTypes = [
  { id: "avoid", name: "Avoid" },
  { id: "limit", name: "Limit" },
  { id: "caution", name: "Caution" },
];

const warningSeverities = [
  { id: "low", name: "Low" },
  { id: "medium", name: "Medium" },
  { id: "high", name: "High" },
];

export const EditFoodHealthWarning = () => (
  <Edit>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="food_id" reference="foods">
            <SelectInput
              validate={[required()]}
              label="Food"
              optionText="name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="condition_id" reference="health_conditions">
            <SelectInput
              validate={[required()]}
              label="Health Condition"
              optionText="condition_name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Warning Details
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12, md: 6 }}>
          <SelectInput
            source="warning_severity"
            choices={warningSeverities}
            validate={[required()]}
            label="Warning Severity"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <SelectInput
            source="warning_type"
            choices={warningTypes}
            validate={[required()]}
            label="Warning Type"
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TextInput
            source="description"
            validate={[required()]}
            label="Description"
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <TextInput
            source="recommendation"
            validate={[required()]}
            label="Recommendation"
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default EditFoodHealthWarning;
