import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const SubscriptionPlanFilters = [
  <TextInput key="name" source="name" label="Search by name" alwaysOn />,
  <TextInput key="id" source="id" label="Plan ID" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const SubscriptionPlanList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "name", order: "ASC" }}
    filters={SubscriptionPlanFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
      <NumberField
        source="price"
        label="Price"
        options={{ style: "currency", currency: "USD" }}
      />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default SubscriptionPlanList;
