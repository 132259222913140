import FormWrapper from "@/components/Forms/FormCardWrapper";
import { WaterInput } from "@/components/Inputs/Unit";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { WaterValidationSchema } from "@/helper/Schemas/water";
import { APIUrlBuilder } from "@/helper/functions/ApiUrlbuilder";
import { WaterTransformer } from "@/helper/functions/converters";
import { fetchData } from "@/helper/functions/fetch";
import { waterIntakeQueryKey } from "@/helper/static/forms";
import { useAuthStore } from "@/helper/store/authStore";
import { useUnitStore } from "@/helper/store/unitStore";
import { useUserStore } from "@/helper/store/userStore";
import { ToastAction } from "@radix-ui/react-toast";
import {
  type InvalidateQueryFilters,
  useQueryClient,
} from "@tanstack/react-query";
import { Formik } from "formik";
import { Link } from "react-router-dom";

const WaterForm = () => {
  const { toast } = useToast();
  const { UUID } = useAuthStore();
  const { UserSettings } = useUserStore();
  const { selectedWaterUnit } = useUnitStore();
  const queryClient = useQueryClient();
  const refetch = () =>
    queryClient.invalidateQueries(
      waterIntakeQueryKey as InvalidateQueryFilters,
    );
  return (
    <Formik
      initialValues={{
        water_intake: 0,
      }}
      onSubmit={async ({ water_intake }, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        const endpoint = APIUrlBuilder("/water/water-intake");
        const transformedWater = WaterTransformer(
          water_intake,
          selectedWaterUnit,
          UserSettings.water_unit,
        );

        try {
          const data = await fetchData(endpoint, "POST", {
            user_id: UUID,
            amount: Number.parseFloat(transformedWater.toString()),
          });
          if (data.success) {
            toast({
              title: "Water intake added",
              // TODO: Add the water unit to the description.
              description: `You have added ${water_intake}ml of water.`,
            });
            resetForm();
            refetch();
          } else {
            console.error("Form submission error", data);
            toast({
              title: "Failed to add water intake",
              description: data.message,
              variant: "destructive",
              action: <ToastAction altText="Try again">Try again</ToastAction>,
            });
          }
        } catch (error) {
          console.error("Network or server error:", error);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: `There was a problem with your request. Error:${error}`,
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        }
        setSubmitting(false);
      }}
      validationSchema={WaterValidationSchema}
    >
      {() => (
        <FormWrapper
          withCard
          cardTitle="Add Water"
          cardDescription="Add your water intake here. Click Add when you're done."
        >
          <WaterInput DefaultUnitValue={UserSettings.water_unit} />
          <span>
            Want to change the water unit?
            <Button asChild variant="link">
              <Link to="/settings/account">Go to settings.</Link>
            </Button>
          </span>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default WaterForm;
