import { DeleteButton, EditButton, TopToolbar } from "react-admin";

export default function ShowActionButtons() {
  return (
    <TopToolbar>
      <DeleteButton />
      <EditButton />
    </TopToolbar>
  );
}
