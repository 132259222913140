import { debouncedCheckUsername } from "@/helper/functions/auth/checkUsernameAvailable";
import { useUsernameStore } from "@/helper/store/usernameStore";
import { useQuery } from "@tanstack/react-query";
import { useField } from "formik";
import type { FC } from "react";
import { InputComponent } from "./InputComponent";
import { InputLabelComponent } from "./InputLabel";

export function UsernameInputComponent() {
  const FieldName = "username";
  const [field] = useField(FieldName);
  const { usernameError, setUsernameError, readyToCallAPI } =
    useUsernameStore();

  useQuery({
    queryKey: ["checkUsername", field.value],
    queryFn: () => debouncedCheckUsername(field.value, setUsernameError),
    enabled: Boolean(readyToCallAPI && field.value.length > 0),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <div className="w-full space-y-2">
      <InputLabelComponent
        LabelText="Kullanıcı Adı"
        For={FieldName}
        FieldName={FieldName}
        classes={usernameError ? "text-red-500" : ""}
      />
      <InputComponent
        Name={FieldName}
        FieldId={FieldName}
        Placeholder="Username"
        InputType="text"
        classes={usernameError ? "border-destructive" : ""}
      />
      <UsernameError />
    </div>
  );
}

const UsernameError: FC = () => {
  const FieldName = "username";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta] = useField(FieldName);
  const { usernameError } = useUsernameStore();

  // Only show Formik errors if the field was touched, but always show API errors
  return (meta.touched && meta.error) || usernameError ? (
    <div className="select-none text-xs font-semibold italic text-red-500">
      {(meta.touched && meta.error) || usernameError}
    </div>
  ) : null;
};
