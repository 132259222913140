import ShowActionButtons from "@/components/Buttons/Admin/ShowActionButtons";
import { Divider, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { TranslationsField } from "../TranslationField";

export const FoodShow = () => {
  return (
    <Show actions={<ShowActionButtons />}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <Labeled label="UUID">
              <TextField source="id" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Name">
              <TextField source="name" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Brand">
              <ReferenceField
                source="brand_id"
                reference="brands"
                label="Brand"
              >
                <TextField source="name" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Food Type">
              <ReferenceField
                source="food_type_id"
                reference="food_types"
                label="Food Type"
              >
                <TextField source="type_name" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Country">
              <TextField source="country" />
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Nutritional Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <Labeled label="Calories">
              <NumberField source="calories" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Proteins (g)">
              <NumberField source="proteins" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Carbs (g)">
              <NumberField source="carbs" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Fats (g)">
              <NumberField source="fats" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Saturated Fats (g)">
              <NumberField source="saturated_fats" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Fiber (g)">
              <NumberField source="fiber" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Sugar (g)">
              <NumberField source="sugar" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Sodium (mg)">
              <NumberField source="sodium" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Serving Size">
              <TextField source="serving_size" />
            </Labeled>
          </Grid>
          <Grid size={6}>
            <Labeled label="Serving Size Unit">
              <TextField source="serving_size_unit" />
            </Labeled>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Timestamps
        </Typography>
        <Grid container spacing={2}>
          <Grid size={4}>
            <Labeled label="Created At">
              <DateField source="created_at" showTime />
            </Labeled>
          </Grid>
          <Grid size={4}>
            <Labeled label="Updated At">
              <DateField source="updated_at" showTime />
            </Labeled>
          </Grid>
          <Grid size={4}>
            <Labeled label="Deleted At">
              <DateField source="deleted_at" showTime />
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Stack spacing={2}>
          <TranslationsField label="Translations" />
        </Stack>
      </SimpleShowLayout>
    </Show>
  );
};

export default FoodShow;
