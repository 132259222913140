import MealForm from "@/components/Forms/FormComponents/MealForm";

function MealPage() {
  return (
    <>
      <section className="grid gap-6">
        <h1 className="select-none text-3xl font-semibold leading-6">
          Add new Meal
        </h1>
        <MealForm />
      </section>
    </>
  );
}
export default MealPage;
