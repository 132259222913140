import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup); // extend yup

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),

  password: Yup.string().required("Required"),
});

export const RegisterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),

  password: Yup.string()
    .required("Required")
    .min(8, "Minimum 8 characters")
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),

  terms: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions to sign up.")
    .required("Required"),
});
