import {
  MultiSelect,
  MultiSelectContent,
  MultiSelectEmpty,
  MultiSelectGroup,
  MultiSelectItem,
  MultiSelectList,
  MultiSelectSearch,
  MultiSelectTrigger,
  MultiSelectValue,
} from "@/components/ui/multi-select";
import { useField, useFormikContext } from "formik";
import { InputError } from "./InputError";

interface FormMultiSelectProps {
  name: string;
  options: {
    group?: string;
    items: { value: string; label: string }[];
  }[];
  placeholder?: string;
  searchPlaceholder?: string;
  width?: string;
}

export const FormMultiSelect = ({
  name,
  options,
  placeholder = "Select options",
  searchPlaceholder = "Input to search",
  width = "w-96",
}: FormMultiSelectProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (selectedItems: string[]) => {
    setFieldValue(name, selectedItems);
  };

  return (
    <div>
      <MultiSelect value={field.value} onValueChange={handleChange}>
        <MultiSelectTrigger className={width}>
          <MultiSelectValue placeholder={placeholder} />
        </MultiSelectTrigger>
        <MultiSelectContent>
          <MultiSelectSearch placeholder={searchPlaceholder} />
          <MultiSelectList>
            {options?.[0].items?.length > 0 &&
              options?.map((group) => (
                <MultiSelectGroup key={group.group} heading={group.group}>
                  {group?.items.map((item) => (
                    <MultiSelectItem key={item.value} value={item.value}>
                      {item.label}
                    </MultiSelectItem>
                  ))}
                </MultiSelectGroup>
              ))}
          </MultiSelectList>
          <MultiSelectEmpty />
        </MultiSelectContent>
      </MultiSelect>
      <InputError Name={name} />
    </div>
  );
};
