import FoodForm from "@/components/Forms/FormComponents/FoodForm";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import clsx from "clsx";
import { PlusIcon } from "lucide-react";
import MealForm from "./Forms/FormComponents/MealForm";
import WaterForm from "./Forms/FormComponents/WaterForm";

// TODO add fast adding functionality with user saved meals and exercises
// TODO add exercise form
function FastAddModal() {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <button
            type="button"
            className={clsx(
              "bg-primary text-primary-foreground",
              "fixed h-10 w-10 rounded-full transition-all hover:bg-primary/50 focus:ring focus:ring-foreground active:bg-primary/80",
              import.meta.env.DEV
                ? "bottom-16 right-4"
                : "bottom-4 right-4 z-50",
              "flex items-center justify-center",
            )}
          >
            <PlusIcon className="h-auto w-auto" />
          </button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Quick add</DialogTitle>
            <DialogDescription>
              Add your meals, exercises, and water intake quickly.
            </DialogDescription>
          </DialogHeader>
          <Tabs defaultValue="water">
            <TabsList className="grid w-full grid-cols-3">
              {/* <TabsList className="grid w-full grid-cols-4"> */}
              <TabsTrigger value="water">Water</TabsTrigger>
              <TabsTrigger value="food">Food</TabsTrigger>
              <TabsTrigger value="meal">Meal</TabsTrigger>
              {/* <TabsTrigger value="exercise">Exercise</TabsTrigger> */}
            </TabsList>
            <TabsContent value="water">
              <WaterForm />
            </TabsContent>
            <TabsContent value="food">
              <FoodForm />
            </TabsContent>
            <TabsContent value="meal">
              <MealForm />
            </TabsContent>
            {/* <TabsContent value="exercise">Add your exercise here.</TabsContent> */}
          </Tabs>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FastAddModal;
