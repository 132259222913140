import { create } from "zustand";
import type { heightUnit, waterUnit, weightUnit } from "../types/Units";

interface unitStore {
	selectedHeightUnit: heightUnit;
	setHeightUnit: (newUnit: heightUnit) => void;
	convertedHeight: number;
	setConvertedHeight: (newHeight: number) => void;
	selectedWeightUnit: weightUnit;
	setWeightUnit: (newUnit: weightUnit) => void;
	convertedWeight: number;
	setConvertedWeight: (newWeight: number) => void;
	setWaterUnit: (newUnit: waterUnit) => void;
	selectedWaterUnit: waterUnit;
	convertedWater: number;
}

export const useUnitStore = create<unitStore>((set) => ({
	selectedHeightUnit: "cm",
	setHeightUnit: (newUnit: heightUnit) => set({ selectedHeightUnit: newUnit }),
	convertedHeight: 0,
	setConvertedHeight: (newHeight: number) =>
		set({ convertedHeight: newHeight }),
	selectedWeightUnit: "kg",
	setWeightUnit: (newUnit: weightUnit) => set({ selectedWeightUnit: newUnit }),
	convertedWeight: 0,
	setConvertedWeight: (newWeight: number) =>
		set({ convertedWeight: newWeight }),
	selectedWaterUnit: "ml",
	setWaterUnit: (newUnit: waterUnit) => set({ selectedWaterUnit: newUnit }),
	convertedWater: 0,
}));
