import { getCustomClaims } from "@/helper/functions/auth/getCustomClaims";
import { queryClient } from "@/helper/lib/react-query";
import type { LoaderFunction } from "react-router-dom";
import { redirect } from "react-router-dom";

export const adminLoader: LoaderFunction = async () => {
	try {
		const claimsQuery = await queryClient.fetchQuery({
			queryKey: ["adminRole"],
			queryFn: getCustomClaims,
		});

		if (claimsQuery?.role !== "admin") {
			return redirect("/404");
		}

		return { role: claimsQuery.role };
	} catch (error) {
		console.error("Admin loader error:", error);
		return redirect("/404");
	}
};
