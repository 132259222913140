import { capitalizeFirstLetter } from "@/helper/lib/utils";
import type { CreateUserInput, HealthGoal } from "../graphql/generated/graphql";
import { useUnitStore } from "../store/unitStore";

export function convertValuesForBackend(
	boarding: CreateUserInput,
): CreateUserInput {
	// Simple conversions
	const first_name = capitalizeFirstLetter(
		boarding.first_name?.trim().toLowerCase() || "",
	);
	const last_name = capitalizeFirstLetter(
		boarding.last_name?.trim().toLowerCase() || "",
	);

	const { selectedHeightUnit, selectedWeightUnit } = useUnitStore.getState();

	if (!boarding.settings) {
		throw new Error("Settings are undefined");
	}

	if (boarding.settings.height_unit !== selectedHeightUnit) {
		boarding.settings.height_unit = selectedHeightUnit;
	}
	if (boarding.settings.weight_unit !== selectedWeightUnit) {
		boarding.settings.weight_unit = selectedWeightUnit;
	}

	return {
		username: boarding.username || "",
		email: boarding.email || "",
		first_name: first_name,
		last_name: last_name,
		provider: boarding.provider || "",
		social_id: boarding.social_id || "",
		profile_picture: "",
		provider_profile_picture: boarding.provider_profile_picture || "",
		health_profile: {
			weight: Number(boarding.health_profile?.weight) || 0,
			height: Number(boarding.health_profile?.height) || 0,
			birth_date: boarding.health_profile?.birth_date,
			gender: boarding.health_profile?.gender || "",
			activity_level: boarding.health_profile?.activity_level || 1,
			dietary_preference_id: "",
			health_goal: boarding.health_profile?.health_goal as HealthGoal,
			smoking_status: boarding.health_profile?.smoking_status || false,
			alcohol_consumption:
				boarding.health_profile?.alcohol_consumption || false,
			// custom_calorie_goal: 0,
			// goal_start_date: new Date(),
			// goal_daily_water_intake: 0,
		},
		settings: {
			language: boarding.settings?.language || "",
			weight_unit: boarding.settings?.weight_unit || "",
			height_unit: boarding.settings?.height_unit || "",
			water_unit: boarding.settings?.water_unit || "",
			energy_unit: boarding.settings?.energy_unit || "",
			preferred_meal_country: boarding.settings?.preferred_meal_country || "",
			country_code: "",
		},
		allergies: {
			allergies: boarding.allergies?.allergies || [],
		},
		healthConditions: {
			conditions: boarding.healthConditions?.conditions || [],
		},
		custom_meal_wanted: boarding.custom_meal_wanted || false,
	};
}
