import {
  difficultyLevels,
  equipment,
  targetAudience,
  targetMuscles,
} from "@/helper/static/Exercise/Array";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrayInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import { TranslationsInput } from "../TranslationInput";

export const CreateExerciseProgram = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 6 }}>
            <TextInput
              source="program_name"
              validate={[required()]}
              label="Program Name"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <ReferenceInput source="created_by_id" reference="users">
              <SelectInput
                validate={[required()]}
                label="Created By"
                optionText="username"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextInput
              source="description"
              validate={[required()]}
              label="Description"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Program Details
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 4 }}>
            <SelectInput
              source="difficulty_level"
              choices={difficultyLevels}
              validate={[required()]}
              label="Difficulty Level"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <NumberInput
              source="duration_weeks"
              validate={[required()]}
              label="Duration (weeks)"
              min={1}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <NumberInput
              source="workouts_per_week"
              validate={[required()]}
              label="Workouts Per Week"
              min={1}
              max={7}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Target Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectArrayInput
              source="target_muscles"
              choices={targetMuscles}
              validate={[required()]}
              label="Target Muscles"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectArrayInput
              source="equipment_needed"
              choices={equipment}
              label="Equipment Required"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Program Goals
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 6 }}>
            <ArrayInput
              source="program_goals.primary_goals"
              label="Primary Goals"
            >
              <SimpleFormIterator>
                <TextInput
                  source=""
                  label="First goal"
                  fullWidth
                  parse={(v) => v}
                  format={(v) => v}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <ArrayInput
              source="program_goals.secondary_goals"
              label="Secondary Goals"
            >
              <SimpleFormIterator>
                <TextInput
                  source=""
                  label="Second goal"
                  fullWidth
                  parse={(v) => v}
                  format={(v) => v}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Additional Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 4 }}>
            <NumberInput
              source="estimated_calories_burned"
              label="Estimated Calories Burned"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <SelectArrayInput
              source="target_audience"
              choices={targetAudience}
              label="Target Audience"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <TextInput
              source="prerequisites"
              label="Prerequisites"
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12 }}>
            <TranslationsInput
              source="translations"
              isNested
              fields={["program_name", "description", "trainer_notes"]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateExerciseProgram;
