import { Button } from "@/components/ui/button";
import type React from "react";
import SettingsFormLayoutWrapper from "../../Forms/Layout/LayoutWrapper";

interface Props {
  children?: React.ReactNode;
}

const AccountDeleteSection: React.FC<Props> = () => {
  return (
    <SettingsFormLayoutWrapper
      Title="Delete account"
      Description="No longer want to use our service? You can delete your account here.
          This action is not reversible. All information related to this account
          will be deleted permanently."
    >
      <form className="flex items-start md:col-span-2">
        <Button type="submit" variant="destructive">
          Yes, delete my account
        </Button>
      </form>
    </SettingsFormLayoutWrapper>
  );
};
export default AccountDeleteSection;
