import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CreateFoodBarcode = () => (
  <Create>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Food Barcode Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="food_id" reference="foods">
            <SelectInput
              validate={[required()]}
              label="Food"
              optionText="name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            source="barcode"
            validate={[required()]}
            label="Barcode"
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default CreateFoodBarcode;
