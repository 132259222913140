import { certifications, specialties } from "@/helper/static/TrainerArray";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { TranslationsInput } from "../TranslationInput";

export const EditTrainer = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 6 }}>
            <ReferenceInput source="user_id" reference="users">
              <SelectInput
                validate={[required()]}
                label="User"
                optionText="username"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <NumberInput
              source="years_of_experience"
              validate={[required()]}
              label="Years of Experience"
              min={0}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextInput
              source="bio"
              validate={[required()]}
              label="Bio"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Professional Details
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectArrayInput
              source="specialties"
              choices={specialties}
              validate={[required()]}
              label="Specialties"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectArrayInput
              source="certification"
              choices={certifications}
              validate={[required()]}
              label="Certifications"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Business Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <NumberInput
              source="hourly_rate"
              validate={[required()]}
              label="Hourly Rate"
              min={0}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <BooleanInput
              source="available_for_1on1"
              label="Available for 1-on-1"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <TextInput
              source="profile_video_url"
              label="Profile Video URL"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TranslationsInput
              source="translations"
              isNested
              fields={["bio"]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditTrainer;
