import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import type React from "react";
import { useEffect, useRef, useState } from "react";

interface Props {
	test?: string;
}

// TODO turn this one into a formik or a modal opener

const SearchInput: React.FC<Props> = () => {
	const [focusInput, setFocusInput] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (focusInput && inputRef.current) {
			inputRef.current.focus();
			setFocusInput(false); // Optionally reset the focus state
		}
	}, [focusInput]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			// Cmd + K for MacOS or Ctrl + K for Windows/Linux
			if ((event.metaKey || event.ctrlKey) && event.key === "k") {
				event.preventDefault();
				setFocusInput(true);
			} else if (event.key === "Escape") {
				// ESC key to lose focus
				if (inputRef.current) {
					inputRef.current.blur();
				}
			}
		};

		// Add event listener for keydown
		window.addEventListener("keydown", handleKeyDown);

		// Cleanup
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, []);

	return (
		<form className="relative flex flex-1" action="#" method="GET">
			<label htmlFor="search-field" className="sr-only">
				Search
			</label>
			{/* <div className="relative"> */}
			<MagnifyingGlassIcon
				className="pointer-events-none h-full w-5 text-foreground/40"
				aria-hidden="true"
			/>
			{/* </div> */}
			<input
				id="search-field"
				ref={inputRef}
				className={clsx(
					"block h-full w-full border-0 py-0 pr-0 pl-8 placeholder:text-foreground/40 focus:ring-0 sm:text-sm",
					"bg-background text-foreground",
				)}
				placeholder="Search..."
				type="search"
				name="search"
				aria-label="Search"
			/>
		</form>
	);
};
export default SearchInput;
