import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  BooleanInput,
  Create,
  DateInput,
  PasswordInput,
  SimpleForm,
  TextInput,
  email,
  required,
} from "react-admin";

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <Grid container spacing={2}>
        {/* Basic Info Section */}
        <Grid size={12}>
          <Typography variant="h6">Basic Info</Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput source="username" validate={[required()]} fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            source="email"
            validate={[required(), email()]}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <PasswordInput source="password" validate={[required()]} fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput source="first_name" validate={[required()]} fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput source="last_name" validate={[required()]} fullWidth />
        </Grid>

        {/* Status & Role Section */}
        <Grid size={12}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Status & Role
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }} display="flex">
          <Grid>
            <BooleanInput source="is_active" defaultValue={true} />
          </Grid>
          <Grid>
            <BooleanInput source="is_admin" defaultValue={false} />
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            source="provider"
            validate={[required()]}
            defaultValue="password"
            fullWidth
          />
        </Grid>

        {/* Profile Section */}
        <Grid size={12}>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Profile
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput source="social_id" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput source="profile_picture" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput source="provider_profile_picture" fullWidth />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <DateInput source="last_login" defaultValue={new Date()} />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default UserCreate;
