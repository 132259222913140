import FormPasswordInput from "@/components/Forms/Inputs/FormPasswordInput";
import { InputComponent } from "@/components/Forms/Inputs/InputComponent";
import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Link } from "react-router-dom";

const EmailInput = "email";
const PasswordInput = "password";

export default function LoginPage() {
  return (
    <>
      <div>
        <InputLabelComponent
          LabelText="Email address"
          FieldName={EmailInput}
          For={EmailInput}
        />
        <div className="mt-2">
          <InputComponent
            Name={EmailInput}
            FieldId={EmailInput}
            InputType="email"
            Placeholder="Enter your email address"
            required
          />
        </div>
        <InputError Name={EmailInput} />
      </div>

      <div>
        <InputLabelComponent
          LabelText="Password"
          FieldName={PasswordInput}
          For={PasswordInput}
        />
        <div className="mt-2">
          <FormPasswordInput
            FieldName={PasswordInput}
            autoComplete="current-password"
            placeholder="Enter your password"
          />
        </div>
        <InputError Name={PasswordInput} />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Checkbox id="remember-me" name="remember-me" />
          <Label htmlFor="remember-me" className="ml-3 block">
            Remember me
          </Label>
        </div>

        <div className="text-sm leading-6">
          <Button variant="link" asChild>
            <Link to="/forgot-password">Forgot password?</Link>
          </Button>
        </div>
      </div>
    </>
  );
}
