import { cn } from "@/helper/lib/utils";
import { ErrorMessage } from "formik";

export function InputError({
  Name,
  classes,
}: {
  Name: string;
  classes?: string;
}) {
  return (
    <ErrorMessage
      name={Name}
      component={"span"}
      className={cn(
        classes ?? "select-none text-xs font-semibold italic text-red-500",
      )}
    />
  );
}
