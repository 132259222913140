import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

// this is for shadcn
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Bu fonksiyon, verilen nesne grubu ve alan adını birleştirerek bir Formik için uygun "name" özelliği üretir.
export function fieldTypeBuilder(
  fieldFamily: string,
  fieldName: string,
): string {
  return `${fieldFamily}.${fieldName}`;
}

// Function to capitalize the first letter
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Function to lowercase the first letter
export const lowercaseFirstLetter = (string: string): string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const sentenceCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const titleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

import { subYears } from "date-fns";

export const defaultBirthDate = subYears(new Date(), 25);
