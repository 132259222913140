/* eslint-disable @typescript-eslint/no-explicit-any */
import * as PopoverPrimitive from "@radix-ui/react-popover";
// biome-ignore lint/style/useImportType: <explanation>
import { Primitive } from "@radix-ui/react-primitive";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import { Check, ChevronsUpDown, X } from "lucide-react";
import { createPortal } from "react-dom";

import { Badge } from "@/components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/helper/lib/utils";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type FC,
  type ReactNode,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export interface MultiSelectOptionItem {
  value: string;
  label?: ReactNode;
}

interface MultiSelectContextValue {
  value: string[];

  open: boolean;

  onSelect: (value: string, item: MultiSelectOptionItem) => void;

  onDeselect: (value: string, item: MultiSelectOptionItem) => void;

  onSearch?: (keyword?: string | undefined) => void;

  filter?: boolean | ((keyword: string, current: string) => boolean);

  disabled?: boolean;

  maxCount?: number;

  itemCache: Map<string, MultiSelectOptionItem>;
}

const MultiSelectContext = createContext<MultiSelectContextValue | undefined>(
  undefined,
);

function useMultiSelect() {
  const context = useContext(MultiSelectContext);

  if (!context)
    throw new Error("useMultiSelect must be used within MultiSelectProvider");

  return context;
}

type MultiSelectProps = ComponentPropsWithoutRef<
  typeof PopoverPrimitive.Root
> & {
  value?: string[];
  onValueChange?: (value: string[], items: MultiSelectOptionItem[]) => void;
  onSelect?: (value: string, item: MultiSelectOptionItem) => void;
  onDeselect?: (value: string, item: MultiSelectOptionItem) => void;
  defaultValue?: string[];
  onSearch?: (keyword?: string | undefined) => void;
  filter?: boolean | ((keyword: string, current: string) => boolean);
  disabled?: boolean;
  maxCount?: number;
};

const MultiSelect: FC<MultiSelectProps> = ({
  value: valueProp,
  onValueChange: onValueChangeProp,
  onDeselect: onDeselectProp,
  onSelect: onSelectProp,
  defaultValue,
  open: openProp,
  onOpenChange,
  defaultOpen,
  onSearch,
  filter,
  disabled,
  maxCount,
  ...popoverProps
}) => {
  const itemCache = useRef(new Map<string, MultiSelectOptionItem>()).current;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleValueChange = useCallback(
    (state: string[]) => {
      if (onValueChangeProp) {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        const items = state.map((value) => itemCache.get(value)!);

        onValueChangeProp(state, items);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onValueChangeProp],
  );

  const [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: handleValueChange,
  });

  const [open, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  const handleSelect = useCallback(
    (value: string, item: MultiSelectOptionItem) => {
      setValue((prev) => {
        if (prev?.includes(value)) return prev;

        onSelectProp?.(value, item);

        return prev ? [...prev, value] : [value];
      });
    },
    [onSelectProp, setValue],
  );

  const handleDeselect = useCallback(
    (value: string, item: MultiSelectOptionItem) => {
      setValue((prev) => {
        if (!prev || !prev.includes(value)) return prev;

        onDeselectProp?.(value, item);

        return prev.filter((v) => v !== value);
      });
    },
    [onDeselectProp, setValue],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const contextValue = useMemo(() => {
    return {
      value: value || [],
      open: open || false,
      onSearch,
      filter,
      disabled,
      maxCount,
      onSelect: handleSelect,
      onDeselect: handleDeselect,
      itemCache,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    open,
    onSearch,
    filter,
    disabled,
    maxCount,
    handleSelect,
    handleDeselect,
  ]);

  return (
    <MultiSelectContext.Provider value={contextValue}>
      <PopoverPrimitive.Root
        {...popoverProps}
        open={open}
        onOpenChange={setOpen}
      />
    </MultiSelectContext.Provider>
  );
};

MultiSelect.displayName = "MultiSelect";

type MultiSelectTriggerElement = ElementRef<typeof Primitive.div>;

interface MultiSelectTriggerProps
  extends ComponentPropsWithoutRef<typeof Primitive.div> {}

function PreventClick(e: MouseEvent | TouchEvent) {
  e.preventDefault();
  e.stopPropagation();
}

const MultiSelectTrigger = forwardRef<
  MultiSelectTriggerElement,
  MultiSelectTriggerProps
>(({ className, children, ...props }, forwardedRef) => {
  const { disabled } = useMultiSelect();

  return (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    <PopoverPrimitive.Trigger ref={forwardedRef as any} asChild>
      <div
        aria-disabled={disabled}
        data-disabled={disabled}
        {...props}
        className={cn(
          "flex size-full min-h-10 items-center justify-between whitespace-nowrap rounded-md border border-input bg-background px-3 py-2 text-sm shadow-sm ring-offset-background focus:outline-none focus:ring-1 focus:ring-ring [&>span]:line-clamp-1",
          disabled ? "cursor-not-allowed opacity-50" : "cursor-text",
          className,
        )}
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onClick={disabled ? PreventClick : (props.onClick as any)}
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onTouchStart={disabled ? PreventClick : (props.onTouchStart as any)}
      >
        {children}
        <ChevronsUpDown aria-hidden className="size-4 shrink-0 opacity-50" />
      </div>
    </PopoverPrimitive.Trigger>
  );
});

MultiSelectTrigger.displayName = "MultiSelectTrigger";

interface MultiSelectValueProps
  extends ComponentPropsWithoutRef<typeof Primitive.div> {
  placeholder?: string;
  maxDisplay?: number;
  maxItemLength?: number;
}

const MultiSelectValue = forwardRef<
  ElementRef<typeof Primitive.div>,
  MultiSelectValueProps
>(
  (
    { className, placeholder, maxDisplay, maxItemLength, ...props },
    forwardRef,
  ) => {
    const { value, itemCache, onDeselect } = useMultiSelect();
    const [firstRendered, setFirstRendered] = useState(false);

    const renderRemain =
      maxDisplay && value.length > maxDisplay ? value.length - maxDisplay : 0;
    const renderItems = renderRemain ? value.slice(0, maxDisplay) : value;

    useLayoutEffect(() => {
      setFirstRendered(true);
    }, []);

    if (!value.length || !firstRendered) {
      return (
        <span className="pointer-events-none text-muted-foreground">
          {placeholder}
        </span>
      );
    }

    return (
      <TooltipProvider delayDuration={300}>
        <div
          className={cn(
            "flex flex-1 flex-wrap items-center gap-1.5 overflow-x-hidden",
            className,
          )}
          {...props}
          ref={forwardRef}
        >
          {renderItems.map((value) => {
            const item = itemCache.get(value);

            const content = item?.label || value;

            const child =
              maxItemLength &&
              typeof content === "string" &&
              content.length > maxItemLength
                ? `${content.slice(0, maxItemLength)}...`
                : content;

            const el = (
              <Badge
                variant="outline"
                key={value}
                className="group/multi-select-badge cursor-pointer rounded-full pr-1.5"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // biome-ignore lint/style/noNonNullAssertion: <explanation>
                  onDeselect(value, item!);
                }}
              >
                <span>{child}</span>
                <X className="ml-1 size-3 text-muted-foreground group-hover/multi-select-badge:text-foreground" />
              </Badge>
            );

            if (child !== content) {
              return (
                <Tooltip key={value}>
                  <TooltipTrigger className="inline-flex">{el}</TooltipTrigger>
                  <TooltipContent
                    side="bottom"
                    align="start"
                    className="z-[51]"
                  >
                    {content}
                  </TooltipContent>
                </Tooltip>
              );
            }

            return el;
          })}
          {renderRemain ? (
            <span className="py-.5 text-xs leading-4 text-muted-foreground">
              +{renderRemain}
            </span>
          ) : null}
        </div>
      </TooltipProvider>
    );
  },
);

const MultiSelectSearch = forwardRef<
  ElementRef<typeof CommandInput>,
  ComponentPropsWithoutRef<typeof CommandInput>
>((props, ref) => {
  const { onSearch } = useMultiSelect();

  return <CommandInput ref={ref} {...props} onValueChange={onSearch} />;
});

MultiSelectSearch.displayName = "MultiSelectSearch";

const MultiSelectList = forwardRef<
  ElementRef<typeof CommandList>,
  ComponentPropsWithoutRef<typeof CommandList>
>(({ className, ...props }, ref) => {
  return (
    <CommandList
      ref={ref}
      className={cn("max-h-[unset] px-0 py-1", className)}
      {...props}
    />
  );
});

MultiSelectList.displayName = "MultiSelectList";

interface MultiSelectContentProps
  extends ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {}

const MultiSelectContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  MultiSelectContentProps
>(({ className, children, ...props }, ref) => {
  const context = useMultiSelect();

  const fragmentRef = useRef<DocumentFragment>();

  if (!fragmentRef.current && typeof window !== "undefined")
    fragmentRef.current = document.createDocumentFragment();

  if (!context.open) {
    return fragmentRef.current
      ? createPortal(<Command>{children}</Command>, fragmentRef.current)
      : null;
  }

  return (
    <PopoverPrimitive.Portal forceMount>
      <PopoverPrimitive.Content
        ref={ref}
        align="start"
        sideOffset={4}
        collisionPadding={10}
        className={cn(
          "z-50 w-full rounded-md border bg-popover p-0 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        )}
        style={
          {
            "--radix-select-content-transform-origin":
              "var(--radix-popper-transform-origin)",
            "--radix-select-content-available-width":
              "var(--radix-popper-available-width)",
            "--radix-select-content-available-height":
              "var(--radix-popper-available-height)",
            "--radix-select-trigger-width": "var(--radix-popper-anchor-width)",
            "--radix-select-trigger-height":
              "var(--radix-popper-anchor-height)",
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          } as any
        }
        {...props}
      >
        <Command
          className={cn(
            "max-h-96 w-full min-w-[var(--radix-select-trigger-width)] px-1",
            className,
          )}
          shouldFilter={!context.onSearch}
        >
          {children}
        </Command>
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
});

type MultiSelectItemProps = ComponentPropsWithoutRef<typeof CommandItem> &
  Partial<MultiSelectOptionItem> & {
    onSelect?: (value: string, item: MultiSelectOptionItem) => void;
    onDeselect?: (value: string, item: MultiSelectOptionItem) => void;
  };

const MultiSelectItem = forwardRef<
  ElementRef<typeof CommandItem>,
  MultiSelectItemProps
>(
  (
    {
      value,
      onSelect: onSelectProp,
      onDeselect: onDeselectProp,
      children,
      label,
      disabled: disabledProp,
      className,
      ...props
    },
    forwardedRef,
  ) => {
    const {
      value: contextValue,
      maxCount,
      onSelect,
      onDeselect,
      itemCache,
    } = useMultiSelect();

    const item = useMemo(() => {
      return value
        ? {
            value,
            label:
              label || (typeof children === "string" ? children : undefined),
          }
        : undefined;
    }, [value, label, children]);

    const selected = Boolean(value && contextValue.includes(value));

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      if (value) itemCache.set(value, item!);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, value, item]);

    const disabled = Boolean(
      disabledProp ||
        (!selected && maxCount && contextValue.length >= maxCount),
    );

    const handleClick = () => {
      if (selected) {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        onDeselectProp?.(value!, item!);
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        onDeselect(value!, item!);
      } else {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        itemCache.set(value!, item!);
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        onSelectProp?.(value!, item!);
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        onSelect(value!, item!);
      }
    };

    return (
      <CommandItem
        {...props}
        value={value}
        className={cn(
          disabled && "cursor-not-allowed text-muted-foreground",
          className,
        )}
        disabled={disabled}
        onSelect={!disabled && value ? handleClick : undefined}
        ref={forwardedRef}
      >
        <span className="mr-2 truncate">{children || label || value}</span>
        {selected ? <Check className="ml-auto size-4 shrink-0" /> : null}
      </CommandItem>
    );
  },
);

const MultiSelectGroup = forwardRef<
  ElementRef<typeof CommandGroup>,
  ComponentPropsWithoutRef<typeof CommandGroup>
>((props, forwardRef) => {
  return <CommandGroup {...props} ref={forwardRef} />;
});

MultiSelectGroup.displayName = "MultiSelectGroup";

const MultiSelectSeparator = forwardRef<
  ElementRef<typeof CommandSeparator>,
  ComponentPropsWithoutRef<typeof CommandSeparator>
>((props, forwardRef) => {
  return <CommandSeparator {...props} ref={forwardRef} />;
});

MultiSelectSeparator.displayName = "MultiSelectSeparator";

const MultiSelectEmpty = forwardRef<
  ElementRef<typeof CommandEmpty>,
  ComponentPropsWithoutRef<typeof CommandEmpty>
>(({ children = "No Content", ...props }, forwardRef) => {
  return (
    <CommandEmpty {...props} ref={forwardRef}>
      {children}
    </CommandEmpty>
  );
});

MultiSelectEmpty.displayName = "MultiSelectEmpty";

export interface MultiSelectOptionSeparator {
  type: "separator";
}

export interface MultiSelectOptionGroup {
  heading?: ReactNode;
  value?: string;
  children: MultiSelectOption[];
}

export type MultiSelectOption =
  | Pick<
      MultiSelectItemProps,
      "value" | "label" | "disabled" | "onSelect" | "onDeselect"
    >
  | MultiSelectOptionSeparator
  | MultiSelectOptionGroup;

function renderMultiSelectOptions(list: MultiSelectOption[]) {
  return list.map((option, index) => {
    if ("type" in option) {
      if (option.type === "separator")
        return <MultiSelectSeparator key={`key-${index}${1}`} />;

      return null;
    }

    if ("children" in option) {
      return (
        <MultiSelectGroup
          key={option.value || index}
          value={option.value}
          heading={option.heading}
        >
          {renderMultiSelectOptions(option.children)}
        </MultiSelectGroup>
      );
    }

    return (
      <MultiSelectItem key={option.value} {...option}>
        {option.label}
      </MultiSelectItem>
    );
  });
}

export {
  MultiSelect,
  MultiSelectContent,
  MultiSelectEmpty,
  MultiSelectGroup,
  MultiSelectItem,
  MultiSelectList,
  MultiSelectSearch,
  MultiSelectSeparator,
  MultiSelectTrigger,
  MultiSelectValue,
  // eslint-disable-next-line react-refresh/only-export-components
  renderMultiSelectOptions,
};
