import { Typography } from "@mui/material";
import {
  BooleanField,
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const UserBanShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Ban Information
        </Typography>
        <ReferenceField source="user_id" reference="users" label="User">
          <TextField source="username" />
        </ReferenceField>
        <BooleanField source="is_banned" />
        <TextField source="ban_reason" />
        <DateField source="banned_at" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserBanShow;
