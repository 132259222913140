/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrayField,
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  WithListContext,
} from "react-admin";
import { TranslationsField } from "../TranslationField";

export const ExerciseProgramShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Program Name">
            <TextField source="program_name" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Trainer">
            <ReferenceField source="created_by_id" reference="users">
              <TextField source="username" />
            </ReferenceField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Labeled label="Description">
            <TextField source="description" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Program Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Difficulty Level">
            <TextField source="difficulty_level" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Duration (weeks)">
            <NumberField source="duration_weeks" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Workouts Per Week">
            <NumberField source="workouts_per_week" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Target Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Target Muscles">
            <ArrayField source="target_muscles">
              <WithListContext
                render={({ data }) => (
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                    {data?.map((item: any) => (
                      <Chip key={item} label={item} size="small" />
                    ))}
                  </Box>
                )}
              />
            </ArrayField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Equipment Required">
            <ArrayField source="equipment_needed">
              <WithListContext
                render={({ data }) => (
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                    {data?.map((item: any) => (
                      <Chip key={item} label={item} size="small" />
                    ))}
                  </Box>
                )}
              />
            </ArrayField>
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Program Goals
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Primary Goals">
            <ArrayField source="program_goals.primary_goals">
              <WithListContext
                render={({ data }) => (
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                    {data?.map((item: any) => (
                      <Chip key={item} label={item} size="small" />
                    ))}
                  </Box>
                )}
              />
            </ArrayField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Secondary Goals">
            <ArrayField source="program_goals.secondary_goals">
              <WithListContext
                render={({ data }) => (
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                    {data?.map((item: any) => (
                      <Chip key={item} label={item} size="small" />
                    ))}
                  </Box>
                )}
              />
            </ArrayField>
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Additional Information
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Estimated Calories Burned">
            <NumberField source="estimated_calories_burned" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Average Rating">
            <NumberField source="average_rating" />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Target Audience">
            <ArrayField source="target_audience">
              <WithListContext
                render={({ data }) => (
                  <Box display="flex" gap={1} flexWrap="wrap">
                    {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                    {data?.map((item: any) => (
                      <Chip key={item} label={item} size="small" />
                    ))}
                  </Box>
                )}
              />
            </ArrayField>
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Labeled label="Prerequisites">
            <TextField source="prerequisites" />
          </Labeled>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Translations
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <TranslationsField label="Translations" />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Timestamps
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Created At">
            <DateField source="created_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Updated At">
            <DateField source="updated_at" showTime />
          </Labeled>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Labeled label="Deleted At">
            <DateField source="deleted_at" showTime />
          </Labeled>
        </Grid>
      </Grid>
    </SimpleShowLayout>
  </Show>
);

export default ExerciseProgramShow;
