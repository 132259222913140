import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Edit, SimpleForm, TextInput, required } from "react-admin";

export const EditRole = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Role Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextInput
              source="name"
              validate={[required()]}
              label="Role Name"
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <TextInput
              source="description"
              validate={[required()]}
              label="Description"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditRole;
