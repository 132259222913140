import { DatagridDangerouslyDeleteButton } from "@/components/Buttons/Admin/DangerouslyDeleteButton";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const UserBanFilters = [
  <TextInput key="user_id" source="user_id" label="User ID" alwaysOn />,
  <TextInput key="ban_reason" source="ban_reason" label="Ban Reason" />,
  <DateInput key="banned_at" source="banned_at" label="Banned At" />,
  <DateInput key="created_at" source="created_at" label="Created At" />,
  <DateInput key="updated_at" source="updated_at" label="Updated At" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const UserBanList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "banned_at", order: "DESC" }}
    filters={UserBanFilters}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="users" label="User">
        <TextField source="username" />
      </ReferenceField>
      <BooleanField source="is_banned" />
      <TextField source="ban_reason" />
      <DateField source="banned_at" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <EditButton />
      <DeleteButton />
      <DatagridDangerouslyDeleteButton resource="user_bans" />
    </Datagrid>
  </List>
);

export default UserBanList;
