import countries from "@/helper/static/Country/filtered_countries.json";
import { Typography, Box, Chip, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useState } from "react";

// Transform countries into the correct format
const countryChoices = countries.map((country) => ({
  id: country.name, // Use the country name as the id
  name: country.name, // Use the country name as the display value
}));

export const EditFood = () => {
  const [translations, setTranslations] = useState<
    { lang: string; text: string }[]
  >([]);
  const [newLang, setNewLang] = useState("");
  const [newText, setNewText] = useState("");

  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextInput
              source="name"
              validate={[required()]}
              label="Food Name"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <ReferenceInput
              source="brand_id"
              reference="brands"
              label="Brand ID"
              fullWidth
            >
              <SelectInput source="name" label="Brand Name" />
            </ReferenceInput>
          </Grid>
          <Grid size={12}>
            <ReferenceInput
              source="food_type_id"
              reference="food_types"
              label="Food Type ID"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Nutritional Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <NumberInput
              source="calories"
              validate={[required()]}
              label="Calories"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="fats"
              validate={[required()]}
              label="Total Fats (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="saturated_fats"
              validate={[required()]}
              label="Saturated Fats (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="fiber"
              validate={[required()]}
              label="Fiber (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="sugar"
              validate={[required()]}
              label="Sugar (g)"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <NumberInput
              source="sodium"
              validate={[required()]}
              label="Sodium (mg)"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Additional Details
        </Typography>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextInput
              source="serving_size"
              validate={[required()]}
              label="Serving Size"
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <SelectInput
              source="country"
              validate={[required()]}
              choices={countryChoices}
              label="Country of Origin"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Box display="flex" gap={2} alignItems="center" sx={{ mb: 2 }}>
              <TextInput
                source="newLang"
                label="Language Code"
                onChange={(e) => setNewLang(e.target.value)}
                value={newLang}
                sx={{ width: 120 }}
              />
              <TextInput
                source="newText"
                label="Translation"
                onChange={(e) => setNewText(e.target.value)}
                value={newText}
                fullWidth
              />
              <IconButton
                onClick={() => {
                  if (newLang && newText) {
                    setTranslations([
                      ...translations,
                      { lang: newLang, text: newText },
                    ]);
                    setNewLang("");
                    setNewText("");
                  }
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Box display="flex" gap={1} flexWrap="wrap">
              {translations.map((trans) => (
                <Chip
                  key={`${trans.lang}-${trans.text}`}
                  label={`${trans.lang}: ${trans.text}`}
                  onDelete={() => {
                    const newTranslations = translations.filter(
                      (t) => t.lang !== trans.lang || t.text !== trans.text,
                    );
                    setTranslations(newTranslations);
                  }}
                  deleteIcon={<DeleteIcon />}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditFood;
