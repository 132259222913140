import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/helper/lib/utils";
import { useTheme } from "@/helper/store/themeStore";

export function SettingsThemeSwitcher() {
  const { theme, setTheme } = useTheme();

  return (
    <RadioGroup
      defaultValue={theme}
      onValueChange={(value) => setTheme(value as "light" | "dark" | "system")}
      className="flex justify-between md:gap-4"
    >
      <div className="w-full">
        <RadioGroupItem value="light" id="theme-light" className="sr-only" />
        <Label
          htmlFor="theme-light"
          className={cn(
            "block cursor-pointer rounded-lg border-2 p-4",
            theme === "light" && "border-primary",
          )}
        >
          <div className="flex h-28 flex-col justify-start gap-2 rounded-md border-2 border-muted bg-white p-4">
            <div className="h-2 w-[90%] rounded bg-neutral-300" />
            <div className="h-2 w-[60%] rounded bg-neutral-300" />
            <div className="mt-2 flex items-center gap-2">
              <div className="h-4 w-4 rounded-full bg-neutral-300" />
              <div className="h-2 w-[40%] rounded bg-neutral-300" />
            </div>
          </div>
          <p className="mt-2 text-center text-sm font-medium">Light</p>
        </Label>
      </div>

      <div className="w-full">
        <RadioGroupItem value="dark" id="theme-dark" className="sr-only" />
        <Label
          htmlFor="theme-dark"
          className={cn(
            "block cursor-pointer rounded-lg border-2 p-4",
            theme === "dark" && "border-primary",
          )}
        >
          <div className="flex h-28 flex-col justify-start gap-2 rounded-md border-2 border-muted bg-neutral-800 p-4">
            <div className="h-2 w-[90%] rounded bg-neutral-500" />
            <div className="h-2 w-[60%] rounded bg-neutral-500" />
            <div className="mt-2 flex items-center gap-2">
              <div className="h-4 w-4 rounded-full bg-neutral-500" />
              <div className="h-2 w-[40%] rounded bg-neutral-500" />
            </div>
          </div>
          <p className="mt-2 text-center text-sm font-medium">Dark</p>
        </Label>
      </div>
      <div className="w-full">
        <RadioGroupItem value="system" id="theme-system" className="sr-only" />
        <Label
          htmlFor="theme-system"
          className={cn(
            "block cursor-pointer rounded-lg border-2 p-4",
            theme === "system" && "border-primary",
          )}
        >
          <div className="flex h-28 flex-col justify-start gap-2 rounded-md border-2 border-muted bg-muted p-4">
            <div className="h-2 w-[90%] rounded bg-foreground/25" />
            <div className="h-2 w-[60%] rounded bg-foreground/25" />
            <div className="mt-2 flex items-center gap-2">
              <div className="h-4 w-4 rounded-full bg-foreground/25" />
              <div className="h-2 w-[40%] rounded bg-foreground/25" />
            </div>
          </div>
          <p className="mt-2 text-center text-sm font-medium">System</p>
        </Label>
      </div>
    </RadioGroup>
  );
}
