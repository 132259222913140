import { Step, type StepItem, Stepper } from "@/components/ui/stepper";
import { toast } from "@/components/ui/use-toast";
import { useBoardingStore } from "@/helper/store/boardingStore";
import { Star } from "lucide-react";

const steps = [
  { label: "Step 1", description: "App Customization & Settings" },
  { label: "Step 2", description: "Personal and Health Information" },
  { label: "Step 3", description: "Lifestyle and Preferences" },
  { label: "Step 4", description: "Goals and Activity Level" },
  { label: "Review", icon: Star, description: "Review and Submit" },
] satisfies StepItem[];

export function OnboardingStepperComponent({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    stepNumber,
    setStepNumber,
    MultiStepForm,
    reviewMode,
    setReviewMode,
    stepsValidity,
  } = useBoardingStore();

  const handleStepClick = (step: number, setStep: (step: number) => void) => {
    // Allow moving to previous steps without restriction
    if (step <= stepNumber) {
      setStepNumber(step);
      setStep(step);
      // Handle review mode transition
      if (reviewMode && step < MultiStepForm.length - 1) {
        setReviewMode(false);
      }
      return;
    }

    // Allow moving to the next step if it's valid
    if (step > stepNumber && stepsValidity[stepNumber]) {
      setStepNumber(step);
      setStep(step);

      // Handle review mode transition
      if (step === MultiStepForm.length - 2) {
        setReviewMode(true);
      }
      return;
    }

    //adding this to fix immidiate next button click
    if (step !== 0) {
      // If trying to move to a future step that isn't valid
      toast({
        variant: "destructive",
        title: "Oops! You can't proceed.",
        description: "Please complete the current step before proceeding.",
      });
    }
  };
  return (
    <div className="flex w-full flex-col gap-4">
      <Stepper
        initialStep={0}
        steps={steps}
        onClickStep={(step, setStep) => {
          handleStepClick(step, setStep);
        }}
      >
        {steps.map((stepProps) => {
          return (
            <Step key={stepProps.label} {...stepProps}>
              {/* <VisuallyHidden /> */}
              {children}
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
