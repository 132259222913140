import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Create, SimpleForm, TextInput, required } from "react-admin";

export const CreateHealthCondition = () => {
  return (
    <Create>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Health Condition Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={12}>
            <TextInput
              source="condition_name"
              validate={[required()]}
              label="Condition Name"
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <TextInput
              source="description"
              validate={[required()]}
              label="Description"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateHealthCondition;
