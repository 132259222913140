import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const BrandCategoryFilters = [
  <TextInput
    key="category_name"
    source="category_name"
    label="Search by name"
    alwaysOn
  />,
  <TextInput
    key="brand_category_id"
    source="brand_category_id"
    label="Category ID"
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const BrandCategoryList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "category_name", order: "ASC" }}
    filters={BrandCategoryFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="category_name" label="Name" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default BrandCategoryList;
