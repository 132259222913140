import { Input, type InputProps } from "@/components/ui/input";
import { cn } from "@/helper/lib/utils";
import { useField } from "formik";
import type { ForwardedRef } from "react";

interface InputFieldProps extends InputProps {
  Name: string;
  Placeholder: string;
  InputType?: "text" | "number" | "email" | "password";
  FieldId: string;
  classes?: string;
  required?: boolean;
  ref?: ForwardedRef<HTMLInputElement>;
}

export function InputComponent({
  Name: FieldName,
  Placeholder,
  InputType = "text",
  FieldId,
  classes,
  required,
  className,
  ...props
}: InputFieldProps) {
  const [field, meta, helpers] = useField<string | number>(FieldName);
  const handleChange = (e: { target: { value: string | number } }) => {
    const { value } = e.target;
    let Value = value;

    if (InputType === "number") {
      // If the input value is just "0", clear it when typing starts
      if (value === "0") {
        Value = "";
      } else if (typeof value === "string") {
        // Check if the value starts with "0" but isn't just "0", and remove the leading "0"
        Value =
          value.startsWith("0") && value.length > 1 ? value.slice(1) : value;
      }

      // If the input is cleared (empty), reset the value to "0"
      if (value === "") {
        Value = "0";
      }

      helpers.setValue(Value);
    } else {
      helpers.setValue(value);
    }
  };

  return (
    <Input
      {...field}
      onChange={handleChange}
      id={FieldId}
      name={FieldName}
      placeholder={Placeholder}
      type={InputType}
      required={required}
      className={cn(
        meta.error &&
          meta.touched &&
          "border-destructive placeholder:text-red-500",
        classes,
        className,
      )}
      {...props}
    />
  );
}
