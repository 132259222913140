import { Typography } from "@mui/material";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

export const AllergenShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Allergen Information
        </Typography>
        <TextField source="id" />
        <TextField source="allergen_name" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default AllergenShow;
