import { Switch } from "@/components/ui/switch";
import { useField } from "formik";

export default function SwitchComponent({
  Name,
  disabled,
}: {
  Name: string;
  disabled?: boolean;
}) {
  // Use the Formik field hook to get the field and meta-information
  const [field, , helpers] = useField(Name);

  return (
    <Switch
      checked={field.value}
      onCheckedChange={(value) => helpers.setValue(value)}
      disabled={disabled ?? false}
    />
  );
}
