import FormButton from "@/components/Forms/Buttons/FormButton";
import { useToast } from "@/components/ui/use-toast";
import { formattedTimeForGo } from "@/helper/functions/date";
import { fetchData } from "@/helper/functions/fetch";
import { useFormStore } from "@/helper/store/formStore";
import { ToastAction } from "@radix-ui/react-toast";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { Form, Formik } from "formik";
import type { FC, ReactNode } from "react";
import type * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { InputComponent } from "../Inputs/InputComponent";
import { InputError } from "../Inputs/InputError";
import { InputLabelComponent } from "../Inputs/InputLabel";

export interface EditDialogProp {
  Endpoint: string;
  DefaultValue: number | string | Record<string, number | string | Date>;
  FieldLabel: string;
  FieldName: string | string[];
  Placeholder: string;
  ValidationSchema: Yup.Schema<unknown>;
  OnSuccess: () => void;
  Title: string;
  Description: string;
  BodyKey: string | string[];
  children?: ReactNode;
}

export const EditDialog: FC<EditDialogProp> = ({
  DefaultValue,
  Endpoint,
  FieldLabel,
  FieldName,
  OnSuccess,
  Placeholder,
  ValidationSchema,
  Description,
  Title,
  BodyKey,
  children,
}) => {
  const { editModal, setEditModal } = useFormStore();
  return (
    <Dialog open={editModal} onOpenChange={setEditModal}>
      <VisuallyHidden.Root>
        <DialogTrigger>Edit</DialogTrigger>
      </VisuallyHidden.Root>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{Title}</DialogTitle>
          <DialogDescription>{Description}</DialogDescription>
        </DialogHeader>
        <EditDialogForm
          DefaultValue={DefaultValue}
          Endpoint={Endpoint}
          FieldLabel={FieldLabel}
          FieldName={FieldName}
          OnSuccess={OnSuccess}
          Placeholder={Placeholder}
          ValidationSchema={ValidationSchema}
          BodyKey={BodyKey}
          {...(children && { children })}
        />
      </DialogContent>
    </Dialog>
  );
};

interface EditModalFormProps {
  DefaultValue: number | string | Record<string, number | string | Date>;
  OnSuccess: () => void;
  ValidationSchema: Yup.Schema<unknown>;
  FieldName: string | string[]; // Allows for multiple fields
  FieldLabel: string;
  Placeholder: string; // Single placeholder, as it's only used for default fields
  Endpoint: string;
  BodyKey: string | string[]; // Allows for multiple keys
  children?: ReactNode;
}

const EditDialogForm: FC<EditModalFormProps> = ({
  OnSuccess,
  ValidationSchema,
  DefaultValue,
  FieldName,
  FieldLabel,
  Placeholder,
  Endpoint,
  BodyKey,
  children,
}) => {
  const { toast } = useToast();
  const { setEditModal } = useFormStore();

  // Create initial values based on whether FieldName is a string or array
  const initialValues = Array.isArray(FieldName)
    ? FieldName.reduce(
        (acc, field) => {
          acc[field] = (DefaultValue as Record<string, number | string>)[field];
          return acc;
        },
        {} as Record<string, number | string>,
      )
    : { [FieldName]: DefaultValue };

  const updateValue = async (
    fields: Record<string, number | string | Date>,
  ) => {
    const payload = Array.isArray(BodyKey)
      ? BodyKey.reduce((acc: Record<string, number | string>, key, index) => {
          const field = fields[FieldName[index]];
          acc[key] = field instanceof Date ? formattedTimeForGo(field) : field;
          return acc;
        }, {})
      : {
          [BodyKey]:
            fields[FieldName as string] instanceof Date
              ? formattedTimeForGo(fields[FieldName as string] as Date)
              : fields[FieldName as string],
        };

    try {
      const data = await fetchData(Endpoint, "PUT", payload, false);

      if (data.ok) {
        toast({
          title: `${FieldLabel} updated`,
          description: `You have updated the ${FieldLabel.toLowerCase()}.`,
        });
        setTimeout(() => OnSuccess(), 1300);
        setEditModal(false);
      } else {
        toast({
          title: `Failed to update ${FieldLabel.toLowerCase()}`,
          description: data.message,
          variant: "destructive",
          action: <ToastAction altText="Try again">Try again</ToastAction>,
        });
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: `There was a problem with your request. Error:${error}`,
        action: <ToastAction altText="Try again">Try again</ToastAction>,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          await updateValue(values as Record<string, number | string>);
        } catch (error) {
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: `There was a problem with your request. Error:${error}`,
            action: <ToastAction altText="Try again">Try again</ToastAction>,
          });
        }
        setSubmitting(false);
      }}
      validationSchema={ValidationSchema}
    >
      <Form className="w-full">
        <div className="grid gap-4 py-4">
          {children ? (
            children
          ) : (
            <DefautEditForm
              DefaultValue={DefaultValue}
              FieldLabel={FieldLabel}
              FieldName={FieldName as string}
              Placeholder={Placeholder}
            />
          )}
        </div>
        <div className="flex items-center justify-between">
          <FormButton Text="Save changes" />
        </div>
      </Form>
    </Formik>
  );
};

const DefautEditForm = ({
  FieldLabel,
  DefaultValue,
  Placeholder,
  FieldName,
}: {
  FieldName: string;
  FieldLabel: string;
  DefaultValue: number | string | Record<string, number | string | Date>;
  Placeholder: string;
}) => {
  return (
    <div className="grid grid-cols-4 items-center gap-4">
      <InputLabelComponent
        For={FieldName}
        LabelText={FieldLabel}
        FieldName={FieldName}
      />
      <div className="col-span-3">
        <InputComponent
          FieldId={FieldName}
          Name={FieldName}
          InputType={typeof DefaultValue === "number" ? "number" : "text"}
          Placeholder={Placeholder}
        />
        <InputError Name={FieldName} />
      </div>
    </div>
  );
};
