import Combobox from "@/components/ui/Combobox";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { useExerciseStore } from "@/helper/store/exerciseStore";
import { SquarePenIcon, Trash2Icon } from "lucide-react";
import {
	type ChangeEvent,
	type InputHTMLAttributes,
	type ReactNode,
	useCallback,
	useEffect,
} from "react";
import { DaysArray } from "./ExerciseStaticValues";

type ExerciseFormValues = {
	UUID: string;
	name: string;
	setCount: number;
	isCompleted: boolean; // Bu satırı ekleyin
	totalSets: number;
	dayOfWeek: number;
};

export function ExerciseHeader() {
	return (
		<TableHeader>
			<TableRow>
				<TableHead className="w-[100px]">Completed?</TableHead>
				<TableHead className="text-center">Exercise Name</TableHead>
				<TableHead className="text-center">Set Count</TableHead>
				<TableHead className="text-center">Total Sets</TableHead>
				<TableHead className="text-center">Activity Day</TableHead>
				<TableHead className="text-center">Remove Exercise</TableHead>
				<TableHead className="text-right">Update Exercise</TableHead>
			</TableRow>
		</TableHeader>
	);
}

export function ExerciseListItem({
	exercise,
}: {
	exercise: ExerciseFormValues;
}) {
	const { removeExercise, toggleExerciseCompletion } = useExerciseStore();
	// CheckBox için event handler
	const handleCheckboxChange = () => {
		toggleExerciseCompletion(exercise);
	};

	return (
		<TableBody>
			<TableRow className="text-center">
				<TableCell>
					<Checkbox
						className="h-6 w-6 rounded border-primary bg-secondary text-primary focus:ring-primary"
						checked={exercise.isCompleted}
						onClick={handleCheckboxChange}
					/>
				</TableCell>
				<TableCell>{exercise.name}</TableCell>
				<TableCell>{exercise.setCount}</TableCell>
				<TableCell>{exercise.totalSets}</TableCell>
				<TableCell>{DaysArray[exercise.dayOfWeek - 1]?.label}</TableCell>
				<TableCell className="text-center">
					<Button
						type="button"
						variant="destructive"
						onClick={() => removeExercise(exercise)}
					>
						<Trash2Icon />
					</Button>
				</TableCell>
				<TableCell className="text-right">
					<UpdateExerciseModal exercise={exercise} />
				</TableCell>
			</TableRow>
		</TableBody>
	);
}

export function CreateExercise() {
	return (
		<TableBody>
			<TableRow className="text-center hover:bg-background">
				<TableCell>
					<Checkbox
						className="h-6 w-6 rounded border-2 border-red-700 bg-red-500 text-red-500 focus:ring-primary"
						disabled
					/>
				</TableCell>
				<TableCell>
					<ExerciseInput name="name" placeholder="Exercise name" />
				</TableCell>
				<TableCell>
					<ExerciseInput
						name="setCount"
						type="number"
						placeholder="Set count"
					/>
				</TableCell>
				<TableCell>
					<ExerciseInput
						name="totalSets"
						type="number"
						placeholder="Total sets"
					/>
				</TableCell>
				<TableCell>
					<ExerciseInput name="dayOfWeek" />
				</TableCell>
				<TableCell className="text-center">
					<Button type="submit">Add</Button>
				</TableCell>
			</TableRow>
		</TableBody>
	);
}

interface ExerciseInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: keyof ExerciseFormValues; // 'Exercise' türündeki anahtarlar (name, setCount, totalSets, vb.)
	// Change?: (value: string) => void;
}

function ExerciseInput({ name, ...props }: ExerciseInputProps) {
	const { formData, setFormData } = useExerciseStore();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		let Value = value;

		if (props.type === "number") {
			// This removes leading zeros by converting to a number and back to a string
			Value = String(Number(value));
		}
		return setFormData(name, Value); // Güncelleme işlemi
	};
	if (name === "dayOfWeek") {
		return (
			<Combobox
				Options={DaysArray}
				DefaultLabel="Aktivite Gününü Seçiniz"
				EmptyText="Aktivite Günü bulunamadı."
				PlaceholderText="Aktivite Günü arayın..."
				onChange={(value) => setFormData(name, value)}
				formValue={formData[name]}
			/>
		);
	}
	return (
		<Input
			{...props} // Tüm kalan props'lar burada geçiriliyor
			name={name}
			value={formData[name] as string} // formData'dan değeri alırken casting yapılması
			onChange={handleChange}
			type={props.type || "text"}
		/>
	);
}

function UpdateExerciseModal({ exercise }: { exercise: ExerciseFormValues }) {
	const { updateExercise, toggleExerciseCompletion, setFormData } =
		useExerciseStore();

	// Handle the completion toggle directly via Zustand
	const handleToggleCompletion = () => {
		toggleExerciseCompletion(exercise);
	};

	// Update the exercise on form submission
	const handleUpdateExercise = () => {
		updateExercise(exercise);
	};

	// Initialize the modal with the exercise data
	const handleInitData = useCallback(() => {
		setFormData("name", exercise.name);
		setFormData("setCount", exercise.setCount);
		setFormData("totalSets", exercise.totalSets);
		setFormData("dayOfWeek", exercise.dayOfWeek);
		setFormData("isCompleted", exercise.isCompleted);
	}, [exercise, setFormData]);

	useEffect(() => {
		handleInitData();
	}, [handleInitData]);

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button type="button">
					<SquarePenIcon />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Edit Exercise</DialogTitle>
					<DialogDescription>
						Make changes to your exercise here. Click save when you're done.
					</DialogDescription>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					<ModalItemWrapper LabelText="Completed?" LabelID="isCompleted">
						<Checkbox
							name="isCompleted"
							className="col-span-3 h-6 w-6 rounded border-primary bg-secondary text-primary focus:ring-primary"
							checked={exercise.isCompleted}
							onClick={handleToggleCompletion}
						/>
					</ModalItemWrapper>
					<ModalItemWrapper LabelText="Exercise Name" LabelID="name">
						<ExerciseInput
							name="name"
							type="text"
							placeholder="Exercise name"
							className="col-span-3"
						/>
					</ModalItemWrapper>
					<ModalItemWrapper LabelText="Set Count" LabelID="setCount">
						<ExerciseInput
							name="setCount"
							type="number"
							placeholder="Set count"
							className="col-span-3"
						/>
					</ModalItemWrapper>
					<ModalItemWrapper LabelText="Total Sets" LabelID="totalSets">
						<ExerciseInput
							name="totalSets"
							type="number"
							placeholder="Total sets"
							className="col-span-3"
						/>
					</ModalItemWrapper>
					<ModalItemWrapper LabelText="Activity Day" LabelID="dayOfWeek">
						{/* TODO buraya ileride dikkat et değeri düzgün alıyor mu diye */}
						<ExerciseInput
							name="dayOfWeek"
							placeholder="Activity Day"
							// Assuming you have a component that handles select/input for days
						/>
					</ModalItemWrapper>
				</div>
				<DialogFooter>
					<Button type="button" onClick={handleUpdateExercise}>
						Save changes
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

function ModalItemWrapper({
	children,
	LabelID,
	LabelText,
}: { children: ReactNode; LabelID: string; LabelText: string }) {
	return (
		<div className="grid grid-cols-4 items-center gap-4">
			<Label htmlFor={LabelID} className="text-right">
				{LabelText}
			</Label>
			{children}
		</div>
	);
}
