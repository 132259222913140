import type { Navigation } from "@/helper/types/Navigation";
import {
  CalendarDays,
  DumbbellIcon,
  GlassWater,
  HomeIcon,
  PieChartIcon,
  Soup,
  UtensilsIcon,
} from "lucide-react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// todo switch to navlink

// Navigation items with dynamic current property based on route, using window.location.pathname
const useNavigation = (): Navigation[] => {
  const location = useLocation();
  const pathname = location.pathname;
  return useMemo(
    () => [
      {
        groupLabel: "",
        subPaths: [
          {
            href: "/",
            label: "Home",
            current: pathname === "/",
            icon: HomeIcon,
            submenus: [],
          },
        ],
      },
      {
        groupLabel: "Food and Meal",
        subPaths: [
          {
            href: "/meal",
            label: "Meal",
            current: pathname === "/meal",
            icon: Soup,
            submenus: [
              {
                href: "/meal",
                name: "Add Meal",
                current: pathname === "/meal",
              },
              {
                href: "/meal/list",
                name: "List Meals",
                current: pathname === "/meal/list",
              },
            ],
          },
          {
            href: "",
            label: "Food",
            current: pathname === "/food",
            icon: UtensilsIcon,
            submenus: [
              {
                href: "/food",
                name: "Add Food",
                current: pathname === "/food",
              },
              {
                href: "/food/list",
                name: "List user foods",
                current: pathname === "/food/list",
              },
              {
                href: "/food/list/all",
                name: "List all foods",
                current: pathname === "/food/list/all",
              },
            ],
          },
          {
            href: "/water",
            label: "Water",
            current: pathname === "/water",
            icon: GlassWater,
            submenus: [],
          },
          {
            href: "/exercise",
            label: "Exercise",
            current: pathname === "/exercise",
            icon: DumbbellIcon,
            submenus: [],
          },
        ],
      },
      {
        groupLabel: "Calendar and Reports",
        subPaths: [
          {
            href: "/calendar",
            label: "Calendar",
            current: pathname === "/calendar",
            icon: CalendarDays,
            submenus: [],
          },
          {
            href: "/reports",
            label: "Reports",
            current: pathname === "/reports",
            icon: PieChartIcon,
            submenus: [],
          },
        ],
      },
    ],
    [pathname], // Depend on pathname to recompute the navigation items when it changes
  );
};

export default useNavigation;
