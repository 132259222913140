import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const UserSubscriptionFilters = [
  <TextInput key="id" source="id" label="Subscription ID" alwaysOn />,
  <TextInput key="user_id" source="user_id" label="User ID" />,
  <TextInput key="plan_id" source="plan_id" label="Plan ID" />,
  <TextInput key="status" source="status" label="Status" />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const UserSubscriptionList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
    filters={UserSubscriptionFilters}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <ReferenceField source="user_id" reference="users" label="User">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField
        source="plan_id"
        reference="subscription_plans"
        label="Plan"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
      <TextField source="frequency" />
      <TextField source="payment_status" />
      <DateField source="subscription_start_date" />
      <DateField source="subscription_end_date" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export default UserSubscriptionList;
