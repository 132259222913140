import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CreateFoodHealthScore = () => (
  <Create>
    <SimpleForm>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="food_id" reference="foods">
            <SelectInput
              validate={[required()]}
              label="Food"
              optionText="name"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <ReferenceInput source="dietician_id" reference="dieticians">
            <SelectInput
              validate={[required()]}
              label="Dietician"
              optionText="bio"
              fullWidth
            />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Scoring Details
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <NumberInput
            source="overall_score"
            label="Overall Score"
            validate={[required()]}
            min={0}
            max={100}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <DateInput
            source="review_date"
            label="Review Date"
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="scoring_criteria.nutritional_balance"
            label="Nutritional Balance"
            validate={[required()]}
            min={0}
            max={100}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="scoring_criteria.ingredient_quality"
            label="Ingredient Quality"
            validate={[required()]}
            min={0}
            max={100}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="scoring_criteria.processing_level"
            label="Processing Level"
            validate={[required()]}
            min={0}
            max={100}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="scoring_criteria.additive_safety"
            label="Additive Safety"
            validate={[required()]}
            min={0}
            max={100}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <NumberInput
            source="scoring_criteria.environmental_impact"
            label="Environmental Impact"
            validate={[required()]}
            min={0}
            max={100}
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Review Information
      </Typography>
      <Grid container spacing={2} width="100%">
        <Grid size={{ xs: 12 }}>
          <TextInput
            source="notes"
            label="Notes"
            multiline
            rows={4}
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default CreateFoodHealthScore;
