/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DataProvider } from "ra-core";
import jsonServerProvider from "ra-data-json-server";

const apiUrl = "http://localhost:3001";
const baseDataProvider = jsonServerProvider(apiUrl);

type ResourceConfig = {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  id: string | ((item: any) => string);
};

const resourceMap: Record<string, ResourceConfig> = {
  foods: {
    id: "id",
  },
  food_types: {
    id: "id",
  },
  brands: {
    id: "id",
  },
  brand_categories: {
    id: "id",
  },
  brand_subcategories: {
    id: "id",
  },
  user_settings: {
    id: "id",
  },
  user_bans: {
    id: "user_id",
  },
  allergens: {
    id: "id",
  },
  health_conditions: {
    id: "id",
  },
  exercises: {
    id: "id",
  },
  exercise_programs: {
    id: "program_id",
  },
  exercise_programs_details: {
    id: "id",
  },
  trainers: {
    id: "user_id",
  },
  dieticians: {
    id: "user_id",
  },
  user_roles: {
    id: "id",
  },
  food_barcodes: {
    id: "id",
  },
  food_details: {
    id: "food_id", // Using food_id as the primary key
  },
  food_health_scores: {
    id: "id",
  },
  food_health_warnings: {
    id: "id",
  },
  food_verification: {
    id: "food_id", // Using food_id as the primary key, since we expect 1:1 relationship planned as each food item has only one verification record
  },
};

export const dataProvider: DataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    const response = await baseDataProvider.getList(resource, params);
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];

    if (resourceConfig) {
      return {
        ...response,
        data: response.data.map((item) => ({
          ...item,
          id:
            typeof resourceConfig.id === "function"
              ? resourceConfig.id(item)
              : item[resourceConfig.id] || item.id,
        })),
      };
    }

    return response;
  },

  getMany: async (resource, params) => {
    const response = await baseDataProvider.getMany(resource, params);
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];

    if (resourceConfig) {
      return {
        data: response.data.map((item) => ({
          ...item,
          id:
            typeof resourceConfig.id === "function"
              ? resourceConfig.id(item)
              : item[resourceConfig.id] || item.id,
        })),
      };
    }

    return response;
  },

  getManyReference: async (resource, params) => {
    const response = await baseDataProvider.getManyReference(resource, params);
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];

    if (resourceConfig) {
      return {
        ...response,
        data: response.data.map((item) => ({
          ...item,
          id:
            typeof resourceConfig.id === "function"
              ? resourceConfig.id(item)
              : item[resourceConfig.id] || item.id,
        })),
      };
    }

    return response;
  },

  getOne: async (resource, params) => {
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];

    if (resourceConfig) {
      const url = `${apiUrl}/${resource}/${params.id}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const item = await response.json();

      return {
        data: {
          ...item,
          id:
            typeof resourceConfig.id === "function"
              ? resourceConfig.id(item)
              : item[resourceConfig.id] || item.id,
        },
      };
    }

    return baseDataProvider.getOne(resource, params);
  },

  create: async (resource, params) => {
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];

    if (resourceConfig) {
      const { data } = params;
      const response = await baseDataProvider.create(resource, {
        data: {
          ...data,
          [resourceConfig.id as string]: crypto.randomUUID(),
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
      });

      return {
        data: {
          ...response.data,
          id:
            typeof resourceConfig.id === "function"
              ? resourceConfig.id(response.data)
              : response.data[resourceConfig.id] || response.data.id,
        },
      };
    }

    return baseDataProvider.create(resource, params);
  },

  update: async (resource, params) => {
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];

    if (resourceConfig) {
      const response = await baseDataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          [resourceConfig.id as string]: params.data.id,
          updated_at: new Date().toISOString(),
        },
      });

      return {
        data: {
          ...response.data,
          id:
            typeof resourceConfig.id === "function"
              ? resourceConfig.id(response.data)
              : response.data[resourceConfig.id] || response.data.id,
        },
      };
    }

    return baseDataProvider.update(resource, params);
  },

  delete: async (resource, params) => {
    if (params.meta?.endpoint === "dangerously-delete") {
      const url = `${baseDataProvider.apiUrl}/${resource}/${params.id}/dangerously-delete`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Dangerous delete failed");
      }

      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return { data: { id: params.id } as any };
    }

    // Handle soft delete for resources that support it
    const resourceConfig = resourceMap[resource as keyof typeof resourceMap];
    if (resourceConfig) {
      const response = await baseDataProvider.update(resource, {
        id: params.id,
        data: {
          deleted_at: new Date().toISOString(),
        },
        previousData: params.previousData,
      });

      return { data: response.data };
    }

    return baseDataProvider.delete(resource, params);
  },
};
