type LoaderFunction = (params: { src: string }) => string;

type ImageProps = {
	src: string;
	alt?: string;
	loader?: LoaderFunction;
	[key: string]: unknown; // or replace with a more specific type
};

const Image = ({ src, alt = "", loader, ...props }: ImageProps) => {
	// Modify the src using the loader function if provided, else use the original src
	const imageSrc = loader ? loader({ src }) : src;

	return <img {...props} src={imageSrc} alt={alt} />;
};

export default Image;
