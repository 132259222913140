import type { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const SettingsFormDivider: FC<Props> = ({ children }) => {
  return <div className="divide-y divide-white/5">{children}</div>;
};
export default SettingsFormDivider;
