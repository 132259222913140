import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";

import { buttonVariants } from "@/helper/lib/buttonVariants";
import { cn } from "@/helper/lib/utils";
import clsx from "clsx";
import { LoaderCircle } from "lucide-react";
import { type ButtonHTMLAttributes, forwardRef } from "react";

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  type?: "button" | "submit" | "reset";
  isDisabled?: boolean;
  isLoading?: boolean;
  isLoadingWithText?: boolean;
  block?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      type = "button",
      isDisabled = false,
      isLoading = false,
      isLoadingWithText = false,
      block = true,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const buttonContent = isLoading ? (
      <div className="flex items-center justify-center gap-x-2">
        <LoaderCircle className="animate-spin" />
        {isLoadingWithText && <span>Loading</span>}
      </div>
    ) : (
      props.children
    );

    const buttonElement = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        disabled={isDisabled || isLoading}
        {...props}
      >
        {buttonContent}
      </Comp>
    );

    if (isDisabled || isLoading) {
      return (
        <div
          className={clsx(block && "w-full", "cursor-not-allowed select-none")}
        >
          {buttonElement}
        </div>
      );
    }

    return buttonElement;
  },
);
Button.displayName = "Button";

export { Button };
