import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"; // import { Button } from "../ui/button";
import { useAuthStore } from "@/helper/store/authStore";
import { useUserStore } from "@/helper/store/userStore";
import { UserIcon } from "@heroicons/react/24/solid";
import {
  CreditCard,
  Keyboard,
  LifeBuoy,
  LogOut,
  PanelLeftIcon,
  Settings,
  User,
} from "lucide-react";
import type { ElementType } from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

const ProfileDropdown = () => {
  const { User: UserData } = useUserStore();
  const { role } = useAuthStore();
  const { first_name, last_name, profile_picture } = UserData;

  const isAdmin = role === "admin";

  const FallbackNameGenerator = () => {
    if (first_name && last_name) {
      return first_name[0] + last_name[0];
    }
    return <UserIcon className="h-4 w-4" />;
  };

  return (
    // TODO create a shortcut for the user
    //! TODO add a command component to this
    // Buraya geri dönüş yapılacak aschild ile çalışıyor mu diye
    <DropdownMenu>
      <DropdownMenuTrigger className="hover:cursor-pointer" asChild>
        <Avatar className="flex items-center">
          <AvatarImage
            className="h-8 w-8 rounded-full bg-gray-50"
            src={profile_picture}
          />
          <AvatarFallback className="h-8 w-8 select-none">
            {FallbackNameGenerator()}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <ProfileDropdownLink
            href="/profile"
            Icon={User}
            Label="Profile"
            Shortcut="⇧⌘P"
          />
          <ProfileDropdownLink
            href="/billing"
            Icon={CreditCard}
            Label="Billing"
            Shortcut="⌘B"
          />
          <ProfileDropdownLink
            href="/settings"
            Icon={Settings}
            Label="Settings"
            Shortcut="⌘S"
          />
          {/* TODO add a command component to this */}
          <DropdownMenuItem>
            <Keyboard className="mr-2 h-4 w-4" />
            <span>Keyboard shortcuts</span>
            <DropdownMenuShortcut>⌘K</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />

        {isAdmin && (
          <Link to="/admin">
            <DropdownMenuItem>
              <>
                <PanelLeftIcon className="mr-2 h-4 w-4" />
                <span>Admin Panel</span>
              </>
            </DropdownMenuItem>
          </Link>
        )}

        <DropdownMenuItem>
          <LifeBuoy className="mr-2 h-4 w-4" />
          <span>Support</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <LogoutButton>
          <DropdownMenuItem>
            <LogOut className="mr-2 h-4 w-4" />
            <span>Log out</span>
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem>
        </LogoutButton>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default ProfileDropdown;

type ProfileDropdownLinkProps = {
  Icon: ElementType;
  href: string;
  Label: string;
  Shortcut: string;
  disable?: boolean;
};

const ProfileDropdownLink = ({
  Icon,
  href,
  Label,
  Shortcut,
  disable = false,
}: ProfileDropdownLinkProps) => {
  return (
    <DropdownMenuItem asChild disabled={disable}>
      <Link to={href}>
        <Icon className="mr-2 h-4 w-4" />
        <span>{Label}</span>
        <DropdownMenuShortcut>{Shortcut}</DropdownMenuShortcut>
      </Link>
    </DropdownMenuItem>
  );
};
