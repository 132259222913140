/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrayField,
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  WithListContext,
} from "react-admin";
import { TranslationsField } from "../TranslationField";

export const ExerciseShow = () => {
  return (
    <Show>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Name">
              <TextField source="name" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Exercise Type">
              <TextField source="exercise_type" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Labeled label="Description">
              <TextField source="description" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Difficulty Level">
              <TextField source="difficulty_level" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Trainer">
              <ReferenceField source="trainer_id" reference="trainers">
                <TextField source="bio" />
              </ReferenceField>
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Exercise Details
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Recommended Reps">
              <NumberField source="recommended_reps" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Recommended Sets">
              <NumberField source="recommended_sets" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Rest Period (seconds)">
              <NumberField source="rest_period_in_seconds" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Calories Burned Per Rep">
              <NumberField source="calories_burned_per_rep" />
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Target Muscles & Equipment
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Target Muscles">
              <ArrayField source="target_muscles">
                <WithListContext
                  render={({ data }) => (
                    <Box display="flex" gap={1} flexWrap="wrap">
                      {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                      {data?.map((item: any) => (
                        <Chip key={item} label={item} size="small" />
                      ))}
                    </Box>
                  )}
                />
              </ArrayField>
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Equipment Needed">
              <ArrayField source="equipment_needed">
                <WithListContext
                  render={({ data }) => (
                    <Box display="flex" gap={1} flexWrap="wrap">
                      {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                      {data?.map((item: any) => (
                        <Chip key={item} label={item} size="small" />
                      ))}
                    </Box>
                  )}
                />
              </ArrayField>
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Media & Instructions
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Video URL">
              <TextField source="video_url" />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Labeled label="Form Tips">
              <ArrayField source="form_tips">
                <WithListContext
                  render={({ data }) => (
                    <Box display="flex" gap={1} flexWrap="wrap">
                      {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
                      {data?.map((item: any) => (
                        <Chip key={item} label={item} size="small" />
                      ))}
                    </Box>
                  )}
                />
              </ArrayField>
            </Labeled>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TranslationsField label="Translations" />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Timestamps
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Created At">
              <DateField source="created_at" showTime />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Updated At">
              <DateField source="updated_at" showTime />
            </Labeled>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Labeled label="Deleted At">
              <DateField source="deleted_at" showTime />
            </Labeled>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};

export default ExerciseShow;
