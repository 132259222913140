import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type ThemeState = "light" | "dark" | "system";

interface ThemeStore {
	theme: ThemeState;
	setTheme: (mode: ThemeState) => void;
}

export const useTheme = create(
	persist<ThemeStore>(
		(set) => ({
			theme: "system", // default value
			setTheme: (selectedTheme: ThemeState) => set({ theme: selectedTheme }),
		}),
		{
			name: "theme-storage", // a unique name
			storage: createJSONStorage(() => localStorage), // using 'localStorage'
		},
	),
);
