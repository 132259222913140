import { DestructedFormikField } from "@/components/Forms/Inputs/DestructedField";
import FormCheckBox from "@/components/Forms/Inputs/FormCheckBox";
import FormPasswordInput from "@/components/Forms/Inputs/FormPasswordInput";
import { InputComponent } from "@/components/Forms/Inputs/InputComponent";
import { InputError } from "@/components/Forms/Inputs/InputError";
import { InputLabelComponent } from "@/components/Forms/Inputs/InputLabel";
import { Label } from "@/components/ui/label";
import { Toaster } from "@/components/ui/toaster";
import { Check, OctagonAlert } from "lucide-react";
import type { FC } from "react";
import PasswordChecklist from "react-password-checklist";

const EmailInput = "email";
const PasswordInput = "password";
const ConfirmPasswordInput = "confirmPassword";
const TermsInput = "terms";

export default function RegisterPage() {
  return (
    <>
      <div>
        <InputLabelComponent
          LabelText="Email address"
          FieldName={EmailInput}
          For={EmailInput}
        />
        <div className="mt-2">
          <InputComponent
            Name={EmailInput}
            FieldId={EmailInput}
            InputType="email"
            Placeholder="Enter your email address"
            required
          />
        </div>
        <InputError Name={EmailInput} />
      </div>

      <div>
        <InputLabelComponent
          LabelText="Password"
          FieldName={PasswordInput}
          For={PasswordInput}
          tooltip
          Content={
            <DestructedFormikField FieldName={PasswordInput}>
              {({ field, form }) => (
                <PasswordChecklist
                  className="mt-1 grid grid-cols-2 gap-1 text-xs"
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={field.value}
                  valueAgain={form.values.confirmPassword}
                  iconComponents={{
                    ValidIcon: <Check size={18} className="text-green-500" />,
                    InvalidIcon: (
                      <OctagonAlert size={18} className="text-red-600" />
                    ),
                  }}
                />
              )}
            </DestructedFormikField>
          }
        />
        <div className="mt-2">
          <FormPasswordInput
            FieldName={PasswordInput}
            autoComplete="current-password"
            placeholder="Enter your password"
          />
        </div>
        <InputError Name={PasswordInput} />
      </div>

      <div>
        <InputLabelComponent
          LabelText="Confirm Password"
          FieldName={ConfirmPasswordInput}
          For={ConfirmPasswordInput}
        />
        <div className="mt-2">
          <FormPasswordInput
            FieldName={ConfirmPasswordInput}
            autoComplete="confirm-password"
            placeholder="Confirm your password"
          />
        </div>
        <InputError Name={ConfirmPasswordInput} />
      </div>

      <div className="flex flex-col items-start gap-1">
        <div className="items-top flex space-x-2">
          <FormCheckBox FieldName={TermsInput} />
          <div className="grid gap-1.5 leading-none">
            <Label
              htmlFor={TermsInput}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Accept terms and conditions
            </Label>
            <p className="text-sm text-muted-foreground">
              You agree to our{" "}
              <LinkToPolicies href="/tos" text="Terms of Service" /> and{" "}
              <LinkToPolicies href="/privacy" text="Privacy Policy" />.
            </p>
          </div>
        </div>
        <InputError Name={TermsInput} />
      </div>
      <Toaster />
    </>
  );
}

interface LinkProps {
  href: string;
  text: string;
}

const LinkToPolicies: FC<LinkProps> = ({ href, text }) => {
  return (
    <a href={href} className="text-primary underline-offset-2 hover:underline">
      {text}
    </a>
  );
};
