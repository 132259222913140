import { Typography } from "@mui/material";
import {
  BooleanField,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const UserShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          User Information
        </Typography>
        <TextField source="id" label="User ID" />
        <TextField source="email" label="Email" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <BooleanField source="is_admin" label="Is Admin" />
        <BooleanField source="is_active" label="Is Active" />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Last Updated" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
