import { Typography } from "@mui/material";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

export const BrandCategoryShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Brand Category Information
        </Typography>
        <TextField source="id" label="ID" />
        <TextField source="category_name" label="Name" />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Updated At" />
      </SimpleShowLayout>
    </Show>
  );
};

export default BrandCategoryShow;
