import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useFormStore } from "@/helper/store/formStore";
import { MoreHorizontal } from "lucide-react";

export const OptionDropdown = () => {
  const { setEditModal, setDeleteModal } = useFormStore();

  const editOnClickHandler = () => {
    setEditModal(true);
  };

  const deleteOnClickHandler = () => {
    setDeleteModal(true);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={editOnClickHandler}>Edit</DropdownMenuItem>
        <DropdownMenuItem onClick={deleteOnClickHandler}>
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
