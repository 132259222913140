import { Typography } from "@mui/material";
import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const SubscriptionPlanShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          Subscription Plan Information
        </Typography>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <NumberField
          source="price"
          label="Price"
          options={{ style: "currency", currency: "USD" }}
        />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Updated At" />
      </SimpleShowLayout>
    </Show>
  );
};

export default SubscriptionPlanShow;
