import { useAuthStore } from "@/helper/store/authStore";
import { useUserStore } from "@/helper/store/userStore";
import { getHours } from "date-fns";

function getGreeting() {
  const currentHour = getHours(new Date());

  if (currentHour < 12) {
    return "Günaydın";
  }
  if (currentHour < 18) {
    return "İyi günler";
  }
  if (currentHour < 22) {
    return "İyi akşamlar";
  }
  return "İyi geceler";
}

export default function HomeWelcomeElement() {
  const { user } = useAuthStore();
  const { User } = useUserStore();
  const { first_name } = User;
  const greeting = getGreeting();
  return (
    <h1 className="text-5xl font-semibold">
      {greeting} {first_name ?? user?.displayName}!
    </h1>
  );
}
