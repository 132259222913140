import type { User } from "firebase/auth";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface AuthStore {
  isRegistered: boolean;
  setIsRegistered: (auth: boolean) => void;
  UUID: string;
  setUUID: (uuid: string) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  role: string | null;
  subscription: string | null;
  setRole: (role: string | null) => void;
  setSubscription: (subscription: string | null) => void;
  resetAuth: () => void;
}

// TODO add banned user check and ban reason to the store
export const useAuthStore = create(
  persist<AuthStore>(
    (set) => ({
      isRegistered: false, // Varsayılan değer
      setIsRegistered: (auth: boolean) => set({ isRegistered: auth }),
      UUID: "", // Varsayılan değer
      setUUID: (uuid: string) => set({ UUID: uuid }),
      user: null,
      setUser: (user: User | null) => set({ user }),
      role: null,
      subscription: null,
      setRole: (role: string | null) => set({ role }),
      setSubscription: (subscription: string | null) => set({ subscription }),
      resetAuth: () =>
        set({
          isRegistered: false,
          UUID: "",
          user: null,
          role: null,
          subscription: null,
        }),
    }),
    {
      name: "auth-storage", // Benzersiz bir isim
      storage: createJSONStorage(() => localStorage), // Daha önce kayıt olup olmadığının bilgilerini 'localStorage' kullanarak saklar
    },
  ),
);
