import DailyCalorieCard from "@/components/Home/DailyCalorieCard";
import DailyCalorieChart from "@/components/Home/DailyCalorieChart";
import WaterGlass from "@/components/Home/WaterCard/WaterCard";
import HomeWelcomeElement from "@/components/Home/WelcomeElement";
import { useAuthStore } from "@/helper/store/authStore";
import type { HealthMetrics } from "@/helper/types/UserTypes";
import { useQuery } from "@tanstack/react-query";
// import { useUserStore } from "@/helper/store/userStore";
// import { PageLoader } from "@/components/LoadingComponent";

function HomePage() {
  const { UUID } = useAuthStore();

  // Access the cached data using useQuery
  useQuery({
    queryKey: ["userData", UUID],
    // No queryFn needed since data is already cached
    staleTime: Number.POSITIVE_INFINITY, // Optional: prevent refetching
  });

  const { data: healthMetrics } = useQuery<HealthMetrics>({
    queryKey: ["user-health-metrics", UUID],
    staleTime: Number.POSITIVE_INFINITY,
  });
  return (
    <>
      <HomeWelcomeElement />
      <div className="container mx-auto">
        <div className="flex h-full flex-wrap gap-4 pt-4">
          <WaterGlass
            dailyWaterGoal={healthMetrics?.recommended_daily_water_intake ?? 0}
            waterConsumed={1500}
          />
          <div className="flex-none">
            <DailyCalorieChart />
          </div>

          <DailyCalorieCard />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <h2 className="text-2xl font-bold">Daily calorie</h2>
            {healthMetrics?.tdee}
          </div>
          <div>
            <h2 className="text-2xl font-bold">Daily water</h2>
            {healthMetrics?.recommended_daily_water_intake}
          </div>
          <div>
            <h2 className="text-2xl font-bold">BMI</h2>
            {healthMetrics?.bmi}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
