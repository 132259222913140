import Social from "@/components/Forms/Social";
import Logo from "@/components/Logo";
import { Button } from "@/components/ui/button";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import type { FormikHelpers, FormikValues } from "formik";

import type React from "react";
import FormButton from "./Forms/Buttons/FormButton";

interface Props {
  children: React.ReactNode;
  initialValues: object;
  validationSchema: object;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => Promise<void>;
  registerOrLogin: "register" | "login";
}

const LoginRegisterWrapper: React.FC<Props> = ({
  children,
  initialValues,
  onSubmit,
  validationSchema,
  registerOrLogin,
}) => {
  // const imageArray = useMemo(
  //   () => [
  //     "photo-1477332552946-cfb384aeaf1c",
  //     "photo-1490645935967-10de6ba17061",
  //     "photo-1498837167922-ddd27525d352",
  //   ],
  //   [],
  // );

  const isLogin = registerOrLogin === "login";
  const Image = isLogin
    ? "photo-1490645935967-10de6ba17061"
    : "photo-1477332552946-cfb384aeaf1c";
  // const [Image, setImage] = useState(imageArray[0]);

  // useEffect(() => {
  //   const randomIndex = Math.floor(Math.random() * imageArray.length);
  //   setImage(imageArray[randomIndex]);
  // }, [imageArray]);

  return (
    <>
      <div className="flex h-full min-h-dvh flex-1 flex-row">
        {/* <div className="flex h-full min-h-dvh flex-1 flex-row md:h-dvh md:min-h-full"> */}
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Logo classes="h-10" justImage />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-foreground">
                Sign in to your account
              </h2>
              <p className="mt-2 text-sm leading-6 text-muted-foreground">
                {isLogin ? "Not" : "Already"} a member?{" "}
                <Button variant="link" asChild>
                  <Link to={isLogin ? "/register" : "/login"}>
                    {isLogin
                      ? "Start a 14 day free trial"
                      : "Login to your account"}
                  </Link>
                </Button>
              </p>
            </div>

            <div className="mt-10">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {() => (
                  <Form className="space-y-6">
                    {children}
                    <div>
                      <FormButton
                        classes="w-full"
                        LoadingText
                        Text={isLogin ? "Sign in" : "Sign up"}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="mt-10">
              <Social />
            </div>
          </div>
        </div>

        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={`https://images.unsplash.com/${Image}?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=75`}
            alt="Background"
          />
        </div>
      </div>
    </>
  );
};
export default LoginRegisterWrapper;
