import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import type { FC } from "react";
import { DestructedFormikField } from "./DestructedField";

interface RadioInputProps {
  FieldName: string;
  Values: { id: string; label: string; value: string }[];
  InputLabel: string;
  defaultValue: string;
  trueOrFalse?: boolean;
}

export const RadioInputComponent: FC<RadioInputProps> = ({
  FieldName,
  Values,
  defaultValue,
  trueOrFalse = false, // Add this line with a default value
}) => {
  return (
    <DestructedFormikField FieldName={FieldName}>
      {({ field, form }) => {
        const radioHandler = (value: string) => {
          const fieldValue = trueOrFalse ? value === "yes" : value;
          form.setFieldValue(field.name, fieldValue);
        };
        return (
          <RadioGroup
            defaultValue={defaultValue}
            className="grid items-center justify-between gap-2 lg:grid-cols-2"
          >
            {Values.map((item) => (
              <div className="flex items-center space-x-2" key={item.id}>
                <RadioGroupItem
                  value={item.value}
                  id={item.id}
                  onClick={() => radioHandler(item.value)}
                />
                <Label htmlFor={item.id}>{item.label}</Label>
              </div>
            ))}
          </RadioGroup>
        );
      }}
    </DestructedFormikField>
  );
};
