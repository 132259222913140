import {
  difficultyLevels,
  equipment,
  exerciseTypes,
  targetMuscles,
} from "@/helper/static/Exercise/Array";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ArrayInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import { TranslationsInput } from "../TranslationInput";

export const EditExercise = () => {
  return (
    <Edit>
      <SimpleForm>
        <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 6 }}>
            <TextInput
              source="name"
              validate={[required()]}
              label="Exercise Name"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectInput
              source="exercise_type"
              choices={exerciseTypes}
              validate={[required()]}
              label="Exercise Type"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextInput
              source="description"
              validate={[required()]}
              label="Description"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectInput
              source="difficulty_level"
              choices={difficultyLevels}
              validate={[required()]}
              label="Difficulty Level"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <ReferenceInput source="trainer_id" reference="trainers">
              <SelectInput
                validate={[required()]}
                label="Trainer"
                optionText="bio"
                fullWidth
              />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Exercise Details
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 3 }}>
            <NumberInput
              source="recommended_reps"
              validate={[required()]}
              label="Recommended Reps"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <NumberInput
              source="recommended_sets"
              validate={[required()]}
              label="Recommended Sets"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <NumberInput
              source="rest_period_in_seconds"
              validate={[required()]}
              label="Rest Period (seconds)"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <NumberInput
              source="calories_burned_per_rep"
              validate={[required()]}
              label="Calories Burned Per Rep"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Target Muscles & Equipment
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectArrayInput
              source="target_muscles"
              choices={targetMuscles}
              validate={[required()]}
              label="Target Muscles"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <SelectArrayInput
              source="equipment_needed"
              choices={equipment}
              label="Equipment Needed"
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Media & Instructions
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12 }}>
            <TextInput source="video_url" label="Video URL" fullWidth />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <ArrayInput source="form_tips" label="Form Tips">
              <SimpleFormIterator>
                <TextInput
                  source=""
                  label="Tip"
                  fullWidth
                  parse={(v) => v}
                  format={(v) => v}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Translations
        </Typography>
        <Grid container spacing={2} width="100%">
          <Grid size={{ xs: 12 }}>
            <TranslationsInput
              source="translations"
              isNested
              fields={["name", "description", "form_tips"]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default EditExercise;
