import { cn } from "@/helper/lib/utils";
import { useSidebarStore } from "@/helper/store/sidebarStore";
import type React from "react";
import FastAddModal from "../FastAddModal";
import Navbar from "../Navbar";
import { Sidebar } from "../Sidebar/Sidebar";
import { Toaster } from "../ui/toaster";

interface Props {
  children: React.ReactNode;
  customClasses?: string;
}

const DashboardShell: React.FC<Props> = ({ children, customClasses }) => {
  const { collapsed } = useSidebarStore();

  return (
    <>
      <Sidebar />
      <main
        className={cn(
          "min-h-[calc(100dvh_-_56px)] transition-[margin-left] duration-300 ease-in-out",
          !collapsed ? "lg:ml-[90px]" : "lg:ml-72",
        )}
      >
        <div className="h-full min-h-dvh">
          <Navbar />

          <main className={cn(customClasses ? customClasses : "py-10")}>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              {children}
              <FastAddModal />
            </div>
          </main>
        </div>
        <Toaster />
      </main>
    </>
  );
};
export default DashboardShell;
