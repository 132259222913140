import { Button } from "@/components/ui/button";
import { ArrowLeftIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex h-dvh w-full items-center justify-center max-lg:flex-col">
        <div className="flex w-full flex-col items-center justify-center text-center max-lg:h-full lg:h-dvh lg:px-2 xl:px-0">
          <p className="text-7xl font-bold tracking-wider text-primary md:text-8xl lg:text-9xl">
            500
          </p>
          <p className="mt-2 text-4xl font-bold tracking-wider text-foreground md:text-5xl lg:text-6xl">
            Error
          </p>
          <p className="my-12 text-lg text-foreground/50 md:text-xl lg:text-2xl">
            Whoops, we broke something.
          </p>
          <Button
            variant="link"
            className="text-xl"
            onClick={() => navigate("/")}
          >
            <ArrowLeftIcon className="mr-1 h-6 w-6" />
            Back to Home
          </Button>
        </div>
        <div className="w-full max-lg:h-full lg:h-dvh">
          <img
            src="https://images.unsplash.com/photo-1569187633056-3d3d7ee6e507?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=75"
            alt="500"
            className="w-full object-cover max-lg:h-full lg:h-dvh"
          />
        </div>
      </div>
    </>
  );
}

export default ErrorPage;
